<template>
    <Fragment>
        <SpinnerComponent v-if="etapaActual.isLoading" spinning text="Cargando etapa actual..." />
        <template v-else>
            <div v-if="etapa" class="px-md-16">
                <div class="d-flex align-center justify-space-between flex-wrap" style="gap: 48px;">
                    <div class="d-flex align-center">
                        <h5 class="text-h5 secondary--text">
                        {{ etapa.etapa_rec_ape.nombre }}
                        </h5>

                    </div>
                    <div class="d-flex align-center flex-wrap" style="gap: 48px;">
                        <p class="h6-text mb-0 mt-0 font-weight-bold">
                            Fecha de Inicio: <br/>
                            <span class="secondary--text">{{ moment(etapa.fecha_inicio).format("DD-MM-YYYY HH:mm A") }}</span>
                        </p>
                    </div>
                </div>
                <div class="mt-8">
                    <template v-if="etapa.etapa_rec_ape.id === 1">
                        <ResolucionRecursoApelacion :id-proceso-compra="idProcesoCompra" />
                    </template>
                </div>
            </div>
            <IndicadorSiguienteEtapa v-else :id-proceso-compra="idProcesoCompra" />
        </template>
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import NotificacionResolucionApelacion from './etapas/NotificacionResolucionApelacion.vue';
import ResolucionRecursoApelacion from './etapas/ResolucionRecursoApelacion.vue';
import AdmisionRecursoApelacion from './etapas/AdmisionRecursoApelacion.vue';
import IndicadorSiguienteEtapa from './etapas/IndicadorSiguienteEtapa.vue';
import { ValidacionAccesos } from '@/utils/role-utils';
import { SpinnerComponent } from '@/components/utils';
import { mapActions, mapState } from 'vuex';
import { Fragment } from 'vue-fragment';

export default {
    name: 'EtapaActualRecursoApelacion',
    emits: ['on-cierre-etapa'],
    components: { 
        Fragment, 
        SpinnerComponent, 
        AdmisionRecursoApelacion, 
        ResolucionRecursoApelacion, 
        NotificacionResolucionApelacion,
        IndicadorSiguienteEtapa,
    },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        cierreEtapa: createLoadable(null),
    }),
    computed: {
        ...mapState('recursoApelacion', ['etapaActual']),
        etapa() {
            return this.etapaActual.data;
        },
        esUCP() {
            return ValidacionAccesos.ES_UCP;
        },
        esAlMenosUCP() {
            return ValidacionAccesos.ES_AL_MENOS_UCP;
        },
        puedeCerrarEtapa() {
            return this.esAlMenosUCP;
        },
        esProveedor() {
            return ValidacionAccesos.ES_PROVEEDOR;
        },
        esTacop() {
            return ValidacionAccesos.ES_TACOP;
        },
    },
    methods: {
        ...mapActions('recursoApelacion', ['cargarEtapaActual']),
        async cerrarEtapa() {
            toggleLoadable(this.cierreEtapa);
            const { data } = await this.services.RecursoApelacion.cerrarEtapa(this.idProcesoCompra, this.etapa.id);
            setLoadableResponse(this.cierreEtapa, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            this.$emit('on-cierre-etapa');
        },
    },
    created() {
        this.cargarEtapaActual(this.idProcesoCompra);
    },
}
</script>
<template>
  <div>
    <div v-if="!soloLectura && !esProveedor" class="mb-12">
      <h5 class="text-h5 font-weight-bold">
        Proveedores adjudicados después de resolución
      </h5>
      <AddGanadorComponent class="mt-4" :solo-lectura="soloLectura" />
    </div>

    <div>
      <h5 class="text-h5 font-weight-bold">
        Notificación de resolución de revisión
      </h5>
      <v-form
        v-if="!soloLectura && esAlMenosUCP"
        ref="form"
        class="mt-4"
        @submit.prevent="manejarEnvioFormulario"
      >
        <v-row>
          <v-col cols="12" md="4" lg="5">
            <v-text-field
              v-model="form.nombre"
              label="Nombre del documento"
              placeholder="Nombre del documento"
              :disabled="documentoCargado.isLoading || guardadoDocumento.isLoading"
              :error-messages="nombreErrors"
              :hide-details="!(nombreErrors.length > 0)"
              @change="$v.form.nombre.$touch()"
              @blur="$v.form.nombre.$touch()"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4" lg="5">
            <v-file-input
              v-model="form.documento"
              label="Archivo"
              placeholder="Archivo"
              accept="application/pdf"
              :disabled="documentoCargado.isLoading || guardadoDocumento.isLoading"
              :error-messages="documentoErrors"
              :hide-details="!(documentoErrors.length > 0)"
              @blur="$v.form.documento.$touch()"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <v-btn
              color="primary"
              class="d-block"
              style="width: 100%"
              type="submit"
              :disabled="documentoCargado.isLoading"
              :loading="guardadoDocumento.isLoading"
              x-large
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <div v-if="documentoCargado.isLoading" class="d-flex align-center blue-grey lighten-5 px-4 py-4 mt-4" style="gap: 16px">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="25"
          :width="3"
        />
        <p class="mb-0 font-weight-bold text-h6">Cargando documento...</p>
      </div>
      <div v-if="documentoCargado.data && !documentoCargado.isLoading" class="mt-4">
        <div
          class="d-flex align-center justify-space-between flex-wrap blue-grey lighten-5 px-4 py-4"
          style="gap: 8px"
        >
          <span>
            <v-icon style="font-size: 28px">mdi-paperclip</v-icon>
            <p class="mb-0 ml-2 font-weight-bold text-h6 d-inline-block">
              {{ documentoCargado.data.nombre_documento }}
            </p>
          </span>
          <div>
            <v-btn
              @click.stop="visualizarDocumento"
              :disabled="documentoCargado.isLoading"
              :loading="visualizacionDocumento.isLoading"
              large
              icon
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              @click.stop="descargarDocumento"
              :disabled="documentoCargado.isLoading"
              :loading="descargaDocumento.isLoading"
              large
              icon
            >
              <v-icon large>mdi-download</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="!documentoCargado.data && !documentoCargado.isLoading" class="blue-grey lighten-5 px-4 py-4 d-flex align-center mt-4">
        <v-icon>mdi-file-document-alert</v-icon>
        <p class="mb-0 ml-2 font-weight-bold text-h6 d-inline-block">
          No se ha cargado el documento
        </p>
      </div>
    </div>

    <!-- Resolucion final -->
    <div v-if="documentoCargado.data && !documentoCargado.isLoading" class="mt-12">
      <h5 class="text-h5 font-weight-bold">
        Resolución final de la máxima autoridad
      </h5>
      <div
        v-if="cargandoResolucionUcp"
        class="d-flex align-center mt-4 blue-grey lighten-5 px-4 py-4"
        style="gap: 16px"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          :size="25"
          :width="3"
        />
        <p class="mb-0 font-weight-bold text-h6">Cargando documentos...</p>
      </div>
      <template v-if="!cargandoResolucionUcp">
        <div v-if="resolucionesUCP.length > 0" class="d-flex flex-column mt-4" style="gap: 8px;">
          <div
            v-for="resolucion in resolucionesUCP"
            class="d-flex align-center justify-space-between flex-wrap blue-grey lighten-5 px-4 py-4"
            style="gap: 8px"
          >
            <span>
              <v-icon style="font-size: 28px">mdi-paperclip</v-icon>
              <p class="mb-0 ml-2 font-weight-bold text-h6 d-inline-block">
                {{ resolucion.nombre_documento }}
              </p>
            </span>
            <div>
              <v-btn
                @click.stop="visualizarResolucionFinal(resolucion)"
                :disabled="documentoCargado.isLoading"
                :loading="previewResolucionUcp.isLoading"
                large
                icon
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                class="ml-2"
                @click.stop="descargarResolucionUcp(resolucion)"
                :disabled="documentoCargado.isLoading"
                :loading="descargandoResolucionUcp"
                large
                icon
              >
                <v-icon large>mdi-download</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else class="blue-grey lighten-5 px-4 py-4 d-flex align-center mt-4">
          <v-icon>mdi-file-document-alert</v-icon>
          <p class="mb-0 ml-2 font-weight-bold text-h6 d-inline-block">
            No se ha cargado el documento
          </p>
        </div>
      </template>
    </div>

    <PdfModal
      :isOpen="modalVerDocAbierto"
      :source-loadable="visualizacionDocumento"
      @on-visibility-change="manejarVisibilidadModalDocumento"
      :filename="filename"
    />
    <PdfModal
      :isOpen="modalPreviewResolucionUcp"
      :source-loadable="previewResolucionUcp"
      @on-visibility-change="manejarVisibilidadModalResolucionUcp"
      :filename="resolucionUcpFilename"
    />
  </div>
</template>
<script>
import AddGanadorComponent from "@/views/ProcesoCompraDoc/RecursosRevision/components/componentesUCP/AddGanadorComponent.vue";
import {
  createLoadable,
  isResponseSuccesful,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import { required, maxLength } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation.js";
import { convertToFormData } from "@/utils/data";
import PdfModal from "@/components/PdfModal.vue";
import { mapActions, mapState } from "vuex";
import { ValidacionAccesos } from "@/utils/role-utils";

export default {
  name: "NotificacionResolucionRevision",
  components: { AddGanadorComponent, PdfModal },
  validations: {
    form: {
      nombre: { required, maxLength: maxLength(250) },
      documento: { required },
    },
  },
  props: {
    idProceso: { type: [String, Number] },
    etapa: { type: Object },
  },
  data: () => ({
    form: {
      nombre: "",
      documento: null,
    },
    modalVerDocAbierto: false,
    // Data
    documentoCargado: createLoadable(null),
    guardadoDocumento: createLoadable(null),
    visualizacionDocumento: createLoadable(null),
    descargaDocumento: createLoadable(null),
    // Resolucion UCP
    resolucionesUCP: [],
    resolucionUcp: null,
    cargandoResolucionUcp: false,
    // Visualizacion resolucion UCP
    previewResolucionUcp: createLoadable(null),
    modalPreviewResolucionUcp: false,
    // Descargar resolucion UCP
    descargandoResolucionUcp: false,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "verificacionParticipacionComision"]),
    nombreErrors() {
      return new Validator(this.$v.form.nombre).detect().getResult();
    },
    documentoErrors() {
      return new Validator(this.$v.form.documento).detect().getResult();
    },
    soloLectura() {
      const participacionComision = this.verificacionParticipacionComision.data;
      return this.haveRole("ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION") || (participacionComision?.participa_en_comision && !participacionComision?.pertenece_a_ucp);
    },
    esProveedor() {
      return ValidacionAccesos.ES_PROVEEDOR;
    },
    esUCP() {
      return ValidacionAccesos.ES_UCP;
    },
    esAlMenosUCP() {
      return ValidacionAccesos.ES_AL_MENOS_UCP;
    },
    filename() {
      return this.documentoCargado.data?.nombre_documento ?? "documento";
    },
    resolucionUcpFilename() {
      return this.resolucionUcp?.nombre_documento ?? "resolucion-final";
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["getDocumentosProcesoPorTipo"]),
    ...mapActions("procesoCompraDoc", [
      "descargarBufferDocumento",
      "descargarArchivoProceso",
    ]),
    limpiarFormulario() {
      this.$v.form.$reset();
      this.$refs.form.reset();
    },
    async manejarEnvioFormulario() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const formData = convertToFormData(this.form);

      toggleLoadable(this.guardadoDocumento);
      const { data } =
        await this.services.RecursoApelacion.guardarDocumentoNotificacionResolucionResultados(
          this.idProceso,
          this.etapa.id,
          formData
        );

      setLoadableResponse(this.guardadoDocumento, data, {
        showAlertOnSuccess: true,
        skipOnSuccess: true,
      });

      if (isResponseSuccesful(data)) {
        this.limpiarFormulario();
        this.cargarDocumento();
      }
    },
    manejarVisibilidadModalDocumento(visible) {
      this.modalVerDocAbierto = visible;
    },
    // Data
    async cargarDocumento() {
      if (!this.idProceso || !this.etapa) return;

      toggleLoadable(this.documentoCargado);
      const { data } =
        await this.services.RecursoApelacion.obtenerDocumentoNotificacionResolucionResultados(
          this.idProceso,
          this.etapa.id
        );

      setLoadableResponse(this.documentoCargado, data);
    },
    async descargarDocumento() {
      toggleLoadable(this.descargaDocumento);
      const { data } =
        await this.services.RecursoApelacion.descargarDocumentoNotificacionResolucionResultados(
          this.idProceso,
          this.etapa.id
        );

      setLoadableResponse(this.descargaDocumento, data, {
        isFile: true,
        skipOnSuccess: true,
      });

      if (data && !("error" in data)) {
        const file = new File([data], `${this.filename}.pdf`, {
          type: "application/octet-stream",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
      }
    },
    async visualizarDocumento() {
      this.modalVerDocAbierto = true;
      toggleLoadable(this.visualizacionDocumento);
      const { data } =
        await this.services.RecursoApelacion.descargarDocumentoNotificacionResolucionResultados(
          this.idProceso,
          this.etapa.id
        );

      setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
    },
    // Resolución Final de la Máxima Autoridad
    async getResolucionUcp() {
      this.cargandoResolucionUcp = true;
      try {
        const response = await this.getDocumentosProcesoPorTipo({
          id_proceso_compra: this.idProceso,
          id_tipo_documento: 15003,
          id_etapa_proceso: this.procesoData.id_etapa_pausa,
        });

        this.resolucionesUCP = response;
      } catch (error) {
      } finally {
        this.cargandoResolucionUcp = false;
      }
    },
    async visualizarResolucionFinal(resolucion) {
      if (!resolucion) return;

      this.resolucionUcp = { ...resolucion };

      toggleLoadable(this.previewResolucionUcp);
      this.modalPreviewResolucionUcp = true;
      try {
        const response = await this.descargarBufferDocumento({
          ruta_documento: resolucion.ruta_documento,
        });
        this.previewResolucionUcp.data = response ? new Blob([response]) : null;
      } catch (error) {
        this.previewResolucionUcp.data = null;
      } finally {
        this.previewResolucionUcp.isLoading = false;
      }
    },
    manejarVisibilidadModalResolucionUcp(visible) {
      this.modalPreviewResolucionUcp = visible;
    },
    async descargarResolucionUcp(resolucion) {
      if (!resolucion) return;

      this.resolucionUcp = { ...resolucion };

      this.descargandoResolucionUcp = true;
      await this.descargarArchivoProceso({
        ruta_documento: resolucion.ruta_documento,
      });
      this.descargandoResolucionUcp = false;
    },
  },
  created() {
    this.cargarDocumento();
    this.getResolucionUcp();
  },
};
</script>

<template>
    <div>
        <v-form v-if="!soloLectura && (esAlMenosUCP || esProveedor)" ref="form" class="mb-sm-4" @submit.prevent="manejarEnvioFormulario">
            <v-row>
                <v-col cols="12" :md="grid.nombre.md" :lg="grid.nombre.lg">
                    <v-text-field 
                        v-model="form.nombre"
                        label="Nombre del documento"
                        placeholder="Nombre del documento"
                        :disabled="documentos.isLoading || eliminacionDocumento.isLoading || guardadoDocumento.isLoading"
                        :error-messages="nombreErrors"
                        @change="$v.form.nombre.$touch()"
                        @blur="$v.form.nombre.$touch()"
                        outlined  
                    />
                </v-col>
                <v-col cols="12" :md="grid.documento.md" :lg="grid.documento.lg">
                    <v-file-input
                        v-model="form.documento"
                        label="Archivo"
                        placeholder="Archivo"
                        accept="application/pdf"
                        :disabled="documentos.isLoading || eliminacionDocumento.isLoading || guardadoDocumento.isLoading"
                        :error-messages="documentoErrors"
                        @blur="$v.form.documento.$touch()"
                        outlined  
                    />
                </v-col>
                <v-col v-if="!esProveedor" cols="12" :md="grid.proveedor.md" :lg="grid.proveedor.lg">
                    <v-select 
                        v-model="form.id_proveedor"
                        label="Proveedor" 
                        :items="proveedoresRecursoApelacion.data"
                        :disabled="documentos.isLoading || eliminacionDocumento.isLoading || guardadoDocumento.isLoading"
                        :error-messages="id_proveedorErrors"
                        item-value="id"
                        item-text="nombre_comercial"
                        :loading="proveedoresRecursoApelacion.isLoading"
                        @change="$v.form.id_proveedor.$touch()"
                        @blur="$v.form.id_proveedor.$touch()"
                        outlined
                    />
                </v-col>
                <v-col cols="12" :md="grid.accion.md" :lg="grid.accion.lg">
                    <v-btn 
                        color="primary" 
                        class="d-block"
                        style="width: 100%"
                        type="submit"
                        :disabled="documentos.isLoading || eliminacionDocumento.isLoading || guardadoDocumento.isLoading"
                        :loading="guardadoDocumento.isLoading"
                        x-large
                    >
                        Guardar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        
        <v-alert v-if="esAlMenosUCP" type="info" dense>
            Esta acción no restringe el envio del expediente físico al TACOP.
        </v-alert>

        <DataTableComponent
            :headers="headers"
            :items="documentos.data"
            :show_loading="documentos.isLoading"
            :tiene_paginacion="false"
        >
            <template v-slot:item.accion="{ item }">
                <div class="d-flex align-center justify-center" style="gap: 8px;">
                    <v-btn 
                        @click.stop="visualizarDocumento(item)"
                        :loading="visualizacionDocumento.isLoading"
                        icon
                    >
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                        @click.stop="descargarDocumento(item)"
                        :loading="descargaDocumento.isLoading"
                        icon
                    >
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-btn v-if="esAlMenosUCP" icon @click.stop="abrirModalEdicionRecursoApelacion(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <!-- <v-btn
                        @click.stop="confirmarEliminacionDocumento(item)"
                        :loading="eliminacionDocumento.isLoading"
                        icon
                    >
                        <v-icon>mdi-trash-can</v-icon>
                    </v-btn> -->
                </div>
            </template>
        </DataTableComponent>

        <PdfModal
            :isOpen="modalVerDocAbierto" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="manejarVisibilidadModalDocumento"
            :filename="filename"
        />
        <ConfirmationModalComponent
            :is-open="modalConfirmacionEliminacion"
            :is-loading="eliminacionDocumento.isLoading"
            description="¿Desea eliminar el documento?"
            @cancel="modalConfirmacionEliminacion = false"
            @confirm="eliminarDocumento"
        />

        <ModalEditarRecursoApelacion 
            :is-open.sync="modalEdicionRecursoAbierta"
            :id-proceso-compra="idProceso"
            :proveedor="documentoActivo?.proveedor.id"
            @on-save="manejarEdicionRecursoApelacion"
        />
    </div>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import ModalEditarRecursoApelacion from './ModalEditarRecursoApelacion.vue';
import DataTableComponent from "@/components/DataTableComponent.vue";
import { ConfirmationModalComponent } from '@/components/utils';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation.js';
import PdfModal from '@/components/PdfModal.vue';
import { convertToFormData } from '@/utils/data';
import { mapState, mapActions } from 'vuex';
import { ValidacionAccesos } from '@/utils/role-utils';

export default {
    name: 'PresentacionRecursoApelacion',
    components: { PdfModal, DataTableComponent, ConfirmationModalComponent, ModalEditarRecursoApelacion },
    validations() {
        return ({
            form: {
                nombre: { required, maxLength: maxLength(250) },
                documento: { required },
                ...!this.esProveedor && ({ id_proveedor: { required } }),
            },
        });
    },
    props: {
        idProceso: { type: [String, Number] },
    },
    data() {
        return ({
            form: {
                nombre: '',
                documento: null,
                ...!this.esProveedor && ({ id_proveedor: null }),
            },
            modalVerDocAbierto: false,
            // Data
            documentos: createLoadable([]),
            guardadoDocumento: createLoadable(null),
            visualizacionDocumento: createLoadable(null),
            descargaDocumento: createLoadable(null),
            eliminacionDocumento: createLoadable(null),
            // documento
            documentoActivo: null,
            headers: [
                { align: 'center', sortable: false, text: 'Nombre', value: 'nombre_documento' },
                { align: 'center', sortable: false, text: 'Proveedor', value: 'proveedor.nombre_comercial' },
                { align: 'center', sortable: false, text: 'Acción', value: 'accion' },
            ],
            // confirmaciones
            modalConfirmacionEliminacion: false,
            // Edicion recurso
            modalEdicionRecursoAbierta: false,
        });
    },
    computed: {
        ...mapState("recursoApelacion", ["proveedoresRecursoApelacion"]),
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        documentoErrors() {
            return new Validator(this.$v.form.documento).detect().getResult();
        },
        id_proveedorErrors() {
            if (this.esProveedor) return [];

            return new Validator(this.$v.form.id_proveedor).detect().getResult();
        },
        soloLectura() {
            return this.haveRole('ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION');
        },
        filename() {
            return this.documentoActivo?.nombre_documento ?? 'documento';
        },
        esProveedor() {
            return ValidacionAccesos.ES_PROVEEDOR;
        },
        esUCP() {
            return ValidacionAccesos.ES_UCP;
        },
        esAlMenosUCP() {
            return ValidacionAccesos.ES_AL_MENOS_UCP;
        },
        grid() {
            const generarGrid = (md, lg) => ({ md, lg });
            return ({
                documento: this.esProveedor ? generarGrid(4, 5) : generarGrid(3, 3),
                nombre: this.esProveedor ? generarGrid(4, 5) : generarGrid(3, 3),
                proveedor: generarGrid(3, 3),
                accion: this.esProveedor ? generarGrid(4, 2) : generarGrid(3, 3),
            });
        },
    },
    methods: {
        ...mapActions("recursoApelacion", ["cargarProveedoresParaApelacion"]),
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
        },
        async manejarEnvioFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            const formData = convertToFormData(this.form);

            toggleLoadable(this.guardadoDocumento);
            const { data } = await this.services.RecursoApelacion.guardarDocumentoPresentacionRecursoApelacion(this.idProceso, formData);
            setLoadableResponse(this.guardadoDocumento, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.limpiarFormulario();
                this.cargarDocumentos();

                if (!this.esProveedor) this.cargarProveedoresParaApelacion(this.idProceso);
            };
        },
        manejarVisibilidadModalDocumento(visible) {
            this.modalVerDocAbierto = visible;
        },
        // Data
        async cargarDocumentos() {
            if (!this.idProceso) return;

            toggleLoadable(this.documentos);
            const { data } = await this.services.RecursoApelacion.obtenerDocumentoPresentacionRecursoApelacion(this.idProceso);
            setLoadableResponse(this.documentos, data);
        },
        async descargarDocumento(documento) {
            this.documentoActivo = documento;
            toggleLoadable(this.descargaDocumento);
            const { data } = await this.services.RecursoApelacion.descargarDocumentoPresentacionRecursoApelacion(
                this.idProceso,
                documento.id,
            );

            setLoadableResponse(this.descargaDocumento, data, { isFile: true, skipOnSuccess: true });

            if (data && !('error' in data)) {
                const file = new File([data], `${this.filename}.pdf`,
                    { type: "application/octet-stream" },
                );
    
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = file.name;
                link.click();
            }
        },
        async visualizarDocumento(documento) {
            this.documentoActivo = documento;
            this.modalVerDocAbierto = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.RecursoApelacion.descargarDocumentoPresentacionRecursoApelacion(
                this.idProceso,
                documento.id,
            );

            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        confirmarEliminacionDocumento(documento) {
            this.documentoActivo = documento;
            this.modalConfirmacionEliminacion = true;
        },
        async eliminarDocumento() {
            toggleLoadable(this.eliminacionDocumento);
            const { data } = await this.services.RecursoApelacion.eliminarDocumentoPresentacionRecursoApelacion(this.idProceso, this.documentoActivo.id);
            this.modalConfirmacionEliminacion = false;
            setLoadableResponse(this.eliminacionDocumento, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.cargarDocumentos();
            }
        },
        // Edicion recurso apelacion
        abrirModalEdicionRecursoApelacion(documento) {
            this.documentoActivo = { ...documento };
            this.modalEdicionRecursoAbierta = true;
        },
        manejarEdicionRecursoApelacion() {
            this.cargarDocumentos();

            if (!this.esProveedor) this.cargarProveedoresParaApelacion(this.idProceso);
        },
    },
    created() {
        this.cargarDocumentos();

        if (!this.esProveedor) this.cargarProveedoresParaApelacion(this.idProceso);
    },
}
</script>
<template>
    <section v-if="visibilidadSection" class="pb-4">
        <Fragment>
            <div v-if="esRoleUCP" class="mb-16">
                <h5 class="text-h5 font-weight-bold">Proveedores adjudicados después de resolución</h5>
                <AddGanadorComponent class="mt-4" :solo-lectura="false" />
            </div>
            
            <template>
                <template v-if="!esRoleTacop">
                    <h6 class="text-h6 font-weight-bold text-uppercase mb-1">Notificación de recurso de apelación</h6>
                    <v-divider />
                </template>
                <v-form v-if="puedeCargarResolucion" ref="form" class="mt-4" @submit.prevent="validarCreacionNotificacion">
                    <v-row>
                        <template>
                            <v-col cols="12" md="4">
                                <v-text-field
                                v-model="form.nombre" 
                                label="Nombre de documento" 
                                outlined 
                                @change="$v.form.nombre.$touch()"
                                @blur="$v.form.nombre.$touch()"
                                :error-messages="nombreErrors"
                                />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-file-input
                                v-model="form.documento"
                                label="Adjunto"
                                accept="application/pdf"
                                outlined 
                                @change="$v.form.documento.$touch()"
                                :error-messages="documentoErrors"
                                />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-btn 
                                x-large 
                                color="primary" 
                                style="width: 100%;" 
                                type="submit"
                                >
                                Guardar
                            </v-btn>
                            </v-col>
                        </template>
                    </v-row>
                </v-form>
            </template>

            <DataTableComponent 
                :items="notificaciones.data"
                :headers="notificacionesHeaders"
                :show_loading="notificaciones.isLoading"
                :tiene_paginacion="false"
                class="mt-4"
                dense
                no-gutters
            >
                <template v-slot:item.fecha_carga="{item}">{{ formatDate(item.created_at) }}</template>
                <template v-slot:item.acciones="{item}">
                    <div class="d-flex align-center justify-center" style="gap: 16px;">
                        <v-icon
                            @click.stop="visualizarDocumento(item)"
                            :loading="visualizacionDocumento.isLoading"
                        >
                            mdi-eye
                        </v-icon>
                        <v-icon
                            v-if="puedeCargarResolucion"
                            @click.stop="confirmarEliminacionNotificacion(item)"
                            :loading="eliminacionNotificacion.isLoading"
                        >
                            mdi-trash-can
                        </v-icon>
                    </div>
                </template>
            </DataTableComponent>

            <div v-if="notificaciones.data.length" class="mt-8">
                <ResolucionFinal :id-proceso-compra="idProcesoCompra" readonly />
            </div>

            <div v-if="notificaciones.data.length && !esProveedor" class="mt-8">
                <h6 class="text-h6 font-weight-bold text-uppercase mb-1">Continuar con el proceso</h6>
                <v-divider />
                <ContinuarProceso 
                    :mostrar-encabezado="false" 
                    :id-proceso="idProcesoCompra" 
                    :mapper="(etapa) => etapa.id_tipo_etapa !== 27"
                    class="mt-4"
                >
                    <template v-slot:accion>
                        <v-btn
                            color="primary"
                            class="mt-8"
                            :loading="continuacionProceso.isLoading"
                            @click.stop="continuarProceso"
                        >
                            Continuar proceso
                        </v-btn>
                    </template>
                </ContinuarProceso>
            </div>

            <ConfirmationModalComponent
                :is-open="confirmacionCreacion"
                :is-loading="guardadoNotificacion.isLoading"
                description="¿Desea guardar el documento?"
                @confirm="crearNotificacion"
                @cancel="confirmacionCreacion = false"
            />

            <ConfirmationModalComponent
                :is-open="confirmacionEliminado"
                :is-loading="eliminacionNotificacion.isLoading"
                description="¿Desea eliminar el documento?"
                @confirm="eliminarNotificacion"
                @cancel="confirmacionEliminado = false"
            />

            <PdfModal
                :isOpen="modalVisualizacionDocumento" 
                :source-loadable="visualizacionDocumento" 
                @on-visibility-change="cerrarModalVisualizacionDocumento"
                :filename="filename"
            />
        </Fragment>
    </section>
    <div v-else class="d-flex flex-column justify-center mt-4" style="gap: 16px;">
        <v-icon style="font-size: 60px !important;" class="secondary--text">mdi-cancel</v-icon>
        <h5 class="text-h5 text-center font-weight-bold secondary--text">
            La etapa actual no es visible para su perfil
        </h5>
    </div>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable, isResponseSuccesful } from '@/utils/loadable';
import AddGanadorComponent from '../../RecursosRevision/components/componentesUCP/AddGanadorComponent.vue';
import ContinuarProceso from '../../RecursosRevision/components/componentesUCP/ContinuarProceso.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import ResolucionFinal from '../components/ResolucionFinal.vue';
import { Validator } from '@/utils/form-validation.js';
import { required } from 'vuelidate/lib/validators'
import { convertToFormData } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { Observable } from '@/utils/observable';
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';
import { mapState } from 'vuex';

export default {
    name: 'NotificacionResolucionApelacion',
    validations() {
        return ({
            form: {
                nombre: { required },
                documento: { required },
            },
        });
    },
    components: { Fragment, ConfirmationModalComponent, DataTableComponent, PdfModal, AddGanadorComponent, ResolucionFinal, ContinuarProceso },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        notificacionActiva: null,
        notificacionesHeaders: [
            { text: 'Nombre', value: 'nombre_documento', align: 'center', sortable: false },
            { text: 'Fecha de carga', value: 'fecha_carga', align: 'center', sortable: false },
            { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
        ],
        // Datos
        notificaciones: createLoadable([]),
        form: {
            nombre: null,
            documento: null,
        },
        // Creacion
        confirmacionCreacion: false,
        guardadoNotificacion: createLoadable(null),
        // Eliminado
        confirmacionEliminado: false,
        eliminacionNotificacion: createLoadable(null),
        // Visualizacion
        modalVisualizacionDocumento: false,
        visualizacionDocumento: createLoadable(null),
        // Continuar proceso
        continuacionProceso: createLoadable(null),
    }),
    computed: {
        ...mapState("recursoApelacion", ["etapaActual"]),
        esProveedor() {
            return this.haveRoles(['ROLE_PROVEEDOR']);
        },
        esRoleTacop() {
            return this.haveRoles(['ROLE_TACOP']);
        },
        esRoleUCP() {
            return this.haveRoles(['ROLE_UACI']);
        },
        puedeCargarResolucion() {
            // return this.esRoleTacop || this.esRoleUCP;
            // return this.esRoleTacop;
            return !this.esProveedor;
        },
        visibilidadSection() {
            return (this.esProveedor || this.esRoleTacop || this.esRoleUCP);
        },
        filename() {
            return this.notificacionActiva?.nombre_documento ?? 'pronunciamiento';
        },
        // Errores
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        documentoErrors() {
            return new Validator(this.$v.form.documento).detect().getResult();
        },
    },
    methods: {
        // UI
        formatDate,
        limpiarFormulario() {
            this.$refs.form.reset();
            this.$v.form.$reset();
        },
        validarCreacionNotificacion() {
            this.$v.form.$touch();

            if (this.$v.form.$invalid) return;

            this.confirmacionCreacion = true;
        },
        cerrarModalVisualizacionDocumento(visible) {
            this.modalVisualizacionDocumento = visible;
        },
        confirmarEliminacionNotificacion(notificacion) {
            this.notificacionActiva = notificacion;
            this.confirmacionEliminado = true;
        },
        // Data
        async cargarNotificaciones() {
            toggleLoadable(this.notificaciones);
            const { data } = await this.services.RecursoApelacion.cargarNotificaciones(this.idProcesoCompra);
            setLoadableResponse(this.notificaciones, data);  
        },
        async crearNotificacion() {
            const formData = convertToFormData(this.form);
            toggleLoadable(this.guardadoNotificacion);
            const { data } = await this.services.RecursoApelacion.guardarNotificacion(this.idProcesoCompra, formData);
            this.confirmacionCreacion = false;
            setLoadableResponse(this.guardadoNotificacion, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            this.limpiarFormulario();
            this.cargarNotificaciones();
        },
        async visualizarDocumento(notificacion) {
            this.notificacionActiva = notificacion;
            this.modalVisualizacionDocumento = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.RecursoApelacion.visualizarDocumentoEtapaResolucion(notificacion.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        async eliminarNotificacion() {
            toggleLoadable(this.eliminacionNotificacion);
            const { data } = await this.services.RecursoApelacion.eliminarNotificacion(this.idProcesoCompra, this.notificacionActiva.id);
            this.confirmacionEliminado = false;
            setLoadableResponse(this.eliminacionNotificacion, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarNotificaciones();
        },
        async continuarProceso() {
            toggleLoadable(this.continuacionProceso);
            const { data } = await this.services.RecursoApelacion.cerrarEtapa(this.idProcesoCompra, this.etapaActual.data.id);
            setLoadableResponse(this.continuacionProceso, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            // Observable.emit('revalidar-pip-activo-recurso-apelacion');
            Observable.emit('recargar-proceso-actual');
        },
    },
    created() {
        this.cargarNotificaciones();
    },
}
</script>
<template>
    <section class="pb-4" v-if="tienePermisos">
        <v-row>
            <template v-if="esProveedor">
                <v-col cols="12">
                    <v-card class="px-4 py-4">
                        <RecursosApelacionAdmitidos :id-proceso-compra="idProcesoCompra" />
                    </v-card>
                </v-col>
            </template>
            <template>
                <v-col cols="12">
                    <v-card class="px-4 py-4">
                        <RecursosApelacionAdmitidos :id-proceso-compra="idProcesoCompra" />
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card class="px-4 py-4">
                        <ResolucionFinal :id-proceso-compra="idProcesoCompra" />
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </section>
    <div v-else class="d-flex flex-column justify-center mt-4" style="gap: 16px;">
        <v-icon style="font-size: 60px !important;" class="secondary--text">mdi-cancel</v-icon>
        <h5 class="text-h5 text-center font-weight-bold secondary--text">
            La etapa actual no es visible para su perfil
        </h5>
    </div>
</template>
<script>
import RecursosApelacionAdmitidos from '../components/RecursosApelacionAdmitidos.vue';
import PronunciamientoPartes from '../components/PronunciamientoPartes.vue';
import ResolucionFinal from '../components/ResolucionFinal.vue';
import ActosDeTramite from  '../components/ActosDeTramite.vue';
import { mapState } from 'vuex';
import { ValidacionAccesos } from '@/utils/role-utils';

export default {
    name: 'ResolucionRecursoApelacion',
    components: { RecursosApelacionAdmitidos, PronunciamientoPartes, ActosDeTramite, ResolucionFinal },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    computed: {
        ...mapState("recursoApelacion", ["resoluciones"]),
        esProveedor() {
            return ValidacionAccesos.ES_PROVEEDOR;
        },
        esRoleTacop() {
            return ValidacionAccesos.ES_TACOP;
        },
        esUCP() {
            return ValidacionAccesos.ES_UCP;
        },
        esAlMenosUCP() {
            return ValidacionAccesos.ES_AL_MENOS_UCP;
        },
        esAuditor() {
            return this.haveRoles(["ROLE_SUPER_ADMIN", "ROLE_AUDITORIA_PROCESO"]);
        },
        tienePermisos() {
            return this.esProveedor || this.esAlMenosUCP || this.esAuditor;
        },
    },
}
</script>
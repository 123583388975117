import { maxLength, required } from 'vuelidate/lib/validators';

export const personalizarMensajeRegla = (rule, message) => (valor) => rule(valor, message); 

export const reglaExtensionPDF = (file, message = 'El archivo debe ser de tipo PDF.') => {
    if (!file) return true;

    return /(\.pdf)$/i.test(file.name) || message;
}

export const reglaNombreArchivoValido = (file, message = 'El nombre de archivo no es válido.') => {
    if (!file) return true;

    return /^([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+\..+)$/ig.test(file.name) || message;
}

export const reglaRequerido = (valor, message = 'Campo requerido') => required(valor) || message;

export const reglaMaxLength = (longitud) => (valor, message = 'El campo excede la longitud permitida') => maxLength(longitud)(valor) || message;

<template>
  <section>
    <div v-if="validandoParticipacion" class="d-flex align-center flex-wrap" style="gap: 8px;">
      <v-progress-circular indeterminate :size="15" :width="2" />
      <p class="mb-0 text-uppercase text-caption font-weight-bold">Validando participación...</p>
    </div>

    <Fragment v-if="tieneAccesoEtapa">
      <v-row v-if="esProveedor" class="mt-4">
        <v-col cols="12" md="6" lg="4">
          <v-card v-if="cargandoRecursoRevisionActual" class="flex-grow-1 flex-shrink-1">
            <v-card-text>
              <div class="d-flex align-center flex-wrap" style="gap: 8px;">
                <v-progress-circular indeterminate :size="15" :width="2" />
                <p class="mb-0 text-uppercase text-caption font-weight-bold">Cargando recurso...</p>
              </div>
            </v-card-text>
          </v-card>
          
          <v-card v-else class="flex-grow-1 flex-shrink-1">
            <v-card-title>
              <h6 class="text-subtitle-1 font-weight-bold text-uppercase">{{ recursoRevisionActual ? 'Recurso de revisión' : 'Cargar recurso' }}</h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <template v-if="recursoRevisionActual">
                <dl>
                  <dt class="font-weight-bold">Fecha de carga</dt>
                  <dd>{{ formatDate(recursoRevisionActual.created_at) }}</dd>
    
                  <dt class="font-weight-bold mt-3">Correlativo</dt>
                  <dd>{{ recursoRevisionActual.correlativo }}</dd>
                </dl>
               
                <div class="d-flex align-center flex-wrap mt-4" style="gap: 16px;">
                  <v-btn 
                    outlined 
                    color="secondary" 
                    class="flex-grow-1 flex-shrink-1 flex-md-grow-0"
                    @click="descargarRecursoRevision"
                  >
                    <v-icon color="secondary" class="mr-2">mdi-download</v-icon>
                    Descargar
                  </v-btn>
    
                  <v-btn 
                    color="secondary" 
                    class="flex-grow-1 flex-shrink-1 flex-md-grow-0"
                    @click="visualizarDocumento(recursoRevisionActual)"
                  >
                    <v-icon color="white" class="mr-2">mdi-file-pdf-box</v-icon>
                    Visualizar
                  </v-btn>
                </div>
              </template>
              <template v-else>
                <v-file-input
                  v-model="recursoRevision"
                  accept="application/pdf"
                  label="Recurso de revisión *"
                  outlined
                  :error-messages="errorArchivo"
                  @blur="$v.recursoRevision.$touch"
                />
                <v-btn class="mt-4" color="primary"  @click="guardarRecursoRevision" x-large block>
                  <v-icon color="white">mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else class="mt-4">
        <template v-if="!haveRole('ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION')">
          <v-col md="5" cols="12">
            <v-autocomplete
              :items="proveedores"
              item-text="nombre"
              item-value="id"
              label="Seleccione el proveedor"
              v-model="proveedor_seleccionado"
              outlined
            />
          </v-col>
          <v-col md="5" cols="12">
            <v-file-input
              v-model="recursoRevision"
              accept="application/pdf"
              label="Recurso de revisión *"
              outlined
              :error-messages="errorArchivo"
              @blur="$v.recursoRevision.$touch"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" @click="guardarRecursoRevision" :disabled="cargandoRecursos" x-large>
              <v-icon color="white" class="mr-2">mdi-content-save</v-icon>
              Guardar
            </v-btn>
          </v-col>
        </template>
        <v-col cols="12" md="12">
          <data-table-component
            :items="recursosSubidos"
            :headers="headers"
            :custom_item_per_page="recursosSubidos.length"
            :show_loading="cargandoRecursos"
            :inhabilitar_paginacion="true"
            :tiene_paginacion="false"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ moment(item.created_at).format("DD/MM/YYYY HH:mm:ss") }}
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <div class="d-flex justify-center align-center" style="gap: 16px;">
                <v-btn 
                  icon 
                  small 
                  @click.stop="visualizarDocumento(item)" 
                  :loading="item.id === recursoRevisionActual?.id && cargandoVisualizacion"
                  :disabled="item.id !== recursoRevisionActual?.id && cargandoVisualizacion"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
  
                <v-btn 
                  icon 
                  small 
                  @click.stop="descargarDocumento(item)"
                  :loading="item.id === recursoRevisionActual?.id && descargandoDocumento"
                  :disabled="item.id !== recursoRevisionActual?.id && descargandoDocumento"  
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
  
                <v-btn 
                  icon 
                  small 
                  :disabled="haveRole('ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION')"
                  @click="abrirModalEdicionRecurso(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </data-table-component>
        </v-col>
      </v-row>
  
      <v-dialog v-model="modalVisualizacionDocumento" max-width="1000px" persistent>
        <v-card style="position: relative; z-index: 10;">
          <a 
            v-if="urlVisualizar"  
            class="d-flex align-center justify-center px-2 py-2 rounded-pill"
            style="width: 36px; height: 36px; position: sticky; float: right; z-index: 20; top: 16px; right: 16px; background-color: var(--v-primary-base);"
            target="_blank" 
            :href="urlVisualizar" 
            download="recurso-revision"
          >
            <v-icon color="#FFFFFF !important" small>mdi mdi-download</v-icon>
          </a>
          <vue-pdf-embed v-if="urlVisualizar" style="max-width: 100%; clear: both;" :source="urlVisualizar" />
          <v-card-actions style="position: sticky; bottom: 0; z-index: 20; background-color: white;" class="d-flex flex-wrap px-4 py-4">
            <v-btn @click.stop="modalVisualizacionDocumento = false" color="secondary" outlined>
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <ModalEditarRecursoRevision 
        :is-open.sync="modalEdicionRecursoAbierta"
        :id-etapa="etapa?.id"
        :id-proceso-compra="procesoData?.id"
        :proveedor="recursoRevisionActual?.Usuario?.Proveedor?.id"
        @on-save="manejarGuardadoExitosoRecursoRevision"
      />
    </Fragment>

    <v-row v-if="!tieneAccesoEtapa && !validandoParticipacion" justify="center" class="mt-8">
      <v-col cols="12">
        <EmptyComponent 
          empty
          title="No tiene acceso a esta etapa"
          :text="esProveedor ? 'No tiene una oferta en el proceso.' : ''"
          icon="mdi-door-closed-cancel"
        >
        </EmptyComponent>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import ModalEditarRecursoRevision from "./ModalEditarRecursoRevision.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import { formatDate } from "@/utils/datetime";
import { Fragment } from "vue-fragment";
import EmptyComponent from "@/components/utils/EmptyComponent.vue";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "RecursoRevisionComponent",
  data: () => ({
    // Validacion por oferta de proveedor
    tieneAccesoEtapa: false,
    validandoParticipacion: false,

    // Data
    cargandoRecursos: false,
    esNotificacionEconomica: false,
    cargandoRecursoRevisionActual: false,
    recursoRevisionActual: null,
    recursoRevision: null,
    proveedores: [],
    recursosSubidos: [],
    headers: [
      { text: "Correlativo", value: "correlativo", align: 'center' },
      { text: "Proveedor", value: "Usuario.Proveedor.nombre", align: 'center' },
      { text: "Fecha de subido", value: "created_at", align: 'center' },
      { text: "Acciones", value: "acciones", align: 'center' },
    ],
    proveedor_seleccionado: null,
    // Visualizacion
    cargandoVisualizacion: false,
    urlVisualizar: null,
    modalVisualizacionDocumento: false,
    // Descarga DOC
    descargandoDocumento: false,
    verificacionProveedorConOferta:  createLoadable(null),
    // Edicion de recurso
    modalEdicionRecursoAbierta: false,
  }),
  components: {
    DataTableComponent,
    ModalEditarRecursoRevision,
    Fragment,
    EmptyComponent,
    VuePdfEmbed,
  },
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    etapas: {
      type: Array,
      default: () => [],
    },
  },
  validations: {
    recursoRevision: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
    errorArchivo() {
      const errors = [];
      if (!this.$v.recursoRevision.$dirty) return errors;
      !this.$v.recursoRevision.required &&
        errors.push("El archivo es requerido");
      !this.$v.recursoRevision.fileTypeValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.recursoRevision.fileNameValid &&
        errors.push("El archivo debe tener un nombre valido");
      !this.$v.recursoRevision.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);

      return errors;
    },
    filename() {
      return 'recurso-revision';
    },
    esProveedor() {
      return this.haveRoles(['ROLE_PROVEEDOR']);
    },
    esTacop() {
      return this.haveRoles(['ROLE_TACOP']);
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento", "descargarArchivoProceso"]),
    formatDate,
    async guardarRecursoRevision() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append("doc_impugnacion", this.recursoRevision);
        formData.append("id_tipo_impugnacion", 1);
        formData.append("id_etapa_proceso", this.etapa.id);

        if (this.proveedor_seleccionado) {
          formData.append("id_proveedor", this.proveedor_seleccionado);
        }

        const { status } = await this.services.RecursoRevision.storeRecursoProveedor(this.procesoData.id, formData);
        
        if (status === 201) {
          this.manejarGuardadoExitosoRecursoRevision();
        }
      }
    },
    async manejarGuardadoExitosoRecursoRevision() {
      this.pushAppMessage({ type: 'success', message: 'Recurso guardado correctamente.' });
      this.proveedor_seleccionado = null;
      this.recursoRevision = null;
      this.$v.$reset();
      this.obtenerConNuevasFunciones();
    },
    async obtenerRecursoRevision() {
      try {
        this.cargandoRecursoRevisionActual = true;

        const response = await this.services.RecursoRevision.getRecursosProveedorPorTipo(
            this.procesoData.id,
            1,
            { pagination: false, id_etapa_proceso: this.etapa.id }
          );
  
        if (response.status === 200) {
          this.recursoRevisionActual = response.data[response.data.length - 1];
        }
      } finally {
        this.cargandoRecursoRevisionActual = false;
      }
    },
    async descargarRecursoRevision() {
      const response = await this.services.PacProcesos.descargarDocumentoProceso({ ruta: this.recursoRevisionActual.doc_impugnacion });

      const blob = new Blob([response.data], { type: response.headers["content-type"] });

      const file = new File([blob], this.recursoRevisionActual.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },
    abrirModalEdicionRecurso(item) {
      this.recursoRevisionActual = { ...item };
      this.modalEdicionRecursoAbierta = true;
    },
    async obtenerProveedores() {
      const { data } =
        await this.services.PacProcesos.participantesProcesoCompra(
          this.procesoData.id
        );
      this.proveedores = data;
    },
    async obtenerRecursos() {
      try {
        this.cargandoRecursos = true;
        const { data } = await this.services.PacProcesos.impugnacionesRecibidas(this.procesoData.id);
        this.recursosSubidos = data;
      } finally {
        this.cargandoRecursos = false;
      }
    },
    async obtenerConNuevasFunciones() {
      if (this.esProveedor) {
        this.obtenerRecursoRevision();
      } else {
        this.obtenerRecursos();
        this.obtenerProveedores();
      }

    },
    visualizarDocumento(documento) {
      this.recursoRevisionActual = { ...documento };
      this.cargandoVisualizacion = true;
      this.urlVisualizar = null;

      this.descargarBufferDocumento({ ruta_documento: documento.doc_impugnacion }).then((response) => {
        const base64 = Buffer.from(response).toString("base64");
        const url = `data:application/pdf;base64,${base64}`;
        this.urlVisualizar = url;
        this.modalVisualizacionDocumento = true;
      }).finally(() => {
        this.cargandoVisualizacion = false;
      });
    },
    descargarDocumento(documento) {
      this.recursoRevisionActual = { ...documento };
      this.descargandoDocumento = true;
      this.descargarArchivoProceso({ ruta_documento: documento.doc_impugnacion }).finally(() => {
        this.descargandoDocumento = false;
      });
    },
    cerrarModalVisualizacionDocumento(visible) {
      this.modalVisualizacionDocumento = visible;
    },

    async verificarProveedorConOferta() {
      try { 
        this.validandoParticipacion = true;
        toggleLoadable(this.verificacionProveedorConOferta);
        const { data } = await this.services.RecursoApelacion.verificaProveedorConOferta(this.procesoData.id);
        setLoadableResponse(this.verificacionProveedorConOferta, data, { skipOnSuccess: true });
  
        if (!isResponseSuccesful(data)) return;
  
        if (data.data) {
          this.tieneAccesoEtapa = true;
        } else {
          this.tieneAccesoEtapa = false;
          this.pushAppMessage({ type: 'error', message: 'No posee una oferta en el proceso.' });
        }
      } finally {
        this.validandoParticipacion = false;
      }
    },
  },
  async created() {    
    if (this.esProveedor) {
      await this.verificarProveedorConOferta();
    } else {
      this.tieneAccesoEtapa = this.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION']);
    }
    
    if (this.tieneAccesoEtapa) {
      this.obtenerConNuevasFunciones();
    }
  },
};
</script>

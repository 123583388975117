<template>
    <Fragment>
        <h6 class="text-h6 font-weight-bold text-uppercase mb-1">Pronunciamiento de partes</h6>
        <v-divider />
        <v-btn 
            v-if="puedeManipularDocumentos"  
            @click.stop="abrirModalCargarPronunciamiento" 
            :loading="verificacionProveedorConOferta.isLoading"
            color="primary" 
            class="mt-4"
        >
            Subir documento
        </v-btn>
        <DataTableComponent 
            :items="pronunciamientos.data"
            :headers="pronunciamientosHeaders"
            :show_loading="pronunciamientos.isLoading"
            :tiene_paginacion="false"
            class="mt-4"
        >
            <template v-slot:item.fecha_pronunciamiento="{item}">{{ formatDate(item.created_at) }}</template>
            <template v-slot:item.proveedor.nombre_comercial="{ item }">
                {{ item.proveedor?.nombre_comercial ?? 'Documento Institucional' }}
            </template>
            <template v-slot:item.acciones="{item}">
                <div class="d-flex align-center justify-center" style="gap: 16px;">
                    <v-icon
                        @click.stop="visualizarDocumento(item)"
                        :loading="visualizacionDocumento.isLoading"
                    >
                        mdi-eye
                    </v-icon>
                    <v-icon
                        v-if="puedeManipularDocumentos"
                        :disabled="esProveedor && item.pronunciamiento_institucion"
                        @click.stop="confirmarEliminacionPronunciamiento(item)"
                        :loading="eliminacionPronunciamiento.isLoading"
                    >
                        mdi-trash-can
                    </v-icon>
                </div>
            </template>
        </DataTableComponent>

        <v-dialog v-if="puedeManipularDocumentos" v-model="modalCrearPronunciamiento" max-width="700" persistent>
            <v-card class="px-4 py-4">
                <div>
                    <v-icon @click.stop="cerrarCreacion">mdi-close</v-icon>
                </div>
                <v-form ref="form" class="mt-4" @submit.prevent="validarPronunciamiento">
                    <v-row no-gutters>
                        <template v-if="!esProveedor">
                            <v-col cols="12">
                                <v-checkbox 
                                    label="Pronunciamiento de institución"
                                    v-model="form.checkBoxInstitucion"
                                    >
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" v-if="!this.form.checkBoxInstitucion">
                                <v-select 
                                    v-model="form.id_proveedor"
                                    label="Proveedor" 
                                    :items="proveedores.data"
                                    :error-messages="id_proveedorErrors"
                                    item-value="id"
                                    item-text="nombre_comercial"
                                    :loading="proveedores.isLoading"
                                    @change="$v.form.id_proveedor.$touch()"
                                    @blur="$v.form.id_proveedor.$touch()"
                                    outlined
                                />
                            </v-col>
                        </template>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.nombre" 
                                label="Nombre de documento" 
                                outlined 
                                @change="$v.form.nombre.$touch()"
                                @blur="$v.form.nombre.$touch()"
                                :error-messages="nombreErrors"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-file-input
                                v-model="form.documento"
                                label="Adjunto"
                                accept="application/pdf"
                                outlined 
                                @change="$v.form.documento.$touch()"
                                :error-messages="documentoErrors"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                                x-large 
                                color="primary" 
                                style="width: 100%;" 
                                type="submit"
                            >
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent
            :is-open="confirmacionCreacion"
            :is-loading="guardadadoPronunciamiento.isLoading"
            description="¿Desea guardar el documento?"
            @confirm="crearPronunciamiento"
            @cancel="confirmacionCreacion = false"
        />
        <ConfirmationModalComponent
            :is-open="confirmacionEliminado"
            :is-loading="eliminacionPronunciamiento.isLoading"
            description="¿Desea eliminar el documento?"
            @confirm="eliminarPronunciamiento"
            @cancel="confirmacionEliminado = false"
        />
        <PdfModal
            :isOpen="modalVisualizacionDocumento" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="cerrarModalVisualizacionDocumento"
            :filename="filename"
        />
    </Fragment>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import { Validator } from '@/utils/form-validation.js';
import { required } from 'vuelidate/lib/validators'
import { convertToFormData, removePropsFromObjectIfNil } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';

export default {
    name: 'PronunciamientoPartes',
    components: { Fragment, DataTableComponent, ConfirmationModalComponent, PdfModal },
    validations() {
        return ({
            form: {
                nombre: { required },
                documento: { required },
                ...(this.form.checkBoxInstitucion || this.esProveedor ? {} : { id_proveedor: { required } }),
            },
        });
    },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data() {
        return ({
            pronunciamientoActivo: null,
            modalCrearPronunciamiento: false,
            form: {
                nombre: null,
                documento: null,
                checkBoxInstitucion: false,
                ...!this.esProveedor && { id_proveedor: null },
            },
            proveedores: createLoadable([]),
            pronunciamientos: createLoadable([]),
            pronunciamientosHeaders: [
                { text: 'Proveedor', value: 'proveedor.nombre_comercial', align: 'center', sortable: false },
                { text: 'Documento', value: 'nombre_documento', align: 'center', sortable: false },
                { text: 'Fecha de pronunciamiento', value: 'fecha_pronunciamiento', align: 'center', sortable: false },
                { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
            ],
            // Creacion
            confirmacionCreacion: false,
            guardadadoPronunciamiento: createLoadable(null),
            pronunciamientoCrear: null,
            // Eliminado
            confirmacionEliminado: false,
            eliminacionPronunciamiento: createLoadable(null),
            // Visualizacion
            modalVisualizacionDocumento: false,
            visualizacionDocumento: createLoadable(null),
            // Validaciones
            verificacionProveedorConOferta: createLoadable(null),
        });
    },
    computed: {
        esProveedor() {
            return this.haveRoles(['ROLE_PROVEEDOR']);
        },
        esRoleTacop() {
            return this.haveRoles(['ROLE_TACOP']);
        },
        puedeManipularDocumentos() {
            // return (this.esRoleTacop || this.esProveedor);
            return true;
        },
        filename() {
            return this.pronunciamientoActivo?.nombre_documento ?? 'pronunciamiento';
        },
        // Errores
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        documentoErrors() {
            return new Validator(this.$v.form.documento).detect().getResult();
        },
        id_proveedorErrors() {
            if (this.esProveedor) return [];

            return new Validator(this.$v.form.id_proveedor).detect().getResult();
        },
        esDocumentoInstitucional() {
            return this.form.checkBoxInstitucion;
        },
    },
    methods: {
        formatDate,
        limpiarFormulario() {
            this.$refs.form.reset();
            this.$v.form.$reset();
            this.form.checkBoxInstitucion = false;
        },
        cerrarCreacion() {
            this.modalCrearPronunciamiento = false;
            this.limpiarFormulario();
        },
        validarPronunciamiento() {
            this.$v.form.$touch();

            if (this.$v.form.$invalid) return;

            this.pronunciamientoCrear = { ...this.form };
            this.modalCrearPronunciamiento = false;
            this.confirmacionCreacion = true;
            this.limpiarFormulario();
        },
        confirmarEliminacionPronunciamiento(pronunciamiento) {
            this.pronunciamientoActivo = pronunciamiento;
            this.confirmacionEliminado = true;
        },
        cerrarModalVisualizacionDocumento(visible) {
            this.modalVisualizacionDocumento = visible;
        },
        abrirModalCargarPronunciamiento() {
            if (this.esProveedor) {
                this.verificarProveedorConOferta(); 
            } else {
                this.modalCrearPronunciamiento = true;
            }
        },
        // Data
        async cargarPronunciamientos() {
            toggleLoadable(this.pronunciamientos);
            const { data } = await this.services.RecursoApelacion.cargarPronunciamientosRecursoApelacion(this.idProcesoCompra);
            setLoadableResponse(this.pronunciamientos, data);  
        },
        async crearPronunciamiento() {
            const formData = convertToFormData(removePropsFromObjectIfNil(this.pronunciamientoCrear, Object.keys(this.pronunciamientoCrear)));
            toggleLoadable(this.guardadadoPronunciamiento);
            const { data } = await this.services.RecursoApelacion.guardarPronunciamientoRecursoApelacion(this.idProcesoCompra, formData);
            this.confirmacionCreacion = false;
            setLoadableResponse(this.guardadadoPronunciamiento, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            this.cargarPronunciamientos();
        },
        async cargarProveedoresConOfertaProceso() {
            toggleLoadable(this.proveedores);
            const { data } = await this.services.RecursoApelacion.cargarProveedoresConOfertaProceso(this.idProcesoCompra);
            setLoadableResponse(this.proveedores, data);
        },
        async eliminarPronunciamiento() {
            toggleLoadable(this.eliminacionPronunciamiento);
            const { data } = await this.services.RecursoApelacion.eliminarPronunciamientoRecursoApelacion(this.idProcesoCompra, this.pronunciamientoActivo.id);
            this.confirmacionEliminado = false;
            setLoadableResponse(this.eliminacionPronunciamiento, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarPronunciamientos();
        },
        async visualizarDocumento(pronunciamiento) {
            this.pronunciamientoActivo = pronunciamiento;
            this.modalVisualizacionDocumento = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.RecursoApelacion.visualizarDocumentoEtapaResolucion(pronunciamiento.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        async verificarProveedorConOferta() {
            toggleLoadable(this.verificacionProveedorConOferta);
            const { data } = await this.services.RecursoApelacion.verificaProveedorConOferta(this.idProcesoCompra);
            setLoadableResponse(this.verificacionProveedorConOferta, data);

            if (!isResponseSuccesful(data)) return;

            if (data.data) {
                this.modalCrearPronunciamiento = true;
            } else {
                this.pushAppMessage({ type: 'error', message: 'No puede cargar el pronunciamiento. No posee una oferta en el proceso.' });
            }
        },
    },
    watch: {
        esDocumentoInstitucional() {
            this.form.id_proveedor = null;
        },
    },
    created() {
        if (!this.esProveedor) this.cargarProveedoresConOfertaProceso();
        this.cargarPronunciamientos();
    },
}
</script>
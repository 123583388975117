<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-alert v-if="verificacionRecursoRevision.data" type="info">
          Se presentaron documentos de recurso de revisión en este proceso
        </v-alert>
      </v-col>
      <v-col cols="12" sm="12" lg="9" class="pt-0">
        <DatosGeneralesComponent :procesoData="procesoData" />
      </v-col>
      <v-col cols="12" sm="12" lg="3" class="pt-0">
        <DatosCertificacionComponent :procesoData="procesoData" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center justify-space-around flex-wrap">
          <div class="d-flex align-center">
            <span class="font-weight-bold secondary--text">Duración del proceso :
            </span>
            <span class="secondary--text ml-2">
              {{ getDuracionProceso(procesoData?.duracion_del_proceso) }}</span>
          </div>
          <div class="d-flex align-center">
            <span class="font-weight-bold secondary--text">Ofertas recibidas :
            </span>
            <span class="secondary--text ml-2">{{
              procesoData?.cantidad_ofertas_recibidas?.tecnicas
            }}
              técnicas,</span>
            <span class="secondary--text ml-2">{{
              procesoData?.cantidad_ofertas_recibidas?.economicas
            }}
              económicas</span>
          </div>
          <div class="d-flex align-center">
            <span class="font-weight-bold secondary--text">
              Proceso de impugnaciones :
            </span>
            <span class="secondary--text ml-2">{{
              procesoData?.proceso_impugnaciones > 0 ? "Si" : "No"
            }}</span>
          </div>
          <div class="d-flex align-center">
            <span class="font-weight-bold secondary--text">Contratos creados :
            </span>
            <span class="secondary--text ml-2">{{ procesoData?.contratos_creados }} contrato/s</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <ParticipantesComponent :procesoData="procesoData" v-if="!esAuditorInterno || esAuditorInterno && procesoData?.fecha_adjudicacion" />
      </v-col>
      <v-col cols="12">
        <v-tabs id="main-tab-auditoria" class="my-4" hide-slider background-color="bgMinsal" v-model="id" show-arrows>
          <v-tab style="text-transform: none !important" v-for="item in contenido" class="secondary--text"
            v-if="item.show" :key="item.id">
            <span>{{ item.tab }}</span>
          </v-tab>
          <v-tab style="text-transform: none !important" class="secondary--text"
            v-if="this.procesoData?.modalidad_compra_id === 23">
            <span>Auditoria de Subasta Inversa</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="id">
          <v-tab-item v-for="item in contenido" v-if="item.show" :key="item.id">
            <component :is="item.content" class="mt-8" :procesoData="procesoData"></component>
          </v-tab-item>
          <v-tab-item v-if="this.procesoData?.modalidad_compra_id === 23">
            <SubastaAuditoria :id_proceso="Number(this.$route.params.idProceso)" :permisos="{ leer: true }" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import moment from "moment";
import DatosGeneralesComponent from "./components/DatosGeneralesComponent.vue";
import DatosCertificacionComponent from "./components/DatosCertificacionComponent.vue";
import ParticipantesComponent from "./components/ParticipantesComponent.vue";
import ContainerPIPComponent from "./components/etapas/pip/ContainerPIPComponent.vue";
import ContainerImpugnacionComponent from "./components/etapas/impugnacion/ContainerImpugnacionComponent.vue";
import ContainerContratosComponent from "./components/etapas/contratos/ContainerContratosComponent.vue";
import ContainerSNComponent from "./components/etapas/solicitudNecesidad/ContainerSNComponent.vue";
import AdjudicacionComponent from "./components/etapas/AdjudicacionComponent.vue";
import SolicitudCompraComponent from "./components/etapas/solicitudCompra/SolicitudCompraComponent.vue";
import DocumentsProccessComponent from "../components/DocumentsProccessComponent.vue";
import bajaCuantiaComponent from "./components/etapas/bajaCuantia/BajaCuantiaComponent.vue";
import LogDocumentos from "./components/etapas/logs/LogDocumentos";
import SubastaAuditoria from "@/views/ProcesoCompraDoc/components/Etapas/PublicacionResultadoSubasta.vue";
import catalogoElectronico from "@/views/ProcesoCompraDoc/components/Etapas/catalogoElectronicoComponent.vue";
import ConsultaComponent from "@/views/ProcesoCompraDoc/AuditoriaProcesos/components/etapas/consultas/listarConsultasComponent.vue"
import { createLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";

export default {
  name: "MainAuditoriaComponent",
  components: {
    DatosGeneralesComponent,
    DatosCertificacionComponent,
    ParticipantesComponent,
    SubastaAuditoria,
  },
  data: () => ({
    id: null,
    procesoData: null,
    verificacionRecursoRevision: createLoadable(false),
  }),
  computed: {
    esAuditorInterno(){
      return this.haveRole('ROLE_AUDITOR_INTERNO_AUIN');
    },
    contenido() {
      return [
        {
          id: 1,
          tab: "PIP",
          content: ContainerPIPComponent,
          show: true,
        },
        {
          id: 2,
          tab: "Sub proceso impugnaciones",
          content: ContainerImpugnacionComponent,
          show: true,
        },
        {
          id: 3,
          tab: "Adjudicación",
          content: AdjudicacionComponent,
          show: true,
        },
        {
          id: 4,
          tab: "Contratos",
          content: ContainerContratosComponent,
          show: true,
        },
        {
          id: 5,
          tab: "Solicitudes de necesidad",
          content: ContainerSNComponent,
          show: true,
        },
        {
          id: 6,
          tab: "Solicitudes de compra",
          content: SolicitudCompraComponent,
          show: true,
        },
        {
          id: 7,
          tab: "Documentos",
          content: DocumentsProccessComponent,
          show: true,
        },
        {
          id: 8,
          tab: "Baja Cuantia",
          content: bajaCuantiaComponent,
          show: true,
        },
        {
          id: 9,
          tab: "Archivo Documentos",
          content: LogDocumentos,
          show: true,
        },
        {
          id: 10,
          tab: "Catálogo electrónico",
          content: catalogoElectronico,
          show: true,
        },
        {
          id: 11,
          tab: "Consultas",
          content: ConsultaComponent,
          show: true,
        },
      ];
    },
  },
  methods: {
    async getdatosGeneralesAuditoria() {
      const { status, data } =
        await this.services.PacProcesos.datosGeneralesAuditoria(
          this.$route.params.idProceso
        );

      if (status == 200) {
        this.procesoData = data;
      }
    },
    getDuracionProceso(duracion) {
      const now = moment();
      const hoyMasDuracion = new moment(
        moment().add(duracion, "days").format("x"),
        "x"
      );
      const dias = hoyMasDuracion.diff(now, "days");
      const horas = hoyMasDuracion.subtract(dias, "days").diff(now, "hours");
      const minutos = hoyMasDuracion
        .subtract(horas, "hours")
        .diff(now, "minutes");

      return `${dias} días, ${horas} horas, ${minutos} min`;
    },
    async verificarProcesoConRecursoRevision() {
      toggleLoadable(this.verificacionRecursoRevision);
      const { data } = await this.services.PacProcesos.verificarProcesoConRecursoRevision(this.$route.params.idProceso);
      setLoadableResponse(this.verificacionRecursoRevision, data);
    },
  },
  created() {
    this.getdatosGeneralesAuditoria();
    this.verificarProcesoConRecursoRevision();
  },
};
</script>
<style lang="scss" scoped>
#main-tab-auditoria :deep(.v-tab--active) {
  color: white !important;
  background: #404a7a !important;
}

#main-tab-auditoria :deep(.v-tab.v-tab) {
  border: thin solid #404a7a !important;
  border-radius: 25px !important;
  margin: 0 10px 0 10px !important;
}

#main-tab-auditoria :deep(.v-slide-group__content) {
  justify-content: center !important;
}
</style>

<template>
  <section class="ma-8">
    <AdmisionRecursoComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 1"
      :etapa="etapaProceso"
      solo-lectura
    />

    <ResolucionRecursosComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 2"
      :etapa="etapaProceso"
      solo-lectura
    />

    <RecepcionRecursoApelacionComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 3"
      :etapa="etapaProceso"
    />

    <ResolucionRecursoApelacionComponent
      v-if="etapaProceso?.id_etapa_proceso_impugnacion === 5"
      :etapa="etapaProceso"
    />
    <div v-if="!etapaProceso" class="d-flex flex-column justify-center mt-4" style="gap: 16px;">
      <v-icon style="font-size: 60px !important;" class="secondary--text">mdi-clock</v-icon>
      <h5 class="text-h5 text-center font-weight-bold secondary--text">
        No se ha encontrado etapa activa
      </h5>
    </div>
  </section>
</template>

<script>
import AdmisionRecursoComponent from "./componentesUCP/AdmisionRecursoComponent.vue";
import ResolucionRecursosComponent from "./componentesUCP/ResolucionRecursosComponent.vue";


import RecepcionRecursoApelacionComponent from "./RecursoRevisionTACOP/RecepcionRecursoApelacionComponent.vue";
import ResolucionRecursoApelacionComponent from "./RecursoRevisionTACOP/ResolucionRecursoApelacionComponent.vue";
// import AdmisionRecursoComponent from "./RecursoRevisionTACOP/AdmisionRecursoComponent.vue";
import { mapActions } from "vuex";
export default {
  data: () => ({
    etapaProceso: null,
  }),
  components: {
    RecepcionRecursoApelacionComponent,
    // AdmisionRecursoApelacionTacopComponent,
    ResolucionRecursoApelacionComponent,
    AdmisionRecursoComponent,
    ResolucionRecursosComponent,
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["getEtapaActualProcesoImpugnacion"]),
    async getEtapaActual() {
      const response = await this.getEtapaActualProcesoImpugnacion({
        id_proceso_compra: this.$route.params.idProceso,
      });
      this.etapaProceso = response;
    },
  },
  created() {
    this.getEtapaActual();
  },
};
</script>

<style></style>

<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="700" persistent>
            <v-card>
                <v-card-title class="px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        rounded
                        text
                        @click.stop="cerrarModal"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-4 py-4">
                    <v-form v-model="formularioValidado" ref="form" @submit.prevent="validarEnvioFormulario">
                        <v-file-input
                            v-model="form.documento"
                            accept="application/pdf"
                            label="Recurso de revisión *"
                            outlined
                            :rules="documentoRules"
                        />

                        <v-text-field 
                            v-model="form.nombre"
                            label="Nombre del documento"
                            placeholder="Nombre del documento"
                            :rules="nombreRules"
                            outlined  
                        />
    
                        <div class="d-flex align-center justify-space-between mt-4" style="gap: 16px;">
                            <v-btn 
                                color="secondary" 
                                class="flex-grow-1 flex-shrink-1"
                                large
                                @click.stop="cerrarModal"
                            >
                                Cancelar
                            </v-btn>
                            <v-btn 
                                color="primary" 
                                class="flex-grow-1 flex-shrink-1"
                                large
                                type="submit"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent 
            :isOpen="confirmacionAbierta" 
            description="¿Desea actualizar el recurso de apelación?"
            @confirm="actualizarRecursoApelacion" 
            @cancel="confirmacionAbierta = false"
            :isLoading="guardadoDocumento.isLoading" 
        />
    </Fragment>
</template>
<script>
import { personalizarMensajeRegla, reglaExtensionPDF, reglaMaxLength, reglaNombreArchivoValido, reglaRequerido } from '@/utils/form-rules';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { createLoadable, setLoadableResponse, toggleLoadable, isResponseSuccesful } from '@/utils/loadable';
import { convertToFormData } from '@/utils/data';
import { Fragment } from 'vue-fragment';

const estadoInicialFormulario = {
    documento: null,
    nombre: null,
}

export default {
    name: 'ModalEditarRecursoApelacion',
    emits: ['on-save'],
    components: { Fragment, ConfirmationModalComponent },
    props: {
        isOpen: { type: Boolean },
        idProcesoCompra: { type: [Number, String] },
        proveedor: { type: [Number, String] },
    },
    data: () => ({
        // form
        form: { ...estadoInicialFormulario },
        cargaUtilFormulario: null,
        formularioValidado: false,
        confirmacionAbierta: false,
        guardadoDocumento: createLoadable(null),
    }),
    computed: {
        documentoRules() {
            return [
                personalizarMensajeRegla(reglaRequerido, 'Archivo requerido'),
                reglaExtensionPDF,
                reglaNombreArchivoValido,
            ];
        },
        nombreRules() {
            return [
                personalizarMensajeRegla(reglaRequerido, 'Nombre del archivo es requerido'),
                personalizarMensajeRegla(reglaMaxLength(250), 'El nombre no debe exceder 250 carácteres'),
            ];
        },
    },
    methods: {
        // UI
        cerrarModal() {
            this.$emit('update:isOpen', false);
        },
        limpiarFormulario() {
            this.$refs.form.reset();
            this.form = { ...estadoInicialFormulario };
        },
        reiniciarModal() {
            this.limpiarFormulario();
            this.cerrarModal();
        },
        validarEnvioFormulario() {
            this.$refs.form.validate();

            if (!this.formularioValidado) return;

            this.cargaUtilFormulario = { ...this.form };
            this.reiniciarModal();
            this.confirmacionAbierta = true;
        },
        // Data
        async actualizarRecursoApelacion() {
            const formData = convertToFormData({
                id_proveedor: this.proveedor,
                ...this.cargaUtilFormulario,
            });

            toggleLoadable(this.guardadoDocumento);
            const { data } = await this.services.RecursoApelacion.guardarDocumentoPresentacionRecursoApelacion(this.idProcesoCompra, formData);
            this.confirmacionAbierta = false; 
            setLoadableResponse(this.guardadoDocumento, data, { showAlertOnSucces: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.$emit('on-save');
        },
    },
}
</script>

<template>
  <section>
    <v-row align="center" v-if="showHeader">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!cargandoSiguientesEtapas && !ocultarAdjudicacion" cols="12">
        <p class="text-h5 secondary--text">
          Proveedores adjudicados después de resolución
        </p>
        <AddGanadorComponent :solo-lectura="soloLectura" />
      </v-col>
    </v-row>
    <br />
    <ContinuarProceso 
      :solo-lectura="soloLectura" 
      :id-proceso="idProceso" 
    />
  </section>
</template>
<script>
import AddGanadorComponent from "./AddGanadorComponent.vue";
import ContinuarProceso from "./ContinuarProceso.vue";
import { mapState } from "vuex";

export default {
  name: "ResolucionImpugnacionesComponent",
  components: { AddGanadorComponent, ContinuarProceso },
  props: {
    etapa: {
      type: Object,
      default: () => {},
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    soloLectura: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["siguientesEtapasImpugnacion"]),
    idProceso() {
      return this.$route.params.idProceso;
    },
    cargandoSiguientesEtapas() {
      return this.siguientesEtapasImpugnacion.isLoading;
    },
    ocultarAdjudicacion() {
      const siguienteEtapa = this.siguientesEtapasImpugnacion.data[0];

      if (!siguienteEtapa) return false;

      return [26].includes(siguienteEtapa.id_tipo_etapa);
    },
  },
};
</script>

<template>
    <div style="padding-bottom: 400px;">
        <ContinuarProceso 
            :id-proceso="idProceso" 
            esconderBotonContinuar 
            @actualizarEtapa="actualizarEtapa"
        />
    </div>
</template>
<script>
import { Observable } from '@/utils/observable';
import ContinuarProceso from './componentesUCP/ContinuarProceso.vue';

export default {
    name: 'PIPEditable',
    components: { ContinuarProceso },
    data: () => ({
        idProceso: null,
    }),
    methods: {
        actualizarEtapa() {
            Observable.emit('revalidar-pip-activo');
        },
    },
    created() {
        this.idProceso = this.$route.params.idProceso;
    },
}
</script>
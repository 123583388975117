<template>
    <section>
        <v-row align="center">
            <v-col cols="12" sm="12" md="6">
              <p class="text-h5 secondary--text font-weight-bold">
                {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-row align="center">
                <v-col cols="6">
                  <p class="secondary--text">
                    Fecha de Inicio:
                    {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="secondary--text">
                    Fecha de Fin:
                    {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <DataTable 
                    :headers="headers" 
                    :items="recursosConPrevencion.data" 
                    :tiene_paginacion="false" 
                    class="mt-1" 
                    dense 
                    no-gutters
                    :show_loading="recursosConPrevencion.isLoading"
                >
                    <template v-slot:item.proveedor="{ item }">
                        <span>{{ item.Usuario.Proveedor?.nombre_comercial ?? '-' }}</span>
                    </template>

                    <template v-slot:item.created_at="{ item }">
                        <span>{{ formatDate(item.created_at) }}</span>
                    </template>

                    <template v-slot:[`item.estado`]="{ item }">
                        <v-chip
                          :color="obtenerColorEstado(item.EstadoImpugnacion?.id)"
                          style="display: block"
                          class="white--text"
                          label
                        >
                          {{ item?.EstadoImpugnacion?.nombre }}
                        </v-chip>
                    </template>

                    <template v-slot:item.acciones="{ item }">
                        <div class="d-flex align-center justify-center" style="gap: 8px;">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-on="on" v-bind="attrs" @click.stop="visualizarPrevencion(item)" icon>
                                        <v-icon color="secondary">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Visualizar prevención</span>
                            </v-tooltip>
                            <v-tooltip v-if="esProveedor && !item.prevencion.subsanacion && item.EstadoImpugnacion.id === 1" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-on="on" v-bind="attrs" @click.stop="abrirModalSubsanarPrevencion(item)" icon>
                                        <v-icon color="secondary">mdi-text-box-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Subsanar prevención</span>
                            </v-tooltip>

                            <v-tooltip v-if="item.prevencion?.subsanacion" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-on="on" v-bind="attrs" @click.stop="visualizarSubsanacion(item)" icon>
                                        <v-icon color="secondary">mdi-folder-wrench</v-icon>
                                    </v-btn>
                                </template>
                                <span>Visualizar subsanación</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-on="on" v-bind="attrs" @click.stop="abrirModalDetallePrevencion(item)" icon>
                                    <v-icon color="secondary">mdi-folder-text</v-icon>
                                  </v-btn>
                                </template>
                                <span>Detalles de prevención</span>
                            </v-tooltip>
                        </div>
                    </template>
                </DataTable>
            </v-col>
        </v-row>

        <PdfModal
            :isOpen.sync="previsualizacionPrevencionAbierta" 
            :source-loadable="visualizacionPrevencion"
            filename="documento-prevencion"
            @on-visibility-change="manejarVisibilidadPrevisualizacionPrevencion"
        />

        <PdfModal
            :isOpen.sync="previsualizacionSubsanacionAbierta" 
            :source-loadable="visualizacionSubsanacion"
            filename="documento-subsanacion"
            @on-visibility-change="manejarVisibilidadPrevisualizacionSubsanacion"
        />

        <ModalSubsanarPrevencion 
            :is-open.sync="modalSubsanarPrevencionAbierta"
            :id-prevencion="recursoRevisionActivo?.prevencion.id"
            @on-save="manejarCreacionSubsanacion"
        />

        <ModalDetallePrevencion 
            :is-open.sync="modalDetallePrevencionAbierta"
            :id-recurso-revision="recursoRevisionActivo?.id"
        />
    </section>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import ModalSubsanarPrevencion from './ModalSubsanarPrevencion.vue';
import ModalDetallePrevencion from './ModalDetallePrevencion.vue';
import DataTable from "@/components/DataTableComponent.vue";
import { Listener, Observable } from '@/utils/observable';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';

export default {
    name: 'PrevencionesRecursoRevision',
    components: { DataTable, PdfModal, ModalSubsanarPrevencion, ModalDetallePrevencion },
    props: {
        etapa: { type: Object },
    },
    data: () => ({
        // UI
        previsualizacionPrevencionAbierta: false,
        recursoRevisionActivo: null,
        modalSubsanarPrevencionAbierta: null,
        previsualizacionSubsanacionAbierta: false,
        unsubscribe: null,
        modalDetallePrevencionAbierta: false,
        // Data
        recursosConPrevencion: createLoadable([]),
        visualizacionPrevencion: createLoadable(null),
        visualizacionSubsanacion: createLoadable(null),
    }),
    computed: {
        headers() {
            return [
                { text: "Código", align: "center", value: "correlativo", sortable: false },
                { text: "Proveedor", align: "center", value: "proveedor", sortable: false },
                { text: "Fecha de creación", value: "created_at", align: "center", sortable: false },
                { text: "Estado", value: "estado", align: "center", sortable: false },
                { text: "Acciones", value: "acciones", align: "center", sortable: false },
            ];
        },
        esProveedor() {
            return this.haveRoles(['ROLE_PROVEEDOR']);
        },
    },
    methods: {
        // UI
        formatDate,
        obtenerColorEstado(id_estado) {
            switch (id_estado) {
                case 1:
                    return "#8EBCE1";
                case 2:
                    return "green";
                case 3:
                    return "red";
                default:
                    return "grey";
            }
        },
        manejarVisibilidadPrevisualizacionPrevencion(visible) {
            this.previsualizacionPrevencionAbierta = visible;
        },
        manejarVisibilidadPrevisualizacionSubsanacion(visible) {
            this.previsualizacionSubsanacionAbierta = visible;
        },
        abrirModalSubsanarPrevencion(recursoRevision) {
            this.recursoRevisionActivo = { ...recursoRevision };
            this.modalSubsanarPrevencionAbierta = true;
        },
        abrirModalDetallePrevencion(recursoRevision) {
            this.recursoRevisionActivo = { ...recursoRevision };
            this.modalDetallePrevencionAbierta = true;
        },
        manejarCreacionSubsanacion() {
            Observable.emit('RECARGAR_ETAPA_ADMISION_RECURSOS');
            this.cargarRecursosConPrevencion();
        },
        // DATA
        async cargarRecursosConPrevencion() {
            toggleLoadable(this.recursosConPrevencion);
            const { data } = await this.services.PrevencionRecursoRevision.cargarRecursosConPrevencion(this.etapa.id_etapa_proceso);
            setLoadableResponse(this.recursosConPrevencion, data);
        },
        async visualizarPrevencion(recursoRevision) {
            this.recursoRevisionActivo = { ...recursoRevision };
            this.previsualizacionPrevencionAbierta = true;
            toggleLoadable(this.visualizacionPrevencion);
            const { data } = await this.services.PrevencionRecursoRevision.visualizarPrevencion(recursoRevision.prevencion.id);
            setLoadableResponse(this.visualizacionPrevencion, data, { isFile: true });
        },
        async visualizarSubsanacion(recursoRevision) {
            this.recursoRevisionActivo = { ...recursoRevision };
            this.previsualizacionSubsanacionAbierta = true;
            toggleLoadable(this.visualizacionSubsanacion);
            const { data } = await this.services.PrevencionRecursoRevision.visualizarSubsanacionPrevencion(recursoRevision.prevencion.subsanacion.id);
            setLoadableResponse(this.visualizacionSubsanacion, data, { isFile: true });
        },
    },
    created() {
        this.cargarRecursosConPrevencion();

        const listener = new Listener('PrevencionRecursoRevision').setHandler(() => this.cargarRecursosConPrevencion());
        this.unsubscribe = Observable.getInstance().subscribe('recargar-etapa-prevenciones', listener);
    },
    beforeDestroy() {
        this.unsubscribe?.();
    },
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Fragment',[_c('div',[_c('RetryDataLoading',{attrs:{"loadable":_vm.cargaHoraServidor,"retry-handler":_vm.cargarHoraServidor,"custom-class":"mx-4 mt-6"}},[(_vm.subastaSeleccionada != null && !_vm.cargaHoraServidor.isLoading)?[_c('v-card',{staticClass:"text-center py-4"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.moment(_vm.subastaSeleccionada.fecha_hora_inicio).isAfter(
                _vm.moment(_vm.serverTime, 'x')
              )
            ),expression:"\n              moment(subastaSeleccionada.fecha_hora_inicio).isAfter(\n                moment(serverTime, 'x')\n              )\n            "}],staticClass:"info--text"},[_c('app-countdown',{attrs:{"titulo":"La subasta iniciará en:","color":"info--text","date-now":_vm.moment(_vm.serverTime, 'x'),"nextDay":_vm.moment(_vm.subastaSeleccionada.fecha_hora_inicio)},on:{"status":function($event){return _vm.$emit('getSalaInfo', true)}}})],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.moment(_vm.subastaSeleccionada.fecha_hora_final).isBefore(
                _vm.moment(_vm.serverTime, 'x')
              )
            ),expression:"\n              moment(subastaSeleccionada.fecha_hora_final).isBefore(\n                moment(serverTime, 'x')\n              )\n            "}],staticClass:"error--text"},[_vm._v(" La subasta ha terminado. ")]),_c('indicacionOfertas',{attrs:{"oferta":_vm.oferta}}),(
              _vm.moment(_vm.subastaSeleccionada.fecha_hora_inicio).isBefore(
                _vm.moment(_vm.serverTime, 'x')
              )
            )?_c('div',[_c('v-alert',{attrs:{"color":"blue-grey","dark":"","dense":"","prominent":"","type":"info"}},[_c('span',[_vm._v(" La subasta del lote actual se ha planificado de "+_vm._s(_vm.moment(_vm.oferta?.subasta?.fecha_hora_inicio).format("HH:mm a"))+" hasta "+_vm._s(_vm.moment(_vm.oferta?.subasta?.fecha_hora_final).format("HH:mm a"))+" ")])]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.mensaje_advertencia &&
                !_vm.haveRole('ROLE_PROVEEDOR') &&
                !_vm.moment(_vm.subastaSeleccionada.fecha_hora_final).isBefore(
                  _vm.moment(_vm.serverTime, 'x')
                ) &&
                !_vm.moment(_vm.subastaSeleccionada.fecha_hora_inicio).isAfter(
                  _vm.moment(_vm.serverTime, 'x')
                )
              ),expression:"\n                mensaje_advertencia &&\n                !haveRole('ROLE_PROVEEDOR') &&\n                !moment(subastaSeleccionada.fecha_hora_final).isBefore(\n                  moment(serverTime, 'x')\n                ) &&\n                !moment(subastaSeleccionada.fecha_hora_inicio).isAfter(\n                  moment(serverTime, 'x')\n                )\n              "}],attrs:{"type":"info","color":"#42a5f5"}},[_vm._v(" "+_vm._s(_vm.mensajeCuentaAtras)+" ")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.mensaje_advertencia &&
                !_vm.actualGanador &&
                _vm.haveRole('ROLE_PROVEEDOR') &&
                !_vm.moment(_vm.subastaSeleccionada.fecha_hora_final).isBefore(
                  _vm.moment(_vm.serverTime, 'x')
                ) &&
                !_vm.moment(_vm.subastaSeleccionada.fecha_hora_inicio).isAfter(
                  _vm.moment(_vm.serverTime, 'x')
                )
              ),expression:"\n                mensaje_advertencia &&\n                !actualGanador &&\n                haveRole('ROLE_PROVEEDOR') &&\n                !moment(subastaSeleccionada.fecha_hora_final).isBefore(\n                  moment(serverTime, 'x')\n                ) &&\n                !moment(subastaSeleccionada.fecha_hora_inicio).isAfter(\n                  moment(serverTime, 'x')\n                )\n              "}],staticClass:"white--text",attrs:{"color":"#42a5f5"}},[_vm._v(" "+_vm._s(_vm.fechaEventoCierreConfigurado.mensaje)+" ")]),_c('br'),_c('app-countdown-segundos',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.moment(_vm.subastaSeleccionada.fecha_hora_final).isBefore(
                  _vm.moment(_vm.serverTime, 'x')
                ) &&
                !_vm.moment(_vm.subastaSeleccionada.fecha_hora_inicio).isAfter(
                  _vm.moment(_vm.serverTime, 'x')
                )
              ),expression:"\n                !moment(subastaSeleccionada.fecha_hora_final).isBefore(\n                  moment(serverTime, 'x')\n                ) &&\n                !moment(subastaSeleccionada.fecha_hora_inicio).isAfter(\n                  moment(serverTime, 'x')\n                )\n              "}],attrs:{"nextDay":_vm.fechaEventoCierreConfigurado?.tiempo,"nuevo_tiempo":_vm.fechaEventoCierreConfigurado?.nuevo_tiempo},on:{"status":function($event){return _vm.$emit('cierreSala', !_vm.fechaEventoCierreConfigurado?.tiempo !== 0)},"cierreSala":_vm.cambiar_visibilidad_}})],1):_vm._e(),_c('span',{staticClass:"text-h4 subastaBlue--text"},[_vm._v(" "+_vm._s(_vm.subastaSeleccionada.nombre_lote)+" ")]),(
              !_vm.moment(_vm.subastaSeleccionada.fecha_hora_final).isBefore(
                _vm.moment(_vm.serverTime, 'x')
              )
            )?_c('div',{staticClass:"d-flex flex-column mx-6",class:_vm.actualGanador ? 'ganadorClass elevation-6' : ''},[(_vm.loadingOferta)?_c('span',{staticClass:"text-h3 font-weight-medium"},[_vm._v("Cargando...")]):(
                ['resta_monto', 'resta_unitaria'].includes(
                  _vm.oferta?.subasta?.CtlSubastum.tipo_operacion
                )
              )?_c('span',{staticClass:"text-h1 font-weight-medium"},[_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.subastaActivaMejorPuja))+" USD ")]):(
                [
                  'suma_porcentaje',
                  'resta_porcentaje_publicidad',
                  'resta_porcentaje',
                ].includes(_vm.oferta?.subasta?.CtlSubastum.tipo_operacion)
              )?_c('span',[_c('span',{staticClass:"text-h1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.subastaActivaMejorPujaPorcentaje)+"% "),_c('br')]),_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { currency: "USD", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.totalSubasta))+" ")]):_vm._e(),(_vm.actualGanador)?_c('span',{staticClass:"text-h6"},[_vm._v(" ¡Vas ganando! ")]):_vm._e()]):_c('div',[_c('v-alert',{attrs:{"type":"info"}},[_c('span',{staticClass:"text-h4"},[_vm._v(" La subasta ha finalizado ")])])],1),(
              _vm.haveRole('ROLE_FRONT_PROVEEDOR_PROCESO_COMPRA') &&
              _vm.moment(_vm.subastaSeleccionada.fecha_hora_inicio).isBefore(
                _vm.moment(_vm.serverTime, 'x')
              )
            )?[(
                !_vm.moment(_vm.subastaSeleccionada.fecha_hora_final).isBefore(
                  _vm.moment(_vm.serverTime, 'x')
                )
              )?[(
                  ['resta_monto', 'resta_unitaria'].includes(
                    _vm.oferta?.subasta?.CtlSubastum.tipo_operacion
                  )
                )?[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-wrap align-center justify-center justify-space-around"},_vm._l((_vm.ofertas),function(ofertaBid,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({key:index,staticClass:"my-4 mx-2",style:(`background-color: ${ofertaBid.color}`),attrs:{"disabled":_vm.loadingOfertar,"outlined":"","color":ofertaBid.color,"dark":"","depressed":""},on:{"click":function($event){return _vm.ofertar(ofertaBid.monto)}}},on),[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { currency: "USD", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ofertaBid?.monto))+" ")])])]}}],null,true)},[(
                          ['resta_unitaria', 'resta_monto'].includes(
                            _vm.oferta?.subasta?.CtlSubastum.tipo_operacion
                          )
                        )?_c('span',[_vm._v(" Su oferta será por un total de: "+_vm._s(Intl.NumberFormat("en-US", { currency: "USD", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format( ofertaBid?.monto * (_vm.oferta?.subasta?.cantidad ?? 1) ))+" ")]):_vm._e()])}),1)]),[(this.oferta?.subasta?.monto_digitado)?_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('div',{staticClass:"digitar_puja"},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","placeholder":"Digite su puja y presione enter para enviar"},on:{"keyup":_vm.validarPujaDigitada,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.ofertar(_vm.montoPuja, true)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"disabled":_vm.loadingOfertar},on:{"click":function($event){return _vm.ofertar(_vm.montoPuja, true)}}},[_vm._v("mdi-send-variant-outline")])]},proxy:true}],null,false,1428308520),model:{value:(_vm.montoPuja),callback:function ($$v) {_vm.montoPuja=$$v},expression:"montoPuja"}})],1)]):_vm._e()]]:(
                  [
                    'resta_porcentaje',
                    'suma_porcentaje',
                    'resta_porcentaje_publicidad',
                  ].includes(_vm.oferta?.subasta?.CtlSubastum.tipo_operacion)
                )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","placeholder":"Digite su puja"},on:{"keyup":_vm.validarPuja},model:{value:(_vm.montoPujaPorcentaje),callback:function ($$v) {_vm.montoPujaPorcentaje=$$v},expression:"montoPujaPorcentaje"}}),_c('v-btn',{staticClass:"subastaBlue--text",on:{"click":_vm.enviarPujaPorcentual}},[_vm._v(" Enviar puja "),(
                      _vm.montoPujaPorcentaje &&
                      ['suma_porcentaje'].includes(
                        _vm.oferta?.subasta?.CtlSubastum.tipo_operacion
                      )
                    )?_c('span',{staticClass:"black--text"},[_vm._v("       (equivalente a "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format( _vm.montoInicial - (_vm.montoPujaPorcentaje * _vm.montoInicial) / 100 ))+") ")]):_vm._e()])],1):_vm._e()]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"my-8 pt-12 text-center text-h6"},[_c('span',{staticClass:"subastaBlue--text",domProps:{"textContent":_vm._s('Monto Inicial: ')}}),_c('span',[_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.montoInicial))+" ")])])]:_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mb-8",attrs:{"fluid":""}},[(_vm.cargandoEtapas)?[_c('v-skeleton-loader',{attrs:{"type":"button"}}),_c('v-skeleton-loader',{staticClass:"mt-2",attrs:{"type":"paragraph"}})]:[(_vm.mostrarIndicadorInscripcionEtapa)?_c('div',[_c('h1',{staticClass:"secondary--text text-center mt-8"},[_vm._v(" Debe inscribirse en el proceso para poder observar la etapa actual ")])]):_vm._e(),(_vm.cargandoEtapas)?_c('div',{staticClass:"d-flex align-center flex-wrap",staticStyle:{"gap":"16px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":25,"width":3}}),_c('span',{staticClass:"text-uppercase font-weight-bold text-subtitle-1"},[_vm._v("Cargando etapas...")])],1):_vm._e(),(!_vm.mostrarIndicadorInscripcionEtapa)?[(_vm.etapas.length === 0)?_c('div',{staticClass:"d-flex flex-column justify-center mt-4",staticStyle:{"gap":"16px"}},[_c('v-icon',{staticClass:"secondary--text",staticStyle:{"font-size":"60px !important"}},[_vm._v("mdi-clock")]),_c('h5',{staticClass:"text-h5 text-center font-weight-bold secondary--text"},[_vm._v(" No se ha encontrado etapa activa ")])],1):[(!_vm.participando && !_vm.haveRole('ROLE_PROVEEDOR') && _vm.etapas.length === 0 && _vm.procesoData?.id_seguimiento_proceso !== 11)?_c('div',{staticClass:"d-flex flex-column justify-center mt-4",staticStyle:{"gap":"16px"}},[_c('v-icon',{staticClass:"secondary--text",staticStyle:{"font-size":"60px !important"}},[_vm._v("mdi-cancel")]),_c('h5',{staticClass:"text-h5 text-center font-weight-bold secondary--text"},[_vm._v(" No tiene acceso a esta etapa ")])],1):_vm._e()]]:_vm._e(),(_vm.procesoData?.id_seguimiento_proceso == 11)?[_c('h3',{staticClass:"mt-8 text-center"},[_vm._v(" Realice las solicitudes de compra correspondientes en este período. ")])]:_vm._e(),(
      (_vm.procesoData?.id_seguimiento_proceso !== 11 &&
        _vm.haveRole('ROLE_PROVEEDOR') &&
        _vm.participando) ||
      (_vm.procesoData?.id_seguimiento_proceso === 3 &&
        !_vm.haveRole('ROLE_PROVEEDOR')) ||
      (_vm.procesoData?.id_seguimiento_proceso === 12 &&
        !_vm.haveRole('ROLE_PROVEEDOR'))
    )?_vm._l((_vm.etapas),function(etapa){return _c('div',{key:etapa.id},[_c('v-row',{staticClass:"display-flex align-center mb-4 mt-8"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"align":"center"}},[_c('h5',{staticClass:"text-h5 secondary--text"},[_vm._v(_vm._s(etapa?.nombre))]),(
                  _vm.haveRoles([
                    'ROLE_UACI',
                    'ROLE_UACI_TECNICO',
                    'ROLE_DIRECCION_COMPRAS',
                    'ROLE_TECNICO_DIRECCION_COMPRAS',
                  ]) && 
                  _vm.procesoData?.id_seguimiento_proceso !== 11 &&
                  !([19].includes(etapa?.id_tipo_etapa) && _vm.haveRole('ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION'))
                )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{staticClass:"secondary--text text-no-style",attrs:{"text":""},on:{"click":function($event){return _vm.cerrarEtapa(etapa.id)}}},[_vm._v(" Cerrar etapa ")])],1)],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('p',{staticClass:"h6-text secondary--text mb-0 mt-0"},[_vm._v(" Fecha de Inicio: "),_c('br'),_vm._v(" "+_vm._s(_vm.moment(etapa?.fecha_hora_inicio).format("DD-MM-YYYY HH:mm A"))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('p',{staticClass:"h6-text secondary--text mb-0 mt-0"},[_vm._v(" Fecha de Fin: "),_c('br'),_vm._v(" "+_vm._s(_vm.moment(etapa?.fecha_hora_fin).format("DD-MM-YYYY HH:mm A"))+" ")])])],1),(etapa?.FormaContratacionEtapa?.indicaciones?.length > 0)?_c('v-row',[_c('v-col',[_c('b',[_vm._v("Indicaciones de hito:")]),_vm._v(" "+_vm._s(etapa?.FormaContratacionEtapa?.indicaciones)+" ")])],1):_vm._e(),([1, 3, 17, 18, 21].includes(etapa?.id_tipo_etapa))?_c('v-row',[_c('v-col',[([2].includes(etapa?.id_tipo_configuracion))?_c('RichTextComponent',{staticClass:"mb-8",attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}}):_vm._e(),([1, 3, 17, 18, 21].includes(etapa?.id_tipo_configuracion))?_c('AdminDocumentosComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}}):_vm._e()],1)],1):_vm._e(),([6].includes(etapa?.id_tipo_etapa))?_c('v-row',[(_vm.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_COOP', 'ROLE_FRONT_LECTURA_CALLCENTER']))?_c('v-col',{attrs:{"cols":"12"}},[_c('AdjudicacionComponent',{staticClass:"my-8",attrs:{"proceso":_vm.procesoData,"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1):_vm._e()],1):_vm._e(),(
            [4, 10, 11].includes(etapa?.id_tipo_etapa) &&
            _vm.haveRoles(['ROLE_PROVEEDOR', 'ROLE_COOP','ROLE_FRONT_LECTURA_CALLCENTER'])
          )?_c('v-row',[_c('v-col',[_c('RecepcionDeOfertasComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),(
            [5, 20].includes(etapa?.id_tipo_etapa) &&
            !_vm.haveRole('ROLE_PROVEEDOR') &&
            !etapa?.nombre.includes('Evaluación')
          )?_c('v-row',{staticClass:"mb-8"},[_c('v-col',[_c('AperturaOfertasNotificacionComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),(
            [4, 10, 11, 20].includes(etapa?.id_tipo_etapa) &&
            _vm.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])
          )?_c('v-row',[_c('v-col',[_c('h5',{staticClass:"secondary--text text-h5 mb-8"},[_vm._v("Ofertas recibidas")]),_c('OfertasEconomicasNotificacionComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}}),_c('v-divider',{staticClass:"my-4 mb-8"}),_c('RecepcionDeOfertasComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),([5, 12, 13].includes(etapa?.id_tipo_etapa))?_c('v-row',[_c('v-col',[(_vm.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO']))?_c('h5',{staticClass:"secondary--text text-h5 mb-8"},[_vm._v(" Ofertas recibidas ")]):_vm._e(),_c('OfertasEconomicasNotificacionComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}}),_c('v-divider',{staticClass:"my-4 mb-8"}),_c('RecepcionDeOfertasComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),(
            [6].includes(etapa?.id_tipo_etapa) &&
            _vm.haveRoles([
              'ROLE_UFI',
              'ROLE_UFI_TECNICO',
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
            ])
          )?_c('v-row',[(_vm.haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO']))?_c('v-col',{attrs:{"cols":"12"}},[_c('AsignacionCdfComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1):_vm._e()],1):_vm._e(),([7].includes(etapa?.id_tipo_etapa))?_c('v-row',[_c('v-col',[_c('RecepcionConsultasComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),([8].includes(etapa?.id_tipo_etapa))?_c('v-row',[_c('v-col',[_c('CatalogoSubastaComponentRefactor',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),([9].includes(etapa?.id_tipo_etapa))?_c('v-row',[_c('v-col',[_c('SubastaInversaComponent')],1)],1):_vm._e(),(etapa.id_tipo_etapa === 22)?_c('v-row',[_c('v-col',[_c('PublicacionResultadoSubasta',{attrs:{"permisos":_vm.permisosEtapaUsuario(etapa),"id_proceso":Number(_vm.id_proceso)}})],1)],1):_vm._e(),([23, 25].includes(etapa.id_tipo_etapa))?_c('v-row',[_c('v-col',[_c('v-divider',{staticClass:"my-4"}),_c('AdminDocumentosComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),([24].includes(etapa.id_tipo_etapa))?_c('v-row',[_c('v-col',[_c('v-divider',{staticClass:"my-4"}),_c('SolicitudCarritoCE',{attrs:{"id_proceso":_vm.procesoData.id}})],1)],1):_vm._e(),(
            [14, 15, 16].includes(etapa?.id_tipo_etapa) &&
            _vm.participando &&
            _vm.haveRole('ROLE_PROVEEDOR')
          )?_c('v-row',[_c('v-col',[_c('NotificacionResultadosComponent',{attrs:{"etapa":etapa,"etapas":_vm.etapas,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),(
            [14, 15, 16].includes(etapa?.id_tipo_etapa) &&
            _vm.haveRoles([
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_FRONT_LECTURA_CALLCENTER'])
          )?_c('v-row',[_c('v-col',[_c('AdminDocumentosComponent',{attrs:{"etapa":etapa,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),(
            [19].includes(etapa?.id_tipo_etapa)
        )?_c('v-row',[_c('v-col',[_c('RecursosRevisionComponent',{attrs:{"etapa":etapa,"etapas":_vm.etapas}})],1)],1):_vm._e(),([26].includes(etapa?.id_tipo_etapa))?_c('v-row',[_c('v-col',[_c('NotificacionResolucionResultados',{attrs:{"id-proceso":_vm.procesoData.id,"etapa":etapa}})],1)],1):_vm._e(),([27].includes(etapa?.id_tipo_etapa))?_c('v-row',[_c('v-col',[_c('PresentacionRecursoApelacion',{attrs:{"id-proceso":_vm.procesoData.id}})],1)],1):_vm._e(),([2].includes(etapa?.id_tipo_etapa))?_c('v-row',[_c('v-col',[_c('ContratacionComponent',{staticClass:"mb-8",attrs:{"etapa":etapa,"proceso":_vm.procesoData,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e(),(_vm.etapas.length > 1)?_c('v-divider',{staticClass:"my-4"}):_vm._e()],1)}):_vm._e(),(([81].includes(_vm.procesoData?.id_seguimiento_proceso) && _vm.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_FRONT_LECTURA_CALLCENTER'])))?[_c('v-alert',{staticClass:"my-4",attrs:{"type":"info","dense":""}},[_vm._v(" El contrato se encuentra liquidado ")])]:_vm._e(),(
      ([5, 80].includes(_vm.procesoData?.id_seguimiento_proceso) &&
        _vm.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_FRONT_LECTURA_CALLCENTER']))
    )?_vm._l((_vm.etapas),function(etapa){return _c('div',{key:etapa.id},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"align":"center"}},[_c('h5',{staticClass:"text-h5 secondary--text"},[_vm._v(_vm._s(etapa?.nombre))])])],1),([2].includes(etapa?.id_tipo_etapa))?_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('ContratacionComponent',{staticClass:"mb-8",attrs:{"etapa":etapa,"proceso":_vm.procesoData,"permisos":_vm.permisosEtapaUsuario(etapa)}})],1)],1):_vm._e()],1)}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
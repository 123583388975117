<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="700" persistent>
            <v-card>
                <v-card-title class="px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        rounded
                        text
                        @click.stop="cerrarModal"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-4 py-4">
                    <v-form v-model="formularioValidado" ref="form" @submit.prevent="validarEnvioFormulario">
                        <v-file-input
                            v-model="archivoRecurso"
                            accept="application/pdf"
                            label="Recurso de revisión *"
                            outlined
                            
                            :rules="documentoRules"
                        />
    
                        <div class="d-flex align-center justify-space-between mt-4" style="gap: 16px;">
                            <v-btn 
                                color="secondary" 
                                class="flex-grow-1 flex-shrink-1"
                                large
                                @click.stop="cerrarModal"
                            >
                                Cancelar
                            </v-btn>
                            <v-btn 
                                color="primary" 
                                class="flex-grow-1 flex-shrink-1"
                                large
                                type="submit"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent 
            :isOpen="confirmacionAbierta" 
            description="¿Desea actualizar el recurso de revisión?"
            @confirm="actualizarRecursoRevision" 
            @cancel="confirmacionAbierta = false"
            :isLoading="false" 
        />
    </Fragment>
</template>
<script>
import { reglaExtensionPDF, reglaNombreArchivoValido, reglaRequerido, personalizarMensajeRegla } from '@/utils/form-rules';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { Fragment } from 'vue-fragment';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';

export default {
    name: 'ModalEditarRecursoRevision',
    emits: ['on-save'],
    components: { Fragment, ConfirmationModalComponent },
    props: {
        isOpen: { type: Boolean },
        idProcesoCompra: { type: [Number, String] },
        idEtapa: { type: [Number, String] },
        proveedor: { type: [Number, String] },
    },
    data: () => ({
        cargaUtilFormulario: null,
        archivoRecurso: null,
        formularioValidado: false,
        confirmacionAbierta: false,
        guardadoRecurso: createLoadable(null),
    }),
    computed: {
        documentoRules() {
            return [
                personalizarMensajeRegla(reglaRequerido, 'Archivo requerido'),
                reglaExtensionPDF, 
                reglaNombreArchivoValido
            ];
        },
    },
    methods: {
        cerrarModal() {
            this.$emit('update:isOpen', false);
        },
        limpiarFormulario() {
            this.$refs.form.reset();
            this.archivoRecurso = null;
        },
        reiniciarModal() {
            this.limpiarFormulario();
            this.cerrarModal();
        },
        validarEnvioFormulario() {
            this.$refs.form.validate();

            if (!this.formularioValidado) return;

            this.cargaUtilFormulario = this.archivoRecurso;
            this.reiniciarModal();
            this.confirmacionAbierta = true;
        },
        async actualizarRecursoRevision() {
            try {
                toggleLoadable(this.guardadoRecurso);
                const formData = new FormData();
                formData.append("doc_impugnacion", this.cargaUtilFormulario);
                formData.append("id_tipo_impugnacion", 1);
                formData.append("id_etapa_proceso", this.idEtapa);
    
                if (this.proveedor) {
                    formData.append("id_proveedor", this.proveedor);
                }
    
                const { status } = await this.services.RecursoRevision.storeRecursoProveedor(this.idProcesoCompra, formData);

                if (status === 201) this.$emit('on-save');
            } finally {
                this.limpiarFormulario();
                this.confirmacionAbierta = false;
                setLoadableResponse(this.guardadoRecurso, { data: null, }, { skipOnSuccess: true, dontShowError: true });
            }
        },
    }
}
</script>
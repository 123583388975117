<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="800px" persistent>
            <v-card style="position: relative;">
                <v-card-title class="px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-4 py-4">
                    <v-form v-model="formularioValidado" ref="form" @submit.prevent="validarEnvioFormulario">
                        <v-file-input
                            v-model="form.adjunto"
                            outlined
                            light
                            label="Documento"
                            placeholder="Documento"
                            accept="application/pdf"
                            :rules="reglasValidacionAdjunto"
                        />
    
                        <v-textarea
                            v-model="form.motivo"
                            outlined
                            label="Motivo"
                            placeholder="Descripción del motivo"
                            class="mt-4"
                            rows="4"
                            no-resize
                            :rules="reglasValidacionMotivo"
                        />
    
                        <div 
                            class="d-flex justify-center align-center flex-wrap flex-sm-nowrap mt-4" 
                            style="position: sticky; bottom: 16px; background: white; gap: 16px;"
                        >
                            <v-btn 
                                class="flex-grow-1 flex-shrink-1" 
                                style="flex-basis: 50%;"
                                color="secondary" 
                                large
                                @click.stop="cerrarModal"
                            >
                                Cerrar
                            </v-btn>
    
                            <v-btn 
                                class="flex-grow-1 flex-shrink-1" 
                                style="flex-basis: 50%;"
                                color="primary" 
                                type="submit"
                                large
                            >
                                Crear prevención
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent 
            :isOpen="confirmacionAbierta" 
            description="¿Desea continuar con la creación de la prevención?" 
            @confirm="crearPrevencion" 
            @cancel="confirmacionAbierta = false"  
            :isLoading="creacionPrevencion.isLoading"
        />
    </Fragment>
</template>
<script>
import { personalizarMensajeRegla, reglaExtensionPDF, reglaNombreArchivoValido, reglaRequerido } from '@/utils/form-rules';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { Fragment } from 'vue-fragment';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { convertToFormData } from '@/utils/data';

export default {
    name: 'ModalCrearPrevencion',
    components: { Fragment, ConfirmationModalComponent },
    emits: ['on-save'],
    props: {
        isOpen: {  type: Boolean },
        idRecursoRevision: { type: [String, Number] }
    },
    data: () => ({
        form: {
            motivo: null,
            adjunto: null,
        },
        cargaUtilFormulario: {
            motivo: null,
            motivo: null,
        },
        formularioValidado: false,
        confirmacionAbierta: false,
        creacionPrevencion: createLoadable(null),
    }),
    computed: {
        reglasValidacionAdjunto() {
            return [personalizarMensajeRegla(reglaRequerido, 'Archivo requerido'), reglaExtensionPDF, reglaNombreArchivoValido];
        },
        reglasValidacionMotivo() {
            return [personalizarMensajeRegla(reglaRequerido, 'Campo requerido')];
        },
    },
    methods: {
        emitirCierreModal() {
            this.$emit('update:isOpen', false);
        },
        reiniciarFormulario() {
            this.$refs.form.reset();
            this.form = {
                adjunto: null,
                motivo: null,
            }
        },
        cerrarModal() {
            this.reiniciarFormulario();
            this.emitirCierreModal();
        },
        validarEnvioFormulario() {
            this.$refs.form.validate();

            if (!this.formularioValidado) return;

            this.cargaUtilFormulario = { ...this.form };
            this.cerrarModal();
            this.confirmacionAbierta = true;
        },
        async crearPrevencion() {
            const formData = convertToFormData(this.cargaUtilFormulario);

            toggleLoadable(this.creacionPrevencion);
            const { data } = await this.services.PrevencionRecursoRevision.crearPrevencion(this.idRecursoRevision, formData);
            this.confirmacionAbierta = false;
            setLoadableResponse(this.creacionPrevencion, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.$emit('on-save');
            }
        },
    },
}
</script>
<template>
  <div class="pa-4">
    <p class="text-h6">Administradores de contrato</p>
    <data-table-component
      :headers="headers"
      :items="solicitud.persona.DocumentoPersonas"
      mobile_breakpoint="725"
      :tiene_paginacion="false"
    >
    <template v-slot:[`item.no`]="{ item }">
        {{
          solicitud.persona.DocumentoPersonas.map((col) => col.id_persona).indexOf(item.id_persona) + 1
        }}
      </template>
      <template v-slot:[`item.nombres`]>
        {{ getNombres }}
      </template>
      <template v-slot:[`item.apellidos`]>
        {{ getApellidos }}
      </template>
    </data-table-component>
  </div>
</template>
  <script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "AdminContratoComponent",
  components: {
    DataTableComponent,
  },
  props: {
    solicitud: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      {
        text: "Correlativo",
        align: "center",
        value: "no",
        sortable: false,
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombres",
      },
      {
        text: "Apellidos",
        align: "center",
        value: "apellidos",
      },
      {
        text: "DUI",
        value: "numero_docume",
        align: "center",
        sortable: false,
      },
    ],
  }),
  computed: {
    getNombres() {
      let nombres;
      if (this.solicitud.persona.primer_nombre !== null) {
        nombres = this.solicitud.persona.primer_nombre + " ";
      }
      if (this.solicitud.persona.segundo_nombre !== null) {
        nombres += this.solicitud.persona.segundo_nombre + " ";
      }
      if (this.solicitud.persona.tercer_nombre !== null) {
        nombres += this.solicitud.persona.tercer_nombre + " ";
      }
      return nombres;
    },
    getApellidos() {
      let apellidos;
      if (this.solicitud?.persona.primer_apellido !== null) {
        apellidos = this.solicitud?.persona.primer_apellido + " ";
      }
      if (this.solicitud?.persona.segundo_apellido !== null) {
        apellidos += this.solicitud?.persona.segundo_apellido + " ";
      }
      if (this.solicitud?.persona.apellido_casada !== null) {
        apellidos += this.solicitud?.persona.apellido_casada + " ";
      }
      return apellidos;
    },
  },
};
</script>
<template>
    <Fragment>
        <v-btn 
            v-if="!soloLectura" 
            color="primary"
            :loading="verificacionProveedorConOferta.isLoading"
            class="mb-4"
            :disabled="esProveedor && !esProveedorConOferta"
            @click.stop="abrirModalCrearPronunciamiento"
        >
            Cargar pronunciamiento
        </v-btn>

        <v-alert v-if="esProveedor && !esProveedorConOferta && !verificandoProveedorConOferta" type="info" class="mb-4" dense>
            No posee una oferta en el proceso de compra
        </v-alert>
        <DataTable
            dense
            no-gutters
            :headers="headersPronunciamiento" 
            :items="pronunciamientos.data" 
            :show_loading="pronunciamientos.isLoading"
            :tiene_paginacion="false"
        >
            <template v-slot:[`item.proveedor`]="{ item }">
            {{ item?.Usuario?.Proveedor?.nombre_comercial }}
            </template>
            <template v-slot:[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="visualizarDocumentoPronunciamiento(item)">
                <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn v-if="!esTacop && !soloLectura" @click.stop="confirmarEliminacionPronunciamiento(item)" icon>
                <v-icon>
                mdi-trash-can
                </v-icon>
            </v-btn>
            </template>
        </DataTable>

        <!-- Confirmar eliminacion pronunciamiento -->
        <ConfirmationModalComponent
            :is-open="confirmacionEliminacionPronunciamiento"
            :is-loading="eliminacionPronunciamiento.isLoading"
            description="¿Desea eliminar el documento?"
            @confirm="eliminarPronunciamiento"
            @cancel="confirmacionEliminacionPronunciamiento = false"
        />
        

        <PdfModal
            :isOpen="modalVisualizacionDocumentoPronunciamiento" 
            :source-loadable="visualizacionDocumentoPronunciamiento" 
            @on-visibility-change="cerrarModalVisualizacionDocumentoPronunciamiento"
            filename="pronunciamiento"
        />

        <ModalCargarPronunciamientoAdjudicado
            :is-open.sync="modalPronunciamientoAbierta" 
            :id-proceso="idProcesoCompra" 
            @on-save="getPronunciamientoProveedor"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from "@/utils/loadable";
import ModalCargarPronunciamientoAdjudicado from "../compartidos/ModalCargarPronunciamientoAdjudicado.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import DataTable from "@/components/DataTableComponent.vue";
import PdfModal from '@/components/PdfModal.vue';
import { Fragment } from 'vue-fragment';

export default {
    name: 'PronunciamientosAdjudicados',
    components: { Fragment, DataTable, PdfModal, ConfirmationModalComponent, ModalCargarPronunciamientoAdjudicado },
    props: {
        soloLectura: { type: Boolean },
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        verificandoProveedorConOferta: false,
        esProveedorConOferta: false,
        headersPronunciamiento: [
            { text: "Proveedor", value: "proveedor", align: "center", width: "30%", sortable: false },
            { text: "Fecha de pronunciamiento", value: "fecha_carga", align: "center", width: "20%", sortable: false },
            { text: "Acciones", value: "acciones", align: "center", width: "20%", sortable: false },
        ],
        verificacionProveedorConOferta: createLoadable(null),
        // Pronunciamiento del adjudicado
        pronunciamientos: createLoadable([]),
        eliminacionPronunciamiento: createLoadable(null),
        modalPronunciamientoAbierta: false,
        confirmacionEliminacionPronunciamiento: false,
        pronunciamientoActivo: null,
        // Visualizacion
        modalVisualizacionDocumentoPronunciamiento: false,
        visualizacionDocumentoPronunciamiento: createLoadable(null),
    }),
    computed: {
        esTacop() {
            return this.haveRoles(['ROLE_TACOP']);
        },
        esProveedor() {
            return this.haveRoles(['ROLE_PROVEEDOR']);
        },
    },
    methods: {
        abrirModalCrearPronunciamiento() {
            this.modalPronunciamientoAbierta = true;
        },
        // Pronunciamiento
        confirmarEliminacionPronunciamiento(pronunciamiento) {
            this.pronunciamientoActivo = pronunciamiento;
            this.confirmacionEliminacionPronunciamiento = true;
        },
        async eliminarPronunciamiento() {
            toggleLoadable(this.eliminacionPronunciamiento);
            const { data } = await this.services.PacProcesos.eliminarPronunciamientoImpugnacion(this.idProcesoCompra, this.pronunciamientoActivo.id);
            this.confirmacionEliminacionPronunciamiento = false;
            setLoadableResponse(this.eliminacionPronunciamiento, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.getPronunciamientoProveedor();
        },
        cerrarModalVisualizacionDocumentoPronunciamiento(visible) {
            this.modalVisualizacionDocumentoPronunciamiento = visible;
        },
        async visualizarDocumentoPronunciamiento(pronunciamiento) {
            this.modalVisualizacionDocumentoPronunciamiento = true;
            toggleLoadable(this.visualizacionDocumentoPronunciamiento);
            const { data } = await this.services.RecursoApelacion.visualizarDocumentoEtapaResolucion(pronunciamiento.id);
            setLoadableResponse(this.visualizacionDocumentoPronunciamiento, data, { isFile: true });
        },
        async getPronunciamientoProveedor() {
            toggleLoadable(this.pronunciamientos);
            const { data } = await this.services.PacProcesos.listarPronunciamientos(this.idProcesoCompra);
            setLoadableResponse(this.pronunciamientos, data);
        },
        async verificarProveedorConOferta() {
            try {
                this.verificandoProveedorConOferta = true;
                if (!this.esProveedor) return;
    
                toggleLoadable(this.verificacionProveedorConOferta);
                const { data } = await this.services.RecursoApelacion.verificaProveedorConOferta(this.idProcesoCompra);
                setLoadableResponse(this.verificacionProveedorConOferta, data, { skipOnSuccess: true });
    
                if (isResponseSuccesful(data)) {
                    this.esProveedorConOferta = data.data;
                }
            } finally {
                this.verificandoProveedorConOferta = false;
            }
        },
    },
    created() {
        this.getPronunciamientoProveedor();
        this.verificarProveedorConOferta();
    },
}
</script>
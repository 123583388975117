<template>
  <section v-if="items.length > 0">
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DataTable :headers="headers" :items="items" :tiene_paginacion="false">
          <template v-slot:[`item.proveedor`]="{ item }">
            {{ item?.Usuario?.Proveedor?.nombre_comercial }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="secondary" @click="showDocument(item), (doc_revision_selected = item)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip
              :color="getColor(item?.EstadoImpugnacion?.id)"
              :class="[6, 7].includes(item?.EstadoImpugnacion?.id) ? 'white--text' : ''"
              style="display: block"
              label
            >
              {{ item?.EstadoImpugnacion?.nombre }}
            </v-chip>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="800px">
      <v-card class="pb-2">
        <embed
          width="100%"
          height="700"
          :src="doc + '#page=1%zoom=100'"
          v-if="doc"
        />
        <v-card-actions>
          <v-btn @click.stop="showDialog = false" color="secondary" outlined>
            Cerrar
          </v-btn>
          <v-btn
            class="ml-auto"
            @click.stop="descargarArchivo()"
            color="info"
            outlined
            dark
          >
            <v-icon>mdi-download</v-icon> Descargar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
  
  <section v-else>
    <h5 class="text-h5 text-center py-4">No se han encontrado recursos de apelación registrados</h5>

    <v-divider class="mb-4" />

    <ResolucionImpugnacionesComponent
      :etapa="etapa"
      :showHeader="false"
      :solo-lectura="soloLectura"
    />
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import ResolucionImpugnacionesComponent from "./ResolucionImpugnacionesComponent.vue";
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: "AdmisionRecursoComponent",
  components: {
    DataTable,
    ResolucionImpugnacionesComponent,
  },
  props: {
    etapa: {
      type: Object,
      default: () => {},
    },
    soloLectura: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Código",
        align: "center",
        value: "correlativo",
        width: "30%",
      },
      {
        text: "Proveedor",
        align: "center",
        value: "proveedor",
        width: "20%",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        width: "20%",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        width: "20%",
        align: "center",
      },
      {
        text: "Acciones",
        align: "center",
        value: "actions",
        width: "20%",
        sortable: false,
      },
    ],
    items: [],
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    showDialog: false,
    doc: null,
    id_impugnacion: null,
    doc_revision_selected: null,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento", "descargarArchivoProceso"]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getRecursosRevision();
    },
    getColor(id_estado) {
      switch (id_estado) {
        case 1:
          return "#8EBCE1";
        case 2:
          return "success";
        case 3:
          return "error";
        case 4:
          return "success";
        case 5:
          return "error";
        case 6:
          return "success";
        case 7:
          return "error";
        default:
          return "grey";
      }
    },
    async getRecursosRevision() {


      const { status, data } =
        await this.services.RecursoRevision.getRecursosRevisionUcp(
          this.$route.params.idProceso,
          {
            id_tipo: 2,
            page: this.pagina,
            per_page: this.por_pagina,
            id_etapa_proceso: this.procesoData.id_etapa_pausa,
          }
        )

      if (status == 200) {
        this.items = data;
      }

    },
    async showDocument(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_impugnacion,
      });
      this.id_impugnacion = item.id;
      this.getDoc(response);
      this.showDialog = true;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },

    async descargarArchivo() {
      this.descargarArchivoProceso({ ruta_documento: this.doc_revision_selected.doc_impugnacion });
    },
  },
  created() {
    this.getRecursosRevision();
  },
};
</script>

<template>
  <section fluid class="mt-8" v-if="winner">
    <v-row>
      <v-col cols="12" md="12">
        <v-btn @click="modal_detalle_subastas = true">Mostrar resultados actuales</v-btn>
      </v-col>
    </v-row>
    </br>
    <ModalSubastasFin 
      :showModal="modal_detalle_subastas" 
      @cerrar="modal_detalle_subastas = false" 
    />
    <v-row no-gutters v-if="subastaSeleccionada != null">
      <v-col cols="12" md="3" class="mb-5">
        <DetalleLote :id_lote="subastaSeleccionada?.id_lote" ref="detalleLote" />
      </v-col>
      <SiguientesSubastas :subastasProximas="subastasProximas" @cierreSala="cierreSala()" />
    </v-row>
    <v-row no-gutters>
      <SeleccionSala :salas="salas" @addSalaSeleccionada="addSalaSeleccionada" :sala_seleccionada="sala_seleccionada"
        :subastaSeleccionada="subastaSeleccionada" @limpiarSubastaActiva="limpiarSubastaActiva" />
      <template v-if="subastaSeleccionada">
        <v-col cols="12" md="8">
          <PanelSubasta 
            :subastaSeleccionada="subastaSeleccionada" 
            @getSalaInfo="getSalaInfo" 
            :oferta="oferta"
            :loadingOferta="loadingOferta" 
            :ofertas="ofertas" 
            :montoInicial="montoInicial"
            :subastaActivaMejorPuja="subastaActivaMejorPuja" 
            :actualGanador="actualGanador"
            :subastaActivaMejorPujaPorcentaje="subastaActivaMejorPujaPorcentaje" 
            :totalSubasta="totalSubasta"
            @cierreSala="cierreSala" 
            @recargarSubasta="recargarSubasta" 
          />
        </v-col>
        <v-col cols="12" md="2" v-if="subastaSeleccionada != null">
          <!-- <div class="d-flex align-center justify-center text-h6">
            <v-chip :color="definirEstado.color" class="white--text">
              <v-icon color="white">{{ definirEstado.icon }}</v-icon>
              <span>{{ definirEstado.text }}</span>
            </v-chip>
          </div>
          <div class="ml-4 d-flex align-center justify-center text-h6">
            <span v-if="definirEstado.btn" @click="$router.go()" color="white" text small class="brake-work">
              Tiene problemas de conexión, recargue la página para volver a conectarse a la subasta en vivo
            </span>
          </div> -->
          </br>
          <HistorialPujasComponent 
            :id="subastaSeleccionada?.id_subasta" 
            ref="historialComponent"
            :porcentual="subastaPorcentual"
            :recarga="true"
          />
        </v-col>
      </template>
      <template v-else-if="salas.length > 0 && (subastasProximas || !subastaSeleccionada)">
        <v-col cols="8">
          <v-alert type="info" class="pulsating-chip">
            <span>Seleccione una sala para ver la subasta en vivo</span>
          </v-alert>
        </v-col>
      </template>
    </v-row>
  </section>
</template>
<script>
import moment from "moment-timezone";
import { mapMutations, mapState } from "vuex";
import socketConfig from "@/plugins/ws";
import AppCountdown from "@/components/AppCountdown.vue";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import HistorialPujasComponent from "./hitoResultadosSubasta/HistorialPujasComponent.vue";
import DetalleLote from "./hitoResultadosSubasta/DetalleLote.vue";
import ModalSubastasFin from "../ModalListadoSubastas.vue";
import SiguientesSubastas from "./realizacionSubasta/siguientesSubastas.vue";
import SeleccionSala from "./realizacionSubasta/seleccionSala.vue";
import PanelSubasta from "./realizacionSubasta/panelRealizacionSubasta.vue";
import { Observable } from "@/utils/observable";

export default {
  components: {
    AppCountdown,
    MoneyTextFieldComponent,
    HistorialPujasComponent,
    DetalleLote,
    ModalSubastasFin,
    SiguientesSubastas,
    SeleccionSala,
    PanelSubasta,
  },
  data: () => ({
    modal_detalle_subastas: false,
    online: true,
    cuentaAtras: false,
    montoPujaPorcentaje: null,
    subastaActivaMejorPuja: 0,
    subastaActivaMejorPujaPorcentaje: 0,
    totalSubasta: 0,
    oferta: null,
    loadingOferta: false,
    salaSeleccionada: null,
    salaAnteriorId: null,
    subastaSeleccionada: null,
    subastasProximas: [],
    cantidadOferta: null,
    montoOferta: null,
    montoInicial: 0,
    disabledBtn: false,
    timeOferta: 0,
    actualGanador: false,
    counting: false,
    list_salas: null,
    showLoteDialog: false,
    loteDetail: [],
    loadingLote: false,
    colores: ["#00B6F5", "#0993cd", "#1270a6", "#1b4d7f", "#252B58"],
    ofertas: [],
    pujando: false,
    winner: false,
    sala_seleccionada: null,
  }),
  computed: {
    // definirEstado() {
    //   let obj = {};
    //   if (this.online) {
    //     obj = {
    //       color: "success",
    //       icon: "mdi-check-circle",
    //       text: "Conectado",
    //     };
    //   } else if (!this.online && this.cuentaAtras) {
    //     obj = {
    //       color: "warning",
    //       icon: "mdi-alert-circle",
    //       text: "Reconectando",
    //     };
    //   } else {
    //     obj = {
    //       color: "error",
    //       icon: "mdi-close-circle",
    //       text: "Desconectado",
    //       btn: true,
    //     };
    //   }
    //   return obj;
    // },

    ...mapState("etapaSubastaInversaStore", ["salas"]),
    ...mapState(["userInfo", "sockets", "serverTime"]),
    subastaPorcentual() {
      return !["resta_monto", "resta_unitaria"].includes(
        this.oferta?.subasta?.CtlSubastum.tipo_operacion
      );
    },
  },
  methods: {
    async limpiarSubastaActiva() {
      this.sala_seleccionada = null;
      this.subastaSeleccionada = null;
      this.montoInicial = null;
      this.subastasProximas = null;
      this.list_salas = null;
      await this.leaveSocketRoom();
      await this.leaveSocketRoom();
    },
    ...mapMutations("etapaSubastaInversaStore", ["setSalas"]),
    ...mapMutations(["setSocket"]),
    opcionesOfertas() {
      let monto = this.subastaSeleccionada?.monto_inicial;
      let salto = this.subastaSeleccionada?.salto_oferta;
      let monto_oferta = [];
      for (let i = 0; i < 5; i++) {
        monto = monto - salto;
        if (monto > 0) {
          monto_oferta.push({
            monto,
            color: this.colores[i],
          });
        }
      }
      this.ofertas = monto_oferta;
    },
    async addSalaSeleccionada(sala, forceUpdate = false) {
      if (
        this.subastaSeleccionada?.id_subasta !==
        sala.subastas_activas?.id_subasta ||
        forceUpdate
      ) {
        await this.getSalaInfo(true);
        await this.leaveSocketRoom();
        this.sala_seleccionada = sala?.id_sala;
        this.subastaSeleccionada = sala.subastas_activas;
        this.montoInicial = this.subastaSeleccionada?.monto_inicial;
        this.subastasProximas = sala.proxima_subasta;
        this.salaAnteriorId = sala?.id_sala;
        await this.joinSocketRoom();
        this.opcionesOfertas();
      }
    },
    async fetchOferta() {
      this.loadingOferta = true;
      const id = this.subastaSeleccionada.id_subasta;
      const { data } = await this.services.Paac.getSubasta(id);
      this.oferta = data;
      if (data && !this.haveRole("ROLE_UACI")) {
        this.montoOferta = data.monto;
        this.actualGanador = data.actual_ganador;
      }
      if (this.haveRole("ROLE_PROVEEDOR")) {
        const response = await this.services.Paac.getGanador(id);
        if (response) this.setActualGanador(response);
        this.montoOferta = data.monto;
      }
      this.loadingOferta = false;
    },
    async recargarHistorialPujas() {
      Observable.emit('RECARGAR_HISTORIAL_PUJAS');
    },
    ...mapMutations(["setServerTime"]),
    setActualGanador(response) {
      this.actualGanador = response.data.actual_ganador;

      const currentDate = moment(this.serverTime, "x");
      const nextOfferTime = moment(
        response.data.proxima_operacion,
        "DD/MM/YYYY HH:mm:ss"
      );
      if (nextOfferTime.isAfter(currentDate)) {
        this.timeOferta = moment(
          response.data.proxima_operacion,
          "DD/MM/YYYY HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm:ss");
        this.timeOferta = moment(
          response.data.proxima_operacion,
          "DD/MM/YYYY HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm:ss");
        this.counting = true;
      } else {
        this.timeOferta = 0;
        this.counting = false;
      }
    },
    async cierreSala(forceUpdate = false) {
      await this.getSalaInfo(forceUpdate);
      if (forceUpdate) {
        await this.limpiarSubastaActiva();
        await this.getSalaInfo(true);
      }
    },
    async eventoCierre({ message }) {
      await this.cierreSala(true);
    },
    async eventoOferta(data) {
      if (
        Number(data.id_subasta) ===
        Number(this.subastaSeleccionada?.id_subasta) ||
        !this.haveRole("ROLE_PROVEEDOR")
      ) {
        this.oferta = data;
        this.subastaSeleccionada = { ...data, ...this.subastaSeleccionada };
        this.subastaSeleccionada.monto_inicial = data.monto;
        this.opcionesOfertas();
        await this.fetchOferta();

        this.actualGanador = Number(data.id_usuario_ganador) === Number(this.userInfo.user.id);

        this.recargarHistorialPujas();
        
        if (this.pujando) {
          this.pushAppMessage({
            show: true,
            type: "info",
            message: `${data?.descripcion}`,
          });
          this.pujando = false;
        }
      }
    },
    async eventosSubasta(data) {
      const tipo = data.type;
      switch (tipo) {
        case "oferta":
          await this.eventoOferta(data);
          break;
        case "cierre_subasta":
          await this.eventoCierre(data);
          break;
        case "cuenta_atrás_desierto":
          await this.eventoDesierto(data);
          break;
        case "aviso_subasta":
          this.recargarSubasta();
          break;
        case "inicio_subasta":
          // Observable.emit('REFRESCAR-HORA-SERVIDOR');
          break;
        default:
          return this.pushAppMessage({
            type: "info",
            message: "Un evento no ha podido ser reconocido",
          });
      }
    },
    async conexionSocket() {
      if (!this.sockets.general) {
        this.setSocket({ socket: "general", connection: socketConfig });
        this.sockets.general.on("connect", () => {
          this.cuentaAtras = false;
          this.joinSocketRoom();
          this.sockets.general.on("EVENTOS_SUBASTA", (data) => {
            this.eventosSubasta(data);
          });

          this.sockets.general.on("disconnect", async () => {
            this.online = false;

            delete this.sockets.general;
            await this.conexionSocket();
            await this.joinSocketRoom();
          });
        });
        this.sockets.general.on("reconnect", async () => {
          this.online = false;
        });
        this.sockets.general.on("error", async () => {
          this.online = false;
          setTimeout(() => {
            this.cuentaAtras = false;
          }, 5000);
          delete this.sockets.general;
          await this.conexionSocket();
        });
        this.sockets.general.nsp = "/subasta";
        this.sockets.general.connect();
      }
      if (this.haveRole("ROLE_PROVEEDOR")) {
        await this.VerifyParticipacion();
      } else {
        this.winner = true;
        await this.getSalaInfo(true);
      }
    },
    async recargarSubasta() {
      await this.fetchOferta();
      this.recargarHistorialPujas();
      this.opcionesOfertas();
    },
    joinSocketRoom() {
      if (this.subastaSeleccionada?.id_subasta) {
        this.sockets.general.emit("SUBASTA_PARTICIPAR", {
          id_subasta: this.subastaSeleccionada?.id_subasta,
        });
      }
    },
    leaveSocketRoom() {
      this.sockets.general.emit("SUBASTA_ABANDONAR", {
        id_subasta: this.subastaSeleccionada?.id_subasta,
      });
    },
    async getSalaInfo(forceUpdate = false) {
      if (this.salas && !forceUpdate) return;
      const { data } = await this.services.PacProcesos.getSalasProceso(
        this.$route.params.idProceso
      );
      this.setSalas(data);
    },
    async VerifyParticipacion() {
      const { data, status } =
        await this.services.PacProcesos.estadoEtapaProceso(
          this.$route.params.idProceso,
          1
        );
      if (status === 200) {
        this.winner = data[0]?.tecnica;
      }
      if (this.winner) await this.getSalaInfo();
    },
  },
  watch: {
    subastaSeleccionada: async function () {
      if (this.subastaSeleccionada?.id_subasta) {
        await this.recargarSubasta();
      }
    },
    montoOferta: function (value) {
      if (value) {
        this.subastaSeleccionada.monto_inicial = value;
        this.opcionesOfertas();
      }
    },
    oferta: function (value) {
      this.subastaActivaMejorPuja = value?.monto || 0;
      this.subastaActivaMejorPujaPorcentaje = value?.porcentaje || 0;
      const porcentaje = (value?.monto * value?.porcentaje ?? 0) / 100;

      switch (this.oferta?.subasta?.CtlSubastum?.tipo_operacion) {
        case "resta_porcentaje":
          this.totalSubasta = value?.monto + porcentaje;
          break;
        case "suma_porcentaje":
          this.totalSubasta = value?.monto - porcentaje;
          break;
        case "resta_porcentaje_publicidad":
          this.totalSubasta = value?.monto;
          break;
        default:
          this.totalSubasta = value?.monto;
          break;
      }
    },
    "sockets.general": async function (value) {
      if (value) {
        await this.conexionSocket();
      }
    },
  },
  async mounted() {
    await this.getSalaInfo(true);
    await this.conexionSocket();
  },
  beforeDestroy() {
    this.leaveSocketRoom();
    this.sockets.general.disconnect();
    this.setSocket({ socket: "general", connection: null });
  },
};
</script>
<style lang="scss" scoped>
.fl-upper::first-letter {
  text-transform: uppercase !important;
}

.w-button-group {
  max-width: max-content;
}

:deep(.v-btn-toggle) {
  flex-direction: column;
}

:deep(.v-btn-toggle--group > .v-btn.v-btn--active) {
  background-color: #404a7a !important;
  color: white !important;
}

.ganadorClass {
  background: #42a5f5;
  color: white !important;
  border-radius: 5px !important;
  padding: 10px;
}

.brake-work {
  word-break: break-word;
}

.pulsating-chip {
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
</style>

<template>
    <Fragment>
        <h6 class="text-h6 font-weight-bold text-uppercase mb-1">Resolución final</h6>
        <v-divider />
        <div v-if="puedeManipularDocumentos && !readonly" class="d-flex align-center flex-gap mt-4" style="gap: 16px;">
            <v-btn 
                @click.stop="modalCrearResolucionFinal = true" 
                :disabled="resoluciones.isLoading"
                :color="mostrarBotonContinuarProceso ? 'secondary' : 'primary'" 
            >
                Subir documento
            </v-btn>
        </div>
        <DataTableComponent 
            :items="resoluciones.data"
            :headers="resolucionesHeaders"
            :show_loading="resoluciones.isLoading"
            :tiene_paginacion="false"
            class="mt-4"
            dense 
            no-gutters
        >
            <template v-slot:item.fecha_carga="{item}">{{ formatDate(item.created_at) }}</template>
            <template v-slot:item.acciones="{item}">
                <div class="d-flex align-center justify-center" style="gap: 16px;">
                    <v-icon @click.stop="visualizarDocumento(item)" color="secondary">
                        mdi-eye
                    </v-icon>
                    <v-icon 
                        v-if="puedeManipularDocumentos && !readonly" 
                        @click.stop="confirmarEliminacionResolucion(item)"
                        color="secondary"
                    >
                        mdi-trash-can
                    </v-icon>
                </div>
            </template>
        </DataTableComponent>

        <v-btn 
            v-if="mostrarBotonContinuarProceso"
            @click.stop="confirmacionContinuarEtapa = true"
            color="primary"
            class="mt-4"
            :disabled="cierreEtapa.isLoading"
        >
            Continuar proceso
        </v-btn>


        <v-dialog v-if="puedeManipularDocumentos" v-model="modalCrearResolucionFinal" max-width="700" persistent>
            <v-card class="px-4 py-4">
                <div>
                    <v-icon @click.stop="cerrarCreacion">mdi-close</v-icon>
                </div>
                <v-form ref="form" class="mt-4" @submit.prevent="validarCreacionResolucion">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.nombre" 
                                label="Nombre de documento" 
                                outlined 
                                @change="$v.form.nombre.$touch()"
                                @blur="$v.form.nombre.$touch()"
                                :error-messages="nombreErrors"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-file-input
                                v-model="form.documento"
                                label="Adjunto"
                                accept="application/pdf"
                                outlined 
                                @change="$v.form.documento.$touch()"
                                :error-messages="documentoErrors"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                                x-large 
                                color="primary" 
                                style="width: 100%;" 
                                type="submit"
                            >
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>

        <ConfirmationModalComponent
            :is-open="confirmacionCreacion"
            :is-loading="guardadoResolucion.isLoading"
            description="¿Desea guardar el documento?"
            @confirm="crearResolucion"
            @cancel="confirmacionCreacion = false"
        />

        <ConfirmationModalComponent
            :is-open="confirmacionEliminado"
            :is-loading="eliminacionResolucion.isLoading"
            description="¿Desea eliminar el documento?"
            @confirm="eliminarResolucion"
            @cancel="confirmacionEliminado = false"
        />

        <ConfirmationModalComponent
            :is-open="confirmacionContinuarEtapa"
            :is-loading="cierreEtapa.isLoading"
            title="¿Desea finalizar el recurso de apelación?"
            description="Esta acción dará fin al recurso de apelación y el proceso de compra regresará a su ejecución"
            @confirm="continuarProcesoApelacion"
            @cancel="confirmacionContinuarEtapa = false"
        />

        <PdfModal
            :isOpen="modalVisualizacionDocumento" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="cerrarModalVisualizacionDocumento"
            :filename="filename"
        />
    </Fragment>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import { Validator } from '@/utils/form-validation.js';
import { required } from 'vuelidate/lib/validators'
import { convertToFormData } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';
import { mapState, mapActions, mapMutations } from 'vuex';
import { Fragment } from 'vue-fragment';
import { Observable } from '@/utils/observable';
import { ValidacionAccesos } from '@/utils/role-utils';

export default {
    name: 'ResolucionFinal',
    components: { Fragment, DataTableComponent, ConfirmationModalComponent, PdfModal },
    validations() {
        return ({
            form: {
                nombre: { required },
                documento: { required },
            },
        });
    },
    props: {
        idProcesoCompra: { type: [Number, String] },
        readonly: { type: Boolean, default: false },
    },
    data() {
        return ({
            resolucionActiva: false,
            // Form
            modalCrearResolucionFinal: false,
            form: {
                nombre: null,
                documento: null,
            },
            confirmacionCreacion: false,
            guardadoResolucion: createLoadable(null),
            resolucionCrear: null,
            // Eliminado
            confirmacionEliminado: false,
            eliminacionResolucion: createLoadable(null),
            // Visualizacion
            modalVisualizacionDocumento: false,
            visualizacionDocumento: createLoadable(null),
            // Continuar etapa
            cierreEtapa: createLoadable(null),
            confirmacionContinuarEtapa: false,
        });
    },
    computed: {
        ...mapState("recursoApelacion", ["resoluciones", "etapaActual"]),
        etapa() {
            return this.etapaActual.data;
        },
        esProveedor() {
            return ValidacionAccesos.ES_PROVEEDOR;
        },
        esRoleTacop() {
            return ValidacionAccesos.ES_TACOP;
        },
        esAlMenosUCP() {
            return ValidacionAccesos.ES_AL_MENOS_UCP;
        },
        puedeManipularDocumentos() {
            return this.esAlMenosUCP;
        },
        filename() {
            return this.resolucionActiva?.nombre_documento ?? 'pronunciamiento';
        },
        resolucionesHeaders() {
            return [
                { text: 'Documento', value: 'nombre_documento', align: 'center', sortable: false },
                { text: 'Fecha de carga', value: 'fecha_carga', align: 'center', sortable: false },
                { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
            ];
        },
        mostrarBotonContinuarProceso() {
            return this.puedeManipularDocumentos && this.resoluciones.data.length > 0 && !this.readonly;
        },
        // Errores
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        documentoErrors() {
            return new Validator(this.$v.form.documento).detect().getResult();
        },
    },
    methods: {
        // UI
        formatDate,
        limpiarFormulario() {
            this.$refs.form.reset();
            this.$v.form.$reset();
        },
        cerrarCreacion() {
            this.modalCrearResolucionFinal = false;
            this.limpiarFormulario();
        },
        confirmarEliminacionResolucion(resolucion) {
            this.resolucionActiva = resolucion;
            this.confirmacionEliminado = true;
        },
        validarCreacionResolucion() {
            this.$v.form.$touch();


            if (this.$v.form.$invalid) return;

            this.resolucionCrear = { ...this.form };
            this.modalCrearResolucionFinal = false;
            this.confirmacionCreacion = true;
            this.limpiarFormulario();
        },
        cerrarModalVisualizacionDocumento(visible) {
            this.modalVisualizacionDocumento = visible;
        },
        // Data
        ...mapActions("recursoApelacion", ["cargarResoluciones", "verificarPIPActivo"]),
        ...mapMutations("recursoApelacion", ["limpiarResoluciones"]),
        async crearResolucion() {
            const formData = convertToFormData(this.resolucionCrear);
            toggleLoadable(this.guardadoResolucion);
            const { data } = await this.services.RecursoApelacion.guardarResolucionFinal(this.idProcesoCompra, formData);
            this.confirmacionCreacion = false;
            setLoadableResponse(this.guardadoResolucion, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            this.cargarResoluciones(this.idProcesoCompra);
        },
        async eliminarResolucion() {
            toggleLoadable(this.eliminacionResolucion);
            const { data } = await this.services.RecursoApelacion.eliminarResolucionFinal(this.idProcesoCompra, this.resolucionActiva.id);
            this.confirmacionEliminado = false;
            setLoadableResponse(this.eliminacionResolucion, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarResoluciones(this.idProcesoCompra);
        },
        async visualizarDocumento(resolucion) {
            this.resolucionActiva = resolucion;
            this.modalVisualizacionDocumento = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.RecursoApelacion.visualizarDocumentoEtapaResolucion(resolucion.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        async continuarProcesoApelacion() {
            toggleLoadable(this.cierreEtapa);
            const { data } = await this.services.RecursoApelacion.cerrarEtapa(this.idProcesoCompra, this.etapa.id);
            this.confirmacionContinuarEtapa = false;
            setLoadableResponse(this.cierreEtapa, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) Observable.emit('recargar-proceso-actual');
        },
    },
    created() {
        this.cargarResoluciones(this.idProcesoCompra);
    },
    beforeDestroy() {
        this.limpiarResoluciones();
    },
}
</script>
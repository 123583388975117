<template>
    <Fragment>
        <v-row v-if="!soloLectura" class="mb-4">
            <v-col cols="12" md="4">
                <v-text-field 
                    v-model="nombreResolucionFinal" 
                    label="Nombre archivo" 
                    outlined 
                    hide-details
                />
            </v-col>

            <v-col cols="12" md="4">
                <v-file-input 
                    v-model="resolucionFinalFile" 
                    prepend-icon="" 
                    prepend-inner-icon="mdi-paperclip"
                    accept="application/pdf" 
                    label="Archivo adjunto" 
                    truncate-length="15" 
                    outlined 
                    hide-details
                />
            </v-col>

            <v-col cols="12" md="4">
              <v-btn 
                    :disabled="!(nombreResolucionFinal && resolucionFinalFile)" 
                    x-large
                    color="primary"
                    class="flex-shrink-1 flex-grow-1"
                    style="width: 100%;"
                    @click="confirmacionResolucionFinal = true"
                >
                    Agregar
                </v-btn>
            </v-col>
        </v-row>

        <DataTable 
            :show_loading="resoluciones.isLoading"
            :items="resoluciones.data"
            :headers="headers"
            :tiene_paginacion="false"
            dense
            no-gutters
        >
            <template v-slot:item.fecha_carga="{ item }">{{ formatDate(item.created_at) }}</template>
            <template v-slot:item.acciones="{ item }">
            <div class="d-flex align-center justify-center" style="gap: 16px;">
                <v-icon @click.stop="visualizarDocumento(item)">mdi-eye</v-icon>
                <v-icon v-if="!soloLectura" @click.stop="confirmarEliminacionResolucionFinal(item)">mdi-trash-can</v-icon>
            </div>
            </template>
        </DataTable>

        <!-- Confirmar resolucion final -->
        <ConfirmationModalComponent 
            :isOpen="confirmacionResolucionFinal" 
            description="¿Desea cargar la resolución final de la máxima autoridad?"
            @confirm="cargarResolucionFinal" 
            @cancel="confirmacionResolucionFinal = false"
            :isLoading="cargaResolucionFinal.isLoading"
        />

        <!-- Confirmar eliminacion resolucion -->
        <ConfirmationModalComponent
            :is-open="confirmacionEliminacionResolucion"
            :is-loading="eliminacionResolucion.isLoading"
            description="¿Desea eliminar el documento?"
            @confirm="eliminarResolucionFinal"
            @cancel="confirmacionEliminacionResolucion = false"
        />

        <!-- Visualizacion de documentos -->
        <PdfModal
            :isOpen="modalVisualizacionDocumento" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="cerrarModalVisualizacionDocumento"
            :filename="filename"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from "@/utils/loadable";
import { ConfirmationModalComponent } from "@/components/utils";
import DataTable from "@/components/DataTableComponent.vue";
import PdfModal from '@/components/PdfModal.vue';
import { convertToFormData } from "@/utils/data";
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';
import { mapState } from 'vuex';

export default {
    name: 'ResolucionesFinalesMaximaAutoridad',
    components: { Fragment, ConfirmationModalComponent, DataTable, PdfModal },
    emits: ['on-recargar-resoluciones'],
    props: {
        idProcesoCompra: { type: [Number, String] },
        resoluciones: { type: Object },
        soloLectura: { type: Boolean },
    },
    data: () => ({
        resolucionActiva: null,
        resolucionFinalFile: null,
        nombreResolucionFinal: null,
        // Cargar nueva resolucion final 
        confirmacionResolucionFinal: false,
        cargaResolucionFinal: createLoadable(null),
        // Eliminar resolucion final
        confirmacionEliminacionResolucion: false,
        eliminacionResolucion: createLoadable(null),
        // Visualizacion de documentos
        modalVisualizacionDocumento: false,
        visualizacionDocumento: createLoadable(null),
        // Tabla
        headers: [
            { text: "Nombre", value: "nombre_documento", align: "center", sortable: false, width: "30%" },
            { text: "Fecha de carga", value: "fecha_carga", align: "center", sortable: false, width: "30%" },
            { text: "Acciones", value: "acciones", align: "center", sortable: false, width: "30%" },
        ],
    }),
    computed: {
        ...mapState("procesoCompraDoc", ["procesoData"]),
        filename() {
            return this.resolucionActiva?.nombre_documento ?? 'resolucion';
        },
    },
    methods: {
        formatDate,
        async cargarResolucionFinal() {
            toggleLoadable(this.cargaResolucionFinal);

            const params = {
                documento: this.resolucionFinalFile,
                nombre: this.nombreResolucionFinal,
                id_proceso_compra: this.idProcesoCompra,
                id_tipo_documento: 15003,
                id_etapa_proceso: this.procesoData.id_etapa_pausa,
            };
            
            const formData = convertToFormData(params);
            const { data } = await this.services.RecursoRevision.cargarResolucionFinal(this.idProcesoCompra, formData);
            this.confirmacionResolucionFinal = false;
            setLoadableResponse(this.cargaResolucionFinal, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            this.resolucionFinalFile = null;
            this.nombreResolucionFinal = null;
            this.$emit('on-recargar-resoluciones');
        },
        // Eliminacion resolucion final
        confirmarEliminacionResolucionFinal(resolucion) {
            this.resolucionActiva = resolucion;
            this.confirmacionEliminacionResolucion = true;
        },
        async eliminarResolucionFinal() {
            toggleLoadable(this.eliminacionResolucion);
            const { data } = await this.services.RecursoRevision.eliminarResolucion(this.idProcesoCompra, this.resolucionActiva.id);
            this.confirmacionEliminacionResolucion = false;
            setLoadableResponse(this.eliminacionResolucion, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            this.$emit('on-recargar-resoluciones');
        },
        // Visualizacion documentos
        async visualizarDocumento(resolucion) {
            this.resolucionActiva = resolucion;
            this.modalVisualizacionDocumento = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.RecursoApelacion.visualizarDocumentoEtapaResolucion(resolucion.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        cerrarModalVisualizacionDocumento(visible) {
            this.modalVisualizacionDocumento = visible;
        },
    },
}
</script>
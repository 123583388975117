<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="800px" persistent>
            <v-card class="pb-2">
                <div v-if="loading" class="py-4 text-center">
                    <v-progress-circular indeterminate :size="60" />
                </div>
    
                <embed
                    v-if="source"
                    width="100%"
                    height="700"
                    :src="source + '#page=1%zoom=100'"
                />
                <v-card-actions v-if="!loading">
                    <v-btn @click.stop="cerrarModal" color="secondary" outlined :loading="loading">
                        Cerrar
                    </v-btn>
                    <template v-if="!soloLectura && puedeAtenderApelacion && this.apelacion?.EstadoImpugnacion.id === 1">
                        <v-btn
                          @click.stop="abrirModalCargaActa(7)"
                          color="red"
                          outlined
                        >
                          Rechazar
                        </v-btn>
                        <v-btn
                          @click.stop="abrirModalCargaActa(6)"
                          color="success"
                          dark
                        >
                          Admitir recurso 
                        </v-btn>
                    </template>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modalCargaActaAbierta" max-width="500" persistent>
            <v-card class="pb-2">
                <v-card-text class="py-4">
                    <h6 class="text-h6 secondary--text mb-4">
                        {{ estado === 6 ? "Admitir" : "Rechazar" }} recurso de apelación
                    </h6>
                    
                    <v-file-input
                        v-model="acta_recurso_apelacion"
                        :label="`Acta de ${estado === 6 ? 'admisión' : 'rechazo'} de recurso`"
                        outlined
                        accept="application/pdf"
                        prepend-icon=""
                        prepend-inner-icon="mdi-paperclip"
                        clearable
                        @change="$v.acta_recurso_apelacion.$touch()"
                        :disabled="cambioEstadoApelacion.isLoading"
                        :error-messages="acta_recurso_apelacionErrors"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-btn 
                        @click.stop="modalCargaActaAbierta = false" 
                        color="secondary"
                        :disabled="cambioEstadoApelacion.isLoading"
                        outlined
                    >
                      Cerrar
                    </v-btn>
                    <v-btn
                        @click.stop="atenderApelacion"
                        :color="estado === 6 ? 'secondary' : 'red'"
                        :disabled="!acta_recurso_apelacion"
                        :loading="cambioEstadoApelacion.isLoading"
                        solid
                    >
                      {{ estado === 6 ? "Admitir" : "Rechazar" }} recurso
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </Fragment>
</template>
<script>
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";
import { Fragment } from 'vue-fragment';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from "@/utils/loadable";

const fileTypeValid = (value) => {
  if (!value) return true;

  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

const fileNameValid = (value) => {
  if (!value) return true;
  
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
    name: 'ModalAprobacionRecursoApelacion',
    emits: ['on-cambio-estado'],
    validations: {
        acta_recurso_apelacion: {
            required,
            fileTypeValid,
            fileNameValid,
            fileSizeValid: LegacyValidations.maxFileSizeRule(),
        },
    },
    props: {
        isOpen: { type: Boolean },
        apelacion: { type: Object },
        loading: { type: Boolean },
        source: { type: String },
        soloLectura: { type: Boolean },
        idProceso: { type: [String, Number] },
    },
    data: () => ({
        modalCargaActaAbierta: false,
        estado: null,
        acta_recurso_apelacion: null,
        cambioEstadoApelacion: createLoadable(null),
    }),
    computed: {
        puedeAtenderApelacion() {
            // return this.haveRoles(['ROLE_TACOP']);
            return !this.haveRoles(['ROLE_PROVEEDOR']);
        },
        acta_recurso_apelacionErrors() {
            const errors = [];
            if (!this.$v.acta_recurso_apelacion.$dirty) return errors;

            !this.$v.acta_recurso_apelacion.required && errors.push("El archivo es requerido");
            !this.$v.acta_recurso_apelacion.fileTypeValid && errors.push("El archivo debe ser PDF");
            !this.$v.acta_recurso_apelacion.fileNameValid && errors.push("El nombre del archivo debe ser sin caracteres especiales");
            !this.$v.acta_recurso_apelacion.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);         
            
            return errors;
        },
    },
    methods: {
        limpiarCampos() {
            this.estado = null;
            this.acta_recurso_apelacion = null;
            this.$v.acta_recurso_apelacion.$reset();
        },
        cerrarModal() {
            this.limpiarCampos();
            this.modalCargaActaAbierta = false;
            this.$emit('update:is-open', false);
        },
        abrirModalCargaActa(estado) {
            if (this.estado !== estado) this.acta_recurso_apelacion = null;

            this.estado = estado;
            this.modalCargaActaAbierta = true;
        },
        async atenderApelacion() {
            if (this.$v.acta_recurso_apelacion.$invalid) return;
            
            const formData = new FormData();
            formData.append("doc_resolucion", this.acta_recurso_apelacion);
            formData.append("id_estado_apelacion", this.estado);

            toggleLoadable(this.cambioEstadoApelacion);
            const { data } = await this.services.RecursoApelacion.atenderApelacion(this.idProceso, this.apelacion.id, formData);
            setLoadableResponse(this.cambioEstadoApelacion, data, { skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                const message = `Recurso de apelación ${this.newEstado === 6 ? 'admitido' : 'rechazado'} con éxito.`;
                this.pushAppMessage({ type: 'success', message });
                this.$emit('on-cambio-estado');
                this.cerrarModal();
            }
        },
    }
}
</script>
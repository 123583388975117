<template>
  <v-container fluid class="mb-8">  
    <template v-if="cargandoEtapas">
      <v-skeleton-loader type="button" />
      <v-skeleton-loader type="paragraph" class="mt-2" />
    </template>
    <template v-else>
      <!-- Vista inicial para proveedores antes de registrarse como participante -->
      <div v-if="mostrarIndicadorInscripcionEtapa">
        <h1 class="secondary--text text-center mt-8">
          Debe inscribirse en el proceso para poder observar la etapa actual
        </h1>
      </div>

      <div v-if="cargandoEtapas" class="d-flex align-center flex-wrap" style="gap: 16px;">
        <v-progress-circular
          indeterminate 
          color="primary" 
          :size="25" 
          :width="3" 
        />
        <span class="text-uppercase font-weight-bold text-subtitle-1">Cargando etapas...</span>
      </div>

      <!-- Vista para todo tipo de usuario excepto proveedor cuando no hay etapa activa -->
      <template v-if="!mostrarIndicadorInscripcionEtapa">
        <div v-if="etapas.length === 0" class="d-flex flex-column justify-center mt-4" style="gap: 16px;">
          <v-icon style="font-size: 60px !important;" class="secondary--text">mdi-clock</v-icon>
          <h5 class="text-h5 text-center font-weight-bold secondary--text">
            No se ha encontrado etapa activa
          </h5>
        </div>
        <template v-else>
          <div 
            v-if="!participando && !haveRole('ROLE_PROVEEDOR') && etapas.length === 0 && procesoData?.id_seguimiento_proceso !== 11"
            class="d-flex flex-column justify-center mt-4" 
            style="gap: 16px;"
          >
            <v-icon style="font-size: 60px !important;" class="secondary--text">mdi-cancel</v-icon>
            <h5 class="text-h5 text-center font-weight-bold secondary--text">
              No tiene acceso a esta etapa
            </h5>
          </div>
        </template>
      </template>

      <!-- Recepción de solicitudes de compra -->
      <template v-if="procesoData?.id_seguimiento_proceso == 11">
        <h3 class="mt-8 text-center">
          Realice las solicitudes de compra correspondientes en este período.
        </h3>
      </template>

      <!--Seguimiento de etapas según estado -->
      <template v-if="
        (procesoData?.id_seguimiento_proceso !== 11 &&
          haveRole('ROLE_PROVEEDOR') &&
          participando) ||
        (procesoData?.id_seguimiento_proceso === 3 &&
          !haveRole('ROLE_PROVEEDOR')) ||
        (procesoData?.id_seguimiento_proceso === 12 &&
          !haveRole('ROLE_PROVEEDOR'))
      ">
        <div v-for="etapa in etapas" :key="etapa.id">
          <v-row class="display-flex align-center mb-4 mt-8">
            <v-col cols="12" sm="6">
              <v-row align="center">
                <h5 class="text-h5 secondary--text">{{ etapa?.nombre }}</h5>

                <v-menu
                  v-if="
                    haveRoles([
                      'ROLE_UACI',
                      'ROLE_UACI_TECNICO',
                      'ROLE_DIRECCION_COMPRAS',
                      'ROLE_TECNICO_DIRECCION_COMPRAS',
                    ]) && 
                    procesoData?.id_seguimiento_proceso !== 11 &&
                    !([19].includes(etapa?.id_tipo_etapa) && haveRole('ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION'))
                  "
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mx-4" v-bind="attrs" v-on="on">
                      <v-icon color="secondary">mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-btn
                        class="secondary--text text-no-style"
                        text
                        @click="cerrarEtapa(etapa.id)"
                      >
                        Cerrar etapa
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3">
              <p class="h6-text secondary--text mb-0 mt-0">
                Fecha de Inicio: <br />
                {{
                  moment(etapa?.fecha_hora_inicio).format("DD-MM-YYYY HH:mm A")
                }}
              </p>
            </v-col>
            <v-col cols="12" sm="3">
              <p class="h6-text secondary--text mb-0 mt-0">
                Fecha de Fin: <br />
                {{ moment(etapa?.fecha_hora_fin).format("DD-MM-YYYY HH:mm A") }}
              </p>
            </v-col>
          </v-row>

          <!-- Indicaciones generales de etapa -->
          <v-row v-if="etapa?.FormaContratacionEtapa?.indicaciones?.length > 0">
            <v-col>
              <b>Indicaciones de hito:</b>
              {{ etapa?.FormaContratacionEtapa?.indicaciones }}
            </v-col>
          </v-row>

          <!-- Etapas de seguimiento -->
          <v-row v-if="[1, 3, 17, 18, 21].includes(etapa?.id_tipo_etapa)">
            <v-col>
              <RichTextComponent
                :etapa="etapa"
                class="mb-8"
                :permisos="permisosEtapaUsuario(etapa)"
                v-if="[2].includes(etapa?.id_tipo_configuracion)"
              />
              <AdminDocumentosComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
                v-if="[1, 3, 17, 18, 21].includes(etapa?.id_tipo_configuracion)"
              />
            </v-col>
          </v-row>

          <!-- adjudicación -->
          <v-row v-if="[6].includes(etapa?.id_tipo_etapa)">
            <v-col
              cols="12"
              v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_COOP', 'ROLE_FRONT_LECTURA_CALLCENTER'])"
            >
              <AdjudicacionComponent
                class="my-8"
                :proceso="procesoData"
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <!-- Recepción de ofertas PROVEEDOR -->
          <v-row
            v-if="
              [4, 10, 11].includes(etapa?.id_tipo_etapa) &&
              haveRoles(['ROLE_PROVEEDOR', 'ROLE_COOP','ROLE_FRONT_LECTURA_CALLCENTER'])
            "
          >
            <v-col>
              <RecepcionDeOfertasComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <!-- Apertura de ofertas UCP -->
          <v-row
            v-if="
              [5, 20].includes(etapa?.id_tipo_etapa) &&
              !haveRole('ROLE_PROVEEDOR') &&
              !etapa?.nombre.includes('Evaluación')
            "
            class="mb-8"
          >
            <v-col>
              <AperturaOfertasNotificacionComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <!-- Recepción de ofertas UCP -->
          <v-row
            v-if="
              [4, 10, 11, 20].includes(etapa?.id_tipo_etapa) &&
              haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])
            "
          >
            <v-col>
              <h5 class="secondary--text text-h5 mb-8">Ofertas recibidas</h5>
              <OfertasEconomicasNotificacionComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
              <v-divider class="my-4 mb-8" />
              <RecepcionDeOfertasComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <!-- Recepción de ofertas UCP/PROVEEDOR -->
          <v-row v-if="[5, 12, 13].includes(etapa?.id_tipo_etapa)">
            <v-col>
              <h5
                class="secondary--text text-h5 mb-8"
                v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])"
              >
                Ofertas recibidas
              </h5>
              <OfertasEconomicasNotificacionComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
              <v-divider class="my-4 mb-8" />
              <RecepcionDeOfertasComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <!-- Asignación DE CDF -->
          <v-row
            v-if="
              [6].includes(etapa?.id_tipo_etapa) &&
              haveRoles([
                'ROLE_UFI',
                'ROLE_UFI_TECNICO',
                'ROLE_UACI',
                'ROLE_UACI_TECNICO',
              ])
            "
          >
            <v-col cols="12" v-if="haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])">
              <AsignacionCdfComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>
          <v-row v-if="[7].includes(etapa?.id_tipo_etapa)">
            <v-col>
              <RecepcionConsultasComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <v-row v-if="[8].includes(etapa?.id_tipo_etapa)">
            <v-col>
              <!-- <CatalogoSubastaComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              /> -->

              <CatalogoSubastaComponentRefactor
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>
          <v-row v-if="[9].includes(etapa?.id_tipo_etapa)">
            <v-col>
              <SubastaInversaComponent />
            </v-col>
          </v-row>

          <v-row v-if="etapa.id_tipo_etapa === 22">
            <v-col>
              <PublicacionResultadoSubasta :permisos="permisosEtapaUsuario(etapa)" :id_proceso="Number(id_proceso)" />
            </v-col>
          </v-row>

          <v-row v-if="[23, 25].includes(etapa.id_tipo_etapa)">
            <v-col>
              <v-divider class="my-4"></v-divider>
              <AdminDocumentosComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <v-row v-if="[24].includes(etapa.id_tipo_etapa)">
            <v-col>
              <v-divider class="my-4"></v-divider>
              <SolicitudCarritoCE :id_proceso="procesoData.id" />
            </v-col>
          </v-row>

          <v-row
            v-if="
              [14, 15, 16].includes(etapa?.id_tipo_etapa) &&
              participando &&
              haveRole('ROLE_PROVEEDOR')
            "
          >
            <v-col>
              <NotificacionResultadosComponent
                :etapa="etapa"
                :etapas="etapas"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <v-row
            v-if="
              [14, 15, 16].includes(etapa?.id_tipo_etapa) &&
              haveRoles([
                'ROLE_UACI',
                'ROLE_UACI_TECNICO',
                'ROLE_DIRECCION_COMPRAS',
                'ROLE_FRONT_LECTURA_CALLCENTER'])
            "
          >
            <v-col>
              <AdminDocumentosComponent
                :etapa="etapa"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <v-row v-if="
              [19].includes(etapa?.id_tipo_etapa)
          ">
            <v-col>
              <RecursosRevisionComponent
                  :etapa="etapa"
                  :etapas="etapas"
              />
            </v-col>
          </v-row>

          <!-- Notificación de Resolución de Revisión -->
          <v-row v-if="[26].includes(etapa?.id_tipo_etapa)">
            <v-col>
              <NotificacionResolucionResultados :id-proceso="procesoData.id" :etapa="etapa" />
            </v-col>
          </v-row>

          <!-- Presentación de Recurso de Apelación -->
          <v-row v-if="[27].includes(etapa?.id_tipo_etapa)">
            <v-col>
              <PresentacionRecursoApelacion :id-proceso="procesoData.id" />
            </v-col>
          </v-row>

          <v-row v-if="[2].includes(etapa?.id_tipo_etapa)">
            <v-col>
              <ContratacionComponent
                :etapa="etapa"
                class="mb-8"
                :proceso="procesoData"
                :permisos="permisosEtapaUsuario(etapa)"
              />
            </v-col>
          </v-row>

          <v-divider v-if="etapas.length > 1" class="my-4"></v-divider>
        </div>
      </template>
      
      <template v-if="([81].includes(procesoData?.id_seguimiento_proceso) && haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_FRONT_LECTURA_CALLCENTER']))">
        <v-alert type="info" class="my-4" dense>
          El contrato se encuentra liquidado
        </v-alert>
      </template>

      <template v-if="
        ([5, 80].includes(procesoData?.id_seguimiento_proceso) &&
          haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_FRONT_LECTURA_CALLCENTER']))
      ">
        <div v-for="etapa in etapas" :key="etapa.id">
          <v-col cols="12" sm="6">
            <v-row align="center">
              <h5 class="text-h5 secondary--text">{{ etapa?.nombre }}</h5>
            </v-row>
          </v-col>
          <v-row v-if="[2].includes(etapa?.id_tipo_etapa)" class="mt-2">
            <v-col>
              <ContratacionComponent :etapa="etapa" class="mb-8" :proceso="procesoData"
                :permisos="permisosEtapaUsuario(etapa)" />
            </v-col>
          </v-row>
        </div>
      </template>
    </template>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import AdminDocumentosComponent from "./Etapas/AdminDocumentosComponent.vue";
import RichTextComponent from "./Etapas/RichTextComponent.vue";
import ContratacionComponent from "./Etapas/ContratacionComponent.vue";
import AsignacionCdfComponent from "./Etapas/AsignacionCdfComponent.vue";
import RecepcionDeOfertasComponent from "./Etapas/RecepcionDeOfertasComponent.vue";
import CatalogoSubastaComponent from "./Etapas/CatalogoSubastaComponent.vue";
import CatalogoSubastaComponentRefactor from "./Etapas/CatalogoSubastaComponentRefactor.vue";
import SubastaInversaComponent from "./Etapas/SubastaInversaComponent.vue";
import RecepcionConsultasComponent from "./Etapas/RecepcionConsultasComponent.vue";
import AdjudicacionComponent from "./Etapas/AdjudicacionComponent.vue";
import NotificacionResultadosComponent from "./Etapas/NotificacionResultadosComponent.vue";
import RecursosRevisionComponent from "./Etapas/RecursosRevisionComponent.vue";
import AperturaOfertasNotificacionComponent from "./Etapas/AperturaOfertasNotificacionComponent.vue";
import PublicacionResultadoSubasta from "./Etapas/PublicacionResultadoSubasta.vue";
import SolicitudCarritoCE from "./Etapas/SolicitudCarritoCE.vue";
import OfertasEconomicasNotificacionComponent from "./Etapas/OfertasEconomicasNotificacionComponent.vue";
import NotificacionResolucionResultados from './Etapas/hitoRecursoApelacion/NotificacionResolucionRevision.vue';
import PresentacionRecursoApelacion from './Etapas/hitoRecursoApelacion/PresentacionRecursoApelacion.vue';
import { Observable } from '@/utils/observable.js';
import socketConfig from "@/plugins/ws";

export default {
  name: "EtapaActualComponent",
  components: {
    AdminDocumentosComponent,
    RichTextComponent,
    ContratacionComponent,
    AsignacionCdfComponent,
    RecepcionDeOfertasComponent,
    CatalogoSubastaComponent,
    CatalogoSubastaComponentRefactor,
    SubastaInversaComponent,
    RecepcionConsultasComponent,
    AdjudicacionComponent,
    NotificacionResultadosComponent,
    RecursosRevisionComponent,
    AperturaOfertasNotificacionComponent,
    PublicacionResultadoSubasta,
    SolicitudCarritoCE,
    OfertasEconomicasNotificacionComponent,
    NotificacionResolucionResultados,
    PresentacionRecursoApelacion,
  },
  data: () => ({
    cargandoEtapas: false,
    etapas: [],
    etapa: null,
    estadoEtapa: null,
    idTipoProceso: null,
    id_proceso: null,
  }),
  emits: ['on-cierre-etapa'],
  computed: {
    ...mapState(["selectedUnidad"]),
    ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
    ...mapState(["userInfo", "sockets"]),
    mostrarIndicadorInscripcionEtapa() {
      return !this.participando && !this.haveRole('ROLE_SUPER_ADMIN') && this.haveRole('ROLE_PROVEEDOR');
    },
  },
  methods: {
    ...mapMutations(["setSocket"]),
    async getEtapasActuales() {
      try {
        this.cargandoEtapas = true;
        const { status, data } = await this.services.PacProcesos.getProcesoEtapasActivas(this.$route.params.idProceso);
  
        if (status !== 200) {
          this.etapas = [];
          return;
        };
  
        if (data.length > 0) {
          this.etapas = data;
          return;
        } 
        
        if ([5, 6, 8].includes(this.procesoData?.id_seguimiento_proceso)) {
          const { status, data } = await this.services.PacProcesos.getEtapaSolicitudes(this.$route.params.idProceso, 2);
  
          this.etapas = status === 200 ? data : [];
          return;
        }
  
        this.etapas = [];
      } finally {
        this.cargandoEtapas = false;
      }
    },
    async getEtapaSolicitudes() {
      const {status, data} = await this.services.PacProcesos.getEtapaSolicitudes(this.$route.params.idProceso, 3);

      if (status == 200) {
        this.etapa = data[0];
      }
    },
    permisosEtapaUsuario(etapa) {
      const permisosEtapa =
        etapa?.FormaContratacionEtapa?.ParticipantesEtapaProcesos;
      const perfiles = this.selectedUnidad?.cargo?.map((element) =>
        Number(element.id)
      ) || [2];
      const permisos = permisosEtapa?.filter((element) => {
        if (perfiles?.includes(element.id_perfil)) return element;
      });

      if (this.haveRole("ROLE_SUPERVISOR") && this.$route.params.supervisor) {
        return {
          escribir: false,
          leer: this.getPermisoLecturaSupervisor(etapa),
        };
      } else {
        return typeof permisos == "object" ? permisos[0] ?? {} : {};
      }
    },
    getPermisoLecturaSupervisor(etapa) {
      if (![2, 4, 5, 6].includes(etapa.id_tipo_etapa)) {
        return true;
      } else {
        const procesoPerteneceAInstitucionUsuario =
          this.procesoData.id_institucion ===
          this.selectedUnidad.instituciones.id;
        const usuarioPerteneceAUCP = this.haveRoles([
          "ROLE_UACI",
          "ROLE_UACI_TECNICO",
        ]);

        return procesoPerteneceAInstitucionUsuario && usuarioPerteneceAUCP;
      }
    },
    async cerrarEtapa(id_etapa) {
      const { status } = await this.services.PacProcesos.closeEtapaProceso(
          this.$route.params.idProceso,
          id_etapa
      );

      if (status === 200) {
        this.temporalAlert({
          show: true,
          message: `Etapa cerrada con éxito`,
          type: "success",
        });

        this.$emit('on-cierre-etapa');
        Observable.emit('cierre-etapa-pip-general');
        Observable.emit('recargar-proceso-actual');
        // this.getEtapasActuales();
      }
    },
    conexionSocket() {
      if (!this.sockets.procesos) {
        this.setSocket({ socket: "procesos", connection: socketConfig });
        this.sockets.procesos.on("connect", () => {
          this.joinSocketRoom();
          this.sockets.procesos.on("ACTUALIZACION_ETAPA", async (data) => {
            await this.actualizarEtapas();
          });
          this.sockets.procesos.on("error", (data) => {
            console.log(data);
          });
        });
        this.sockets.procesos.nsp = `/proceso`;
        this.sockets.procesos.connect();
      }
    },
    joinSocketRoom() {
      this.sockets.procesos.emit("PROCESO_COMPRA", {
        id_proceso_compra: this.$route.params.idProceso,
      });
    },
    async actualizarEtapas() {
      if (
        this.procesoData?.id_seguimiento_proceso == 11 &&
        !this.haveRole("ROLE_PROVEEDOR")
      ) {
        this.getEtapaSolicitudes();
      } else {
        await this.getEtapasActuales();
      }
    },
  },
  watch: {
    procesoData: {
      handler(value) {
        if (value) {
          this.actualizarEtapas();
        }
      },
      deep: true,
      immediate: true,
    },
    "sockets.procesos": async function (value) {
      if (value) this.joinSocketRoom();
    },
  },
  mounted() {
    this.id_proceso = this.$route.params.idProceso;
    this.conexionSocket();
  },
};
</script>

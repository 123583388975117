<template>
  <section>
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <p class="text-h5 secondary--text font-weight-bold">
          {{ etapa?.CtlEtapasProcesoImpugnacion?.nombre_etapa }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center">
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Inicio:
              {{ moment(etapa?.fecha_inicio).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="secondary--text">
              Fecha de Fin:
              {{ moment(etapa?.fecha_fin).format("DD/MM/YYYY HH:mm") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DataTable 
          :headers="headers" 
          :items="items" 
          :tiene_paginacion="false"
          :show_loading="cargandoRecursosRevision"
          dense 
          no-gutters
        >
          <template v-slot:[`item.proveedor`]="{ item }">
            {{ item?.Usuario?.Proveedor?.nombre_comercial }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip
              :color="getColor(item.EstadoImpugnacion?.id)"
              style="display: block"
              class="white--text"
              label
            >
              {{ item?.EstadoImpugnacion?.nombre }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-center justify-center flex-wrap" style="gap: 8px;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    v-on="on"
                    v-bind="attrs"
                    @click="showDocument(item), (doc_impugnacion_selected = item)"
                    icon
                  >
                    <v-icon color="secondary">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver recurso</span>
              </v-tooltip>
              <!-- <v-tooltip v-if="item.EstadoImpugnacion?.id === 1 && !esProveedor && !item.prevencion" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="abrirModalCreacionPrevencion(item)"
                    icon
                  >
                    <v-icon color="secondary">mdi-folder-plus</v-icon>
                  </v-btn>
                </template>
                <span>Crear prevención</span>
              </v-tooltip> -->
              
              <v-tooltip v-if="item.prevencion" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click.stop="visualizarPrevencion(item)"
                    icon
                  >
                    <v-icon color="secondary">mdi-folder-eye</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar prevención</span>
              </v-tooltip>

              <v-tooltip v-if="item.prevencion?.subsanacion" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" @click.stop="visualizarSubsanacion(item)" icon>
                        <v-icon color="secondary">mdi-folder-wrench</v-icon>
                    </v-btn>
                </template>
                <span>Visualizar subsanación</span>
              </v-tooltip>

              <v-tooltip v-if="item.prevencion" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" @click.stop="abrirModalDetallePrevencion(item)" icon>
                    <v-icon color="secondary">mdi-folder-text</v-icon>
                  </v-btn>
                </template>
                <span>Detalles de prevención</span>
              </v-tooltip>
            </div>
          </template>
        </DataTable>
        <!-- Continuar con el proceso si todos las impugnaciones han sido rechazadas -->
        <ContinuarProceso
          v-if="todasLasImpugnacionesHanSidoRechazadas"
          class="mt-8"
          :id-proceso="idProceso"
          :solo-lectura="haveRoles('ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION')"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="1000px" persistent>
      <v-card style="position: relative; z-index: 10;">
        <a 
          v-if="doc"  
          class="d-flex align-center justify-center px-2 py-2 rounded-pill"
          style="width: 36px; height: 36px; position: sticky; float: right; z-index: 20; top: 16px; right: 16px; background-color: var(--v-primary-base);"
          target="_blank" 
          :href="doc" 
          download="recurso-revision"
        >
          <v-icon color="#FFFFFF !important" small>mdi mdi-download</v-icon>
        </a>

        <vue-pdf-embed v-if="doc" style="max-width: 100%; clear: both;" :source="doc" />

        <v-card-actions style="position: sticky; bottom: 0; z-index: 20; background-color: white;" class="d-flex flex-wrap px-4 py-4">
          <template v-if="this.estado_impugnacion_selected?.id === 1">
            <v-btn @click.stop="showDialog = false" color="secondary" outlined>
              Cerrar
            </v-btn>

            <template v-if="!soloLectura && esAlMenosUCP">
              <v-btn
                @click.stop="(showDialogadmisionRecurso = true), (newEstado = 3)"
                color="red"
                outlined
              >
                Rechazar
              </v-btn>
              <v-btn
                @click.stop="(showDialogadmisionRecurso = true), (newEstado = 2)"
                color="success"
                :outlined="mostrarCreacionPrevencion"
                :dark="!mostrarCreacionPrevencion"
              >
                Admitir recurso 
              </v-btn>
              <v-btn 
                v-if="mostrarCreacionPrevencion"
                @click="abrirModalCreacionPrevencion"
                color="primary"
              >
                Crear prevención
              </v-btn>
            </template>
          </template>

          <div class="px-4" v-else>
            <v-btn @click.stop="showDialog = false" color="secondary" outlined>
              Cerrar
            </v-btn>
            <v-chip 
              label 
              class="ml-4 white--text"
              :color="this.estado_impugnacion_selected?.id === 2 ? 'green' : 'red'"
            >
              {{ this.estado_impugnacion_selected?.nombre }}
            </v-chip>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogadmisionRecurso" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <h6 class="text-subtitle-1 font-weight-bold text-uppercase secondary--text">
            {{ newEstado === 2 ? "Admitir" : "Rechazar" }} recurso de revisión
          </h6>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4">
          <p v-if="newEstado === 2" class="text-body-2 mb-0">
            Para realizar esta acción debe cargar la resolución de admisión firmada 
            por la autoridad.
          </p>
          <p v-if="newEstado === 3" class="text-body-2 mb-0">
            Para realizar esta acción debe cargar la resolución de rechazo firmada 
            por la autoridad.
          </p>

          <v-file-input
            v-if="!soloLectura"
            :label="`${newEstado === 2 ? 'Resolución de admisión' : 'Resolución de rechazo'}`"
            v-model="doc_admision_recurso"
            outlined
            accept="application/pdf"
            class="mt-4"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            clearable
            @blur="$v.doc_admision_recurso.$touch()"
            :error-messages="docAdmisionRecursoErrors"
            :disabled="cambioEstadoImpugnacion.isLoading"
            :hide-details="docAdmisionRecursoErrors?.length === 0"
          />
          <div style="gap: 16px" class="d-flex justify-center flex-wrap mt-8">
            <v-btn
              @click.stop="showDialogadmisionRecurso = false"
              color="primary"
              class="flex-grow-1 flex-shrink-1"
              outlined
              :disabled="cambioEstadoImpugnacion.isLoading"
            >
              Cerrar
            </v-btn>
            <v-btn
              @click.stop="updateEstadoRecurso()"
              color="primary"
              class="flex-grow-1 flex-shrink-1 white--text"
              :loading="cambioEstadoImpugnacion.isLoading"
              :disabled="!(this.doc_admision_recurso && !soloLectura)"
            >
              {{ newEstado === 2 ? "Admitir" : "Rechazar" }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ModalCrearPrevencion 
      :is-open.sync="modalCrearPrevencionAbierta" 
      :id-recurso-revision="doc_impugnacion_selected?.id"
      @on-save="manejarCreacionPrevencion"
    />

    <PdfModal
      :isOpen.sync="previsualizacionPrevencionAbierta" 
      :source-loadable="visualizacionPrevencion"
      filename="documento-prevencion"
      @on-visibility-change="manejarVisibilidadPrevisualizacionPrevencion"
    />

    <ModalDetallePrevencion 
      :is-open.sync="modalDetallePrevencionAbierta"
      :id-recurso-revision="doc_impugnacion_selected?.id"
    />

    <PdfModal
      :isOpen.sync="previsualizacionSubsanacionAbierta" 
      :source-loadable="visualizacionSubsanacion"
      filename="documento-subsanacion"
      @on-visibility-change="manejarVisibilidadPrevisualizacionSubsanacion"
    />
  </section>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import ModalDetallePrevencion from '../prevencionRecursoRevision/ModalDetallePrevencion.vue';
import ModalCrearPrevencion from './ModalCrearPrevencion.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import DataTable from "@/components/DataTableComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { Observable, Listener } from '@/utils/observable';
import ContinuarProceso from "./ContinuarProceso.vue";
import { required } from "vuelidate/lib/validators";
import PdfModal from '@/components/PdfModal.vue';
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { ValidacionAccesos } from '@/utils/role-utils';

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}
function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "AdmisionRecursoComponent",
  components: { DataTable, ContinuarProceso, ModalCrearPrevencion, PdfModal, ModalDetallePrevencion, VuePdfEmbed },
  props: {
    etapa: {
      type: Object,
      default: () => {},
    },
    soloLectura: {
      type: Boolean,
      default: false,
    },
    getProceso: {
      type: Function,
    },
    cargarEtapaActual: {
      type: Function,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Código",
        align: "center",
        value: "correlativo",
        width: "25%",
      },
      {
        text: "Proveedor",
        align: "center",
        value: "proveedor",
        width: "20%",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        width: "20%",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        width: "20%",
        align: "center",
      },
      {
        text: "Acciones",
        align: "center",
        value: "actions",
        width: "25%",
        sortable: false,
      },
    ],
    cargandoRecursosRevision: false,
    items: [],
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    showDialog: false,
    doc: null,
    id_impugnacion: null,
    estado_impugnacion_selected: null,
    doc_admision_recurso: null,
    showDialogadmisionRecurso: false,
    newEstado: null,
    doc_impugnacion_selected: null,
    cambioEstadoImpugnacion: createLoadable(null),
    // Prevencion
    modalCrearPrevencionAbierta: false,
    previsualizacionPrevencionAbierta: false,
    visualizacionPrevencion: createLoadable(null),
    modalDetallePrevencionAbierta: false,
    // Subsanacion
    visualizacionSubsanacion: createLoadable(null),
    previsualizacionSubsanacionAbierta: false,
    // Listeners
    unsubscriber: null,
  }),
  validations: {
    doc_admision_recurso: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    docAdmisionRecursoErrors() {
      const errors = [];
      if (!this.$v.doc_admision_recurso.$dirty) return errors;

      !this.$v.doc_admision_recurso.required &&
        errors.push("El archivo es requerido");
      !this.$v.doc_admision_recurso.fileTypeValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.doc_admision_recurso.fileNameValid &&
        errors.push("El nombre del archivo debe ser sin caracteres especiales");
      !this.$v.doc_admision_recurso.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);      
      
      return errors;
    },
    idProceso() {
      return this.$route.params.idProceso;
    },
    todasLasImpugnacionesHanSidoRechazadas() {
      return this.items.length > 0 && this.items.every((impugnacion) => impugnacion.id_estado_impugnacion === 3);
    },
    esProveedor() {
      return ValidacionAccesos.ES_PROVEEDOR;
    },
    esTACOP() {
      return ValidacionAccesos.ES_TACOP;
    },
    esUCP() {
      return ValidacionAccesos.ES_UCP;
    },
    esAlMenosUCP() {
      return ValidacionAccesos.ES_AL_MENOS_UCP;
    },
    mostrarCreacionPrevencion() {
      return this.doc_impugnacion_selected?.EstadoImpugnacion?.id === 1 && !this.esProveedor && !this.doc_impugnacion_selected?.prevencion;
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarBufferDocumento", "descargarArchivoProceso"]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getRecursosRevision();
    },
    async getRecursosRevision(validarImpugnaciones) {
      try {
        this.cargandoRecursosRevision = true;

        const { status, data } = await this.services.RecursoRevision.getRecursosRevisionUcp( this.$route.params.idProceso, {
          id_tipo: 1,
          page: this.pagina,
          per_page: this.por_pagina,
          id_etapa_proceso: this.procesoData.id_etapa_pausa,
        });
  
        if (status === 200) {
          this.items = data;
          const todasLasImpugnacionesHanSidoAtendidas = data.every((impugnacion) => [2, 3].includes(impugnacion.id_estado_impugnacion));
  
          if (validarImpugnaciones && todasLasImpugnacionesHanSidoAtendidas) {
            this.cargarEtapaActual();
            this.getProceso();
            Observable.emit('recargar-etapas-impugnaciones-ucp');
          }
        }
      } catch (error) {
        this.items = [];
      } finally {
        this.cargandoRecursosRevision = false;
      }
    },
    async showDocument(item) {
      const response = await this.descargarBufferDocumento({
        ruta_documento: item.doc_impugnacion,
      });
      this.id_impugnacion = item.id;
      this.estado_impugnacion_selected = item.EstadoImpugnacion;
      this.getDoc(response);
      this.showDialog = true;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
    getColor(id_estado) {
      switch (id_estado) {
        case 1:
          return "#8EBCE1";
        case 2:
          return "green";
        case 3:
          return "red";
        default:
          return "grey";
      }
    },
    async updateEstadoRecurso() {
      if (this.$v.doc_admision_recurso.$invalid) {
        const maxSize = LegacyValidations.getMaxSizeString();
        this.temporalAlert({
          show: true,
          message: `Debe seleccionar un archivo PDF válido, sin carácteres especiales y menor a ${maxSize}`,
          type: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("doc_resolucion", this.doc_admision_recurso);
      formData.append("id_estado_impugnacion", this.newEstado);

      toggleLoadable(this.cambioEstadoImpugnacion);
      const { data } = await this.services.RecursoRevision.putEstadoImpugnacion(
        this.$route.params.idProceso,
        this.id_impugnacion,
        formData
      );

      setLoadableResponse(this.cambioEstadoImpugnacion, data, { skipOnSuccess: true });
      this.showDialog = false;
      this.id_impugnacion = null;
      this.estado_impugnacion_selected = null;
      this.newEstado = null;
      this.showDialogadmisionRecurso = false;
      this.doc_admision_recurso = null;

      if (isResponseSuccesful(data)) {
        const message = `Recurso ${this.newEstado === 2 ? 'admitido' : 'rechazado'} con éxito`;
        this.pushAppMessage({ type: 'success', message });
        this.getRecursosRevision(true);
      }
    },
    async descargarArchivo() {
      this.descargarArchivoProceso({ ruta_documento: this.doc_impugnacion_selected.doc_impugnacion });
    },
    // Prevencion
    abrirModalCreacionPrevencion() {
      this.showDialog = false;
      this.modalCrearPrevencionAbierta  = true;
    },
    manejarCreacionPrevencion() {
      this.getRecursosRevision(true);
      Observable.emit('recargar-etapa-actual-ucp');
      Observable.emit('recargar-etapas-impugnaciones-ucp');
    },
    async visualizarPrevencion(recursoRevision) {
      this.doc_impugnacion_selected = { ...recursoRevision };
      this.previsualizacionPrevencionAbierta = true;
      toggleLoadable(this.visualizacionPrevencion);
      const { data } = await this.services.PrevencionRecursoRevision.visualizarPrevencion(recursoRevision.prevencion.id);
      setLoadableResponse(this.visualizacionPrevencion, data, { isFile: true });
    },
    manejarVisibilidadPrevisualizacionPrevencion(visible) {
      this.previsualizacionPrevencionAbierta = visible;
    },
    abrirModalDetallePrevencion(recursoRevision) {
      this.doc_impugnacion_selected = { ...recursoRevision };
      this.modalDetallePrevencionAbierta = true;
    },
    // Subsanacion
    async visualizarSubsanacion(recursoRevision) {
      this.recursoRevisionActivo = { ...recursoRevision };
      this.previsualizacionSubsanacionAbierta = true;
      toggleLoadable(this.visualizacionSubsanacion);
      const { data } = await this.services.PrevencionRecursoRevision.visualizarSubsanacionPrevencion(recursoRevision.prevencion.subsanacion.id);
      setLoadableResponse(this.visualizacionSubsanacion, data, { isFile: true });
    },
    manejarVisibilidadPrevisualizacionSubsanacion(visible) {
      this.previsualizacionSubsanacionAbierta = visible;
    },
  },
  watch: {
    showDialogadmisionRecurso(value) {
      if (!value) {
        this.doc_admision_recurso = null;
        this.$v.doc_admision_recurso.$reset();
      }
    }
  },
  created() {
    this.getRecursosRevision();
    const listener = new Listener('ADMISION_RECURSO').setHandler(() => this.getRecursosRevision());
    this.unsubscriber = Observable.getInstance().subscribe('RECARGAR_ETAPA_ADMISION_RECURSOS', listener);
  },
  beforeDestroy() {
    this?.unsubscriber();
  },
};
</script>

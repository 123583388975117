<template>
  <v-dialog v-model="showModalEditCreateSubasta" persistent>
    <v-card>
      <v-card-title>
        <p class="text-h5 secondary--text">Configuración</p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-autocomplete clearable label="Seleccione un lote *" outlined v-model="form_enviar.id_lote"
              :items="ctlLotes" :disabled="subasta_seleccionada?.id ? true : false" item-value="id" item-text="nombre"
              @change="getLoteDetail" />
          </v-col>
          <v-col cols="12" md="6" v-if="form_enviar.id_lote">
            <v-autocomplete label="Seleccione el tipo de subasta*" outlined v-model="form_enviar.ctlSubasta"
              :disabled="subasta_seleccionada?.id ? true : false" :items="ctlSubastas" return-object
              item-text="nombre" />
          </v-col>
        </v-row>
        <erroresUnitaria />
        <template v-if="
          form_enviar.id_lote &&
          form_enviar.ctlSubasta &&
          form_enviar.ctlSubasta.tipo_operacion === 'resta_unitaria' &&
          solicitudes_lote?.length > 1
        ">
          <unitariaAlerta />
          <multiplesSubastasUnitarias />
        </template>
        <template v-else-if="form_enviar.id_lote && form_enviar.ctlSubasta">
          <DetalleLoteSeleccionado />
          <camposComunes />
          <singularSubasta />
        </template>
      </v-card-text>
      <v-card-actions>
        <acciones />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations, mapState, mapActions } from "vuex";

import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import DetalleLoteSeleccionado from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/detalleLoteSeleccionado.vue";
import unitariaAlerta from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/unitariaAlerta.vue";
import camposComunes from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/camposComunes.vue";
import acciones from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/acciones.vue";
import singularSubasta from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/singularSubasta.vue";
import multiplesSubastasUnitarias from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/multiplesSubastasUnitarias.vue";
import erroresUnitaria from "@/views/ProcesoCompraDoc/components/Etapas/configuracionSalas/erroresUnitaria.vue";
export default {
  name: "ConfiguracionSalaView",
  components: {
    MoneyTextFieldComponent,
    DetalleLoteSeleccionado,
    unitariaAlerta,
    camposComunes,
    acciones,
    singularSubasta,
    multiplesSubastasUnitarias,
    erroresUnitaria,
  },
  data: () => ({
    loadingSaveBtn: false,
  }),
  watch: {
    "form_enviar.ctlSubasta": {
      handler: async function (val) {
        this.form_enviar.id_ctl_subasta = val?.id;
        if (
          !!val &&
          this.form_enviar?.ctlSubasta?.tipo_operacion === "resta_unitaria" &&
          this.form_enviar.id_lote
        ) {
          await this.obtenerSubProcesosObsLote();
          this.form_enviar.monto_inicial = this.montoTotalLote?.total_unitaria;
          this.form_enviar.cantidadElementos = this.loteDetails.reduce(
            (acc, item) => +acc + +item.total_sub_proceso,
            0
          );
          return;
        }
        this.form_enviar.monto_inicial = this.montoTotalLote?.total ?? this.form_enviar.monto_inicial;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    ...mapState("configuracionSalas", [
      "headersLoteSubasta",
      "showModalEditCreateSubasta",
      "subasta_seleccionada",
      "procesoCompra",
      "sala_seleccionada",
      "form_enviar",
      "ctlLotes",
      "montoTotalLote",
      "montoInicial",
      "loteDetails",
      "ctlSubastas",
      "solicitudes_lote",
      "lote_seleccionado",
    ]),
    ...mapState("etapaSubastaInversaStore", ["form", "isShow"]),
  },
  methods: {
    ...mapMutations("procesoCompraDoc", ["setInfoProceso"]),
    ...mapActions("configuracionSalas", [
      "getLotes",
      "getLoteDetail",
      "getCatalogoSubastas",
      "getSubasta",
      "obtenerSubProcesosObsLote",
    ]),
    async getProceso() {
      const { data } = await this.services.PacProcesos.getProceso(
        this.procesoCompra
      );
      this.setInfoProceso(data);
    },
  },
  async created() {
    // await this.getProceso();
    await this.getLotes();
    await this.getCatalogoSubastas();
    if (this.subasta_seleccionada?.id) {
      await this.getSubasta();
    }
  },
};
</script>

<template>
  <section fluid>
    <v-row>
      <v-col v-if="!soloLectura" cols="12">
        <v-btn
          v-if="procesoData.id_seguimiento_proceso === 3
            && ![4, 5, 6, 7, 8, 9].includes(procesoData.id_estado_proceso)
            && haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])"
          color="secondary" class="bgWhite--text" :block="$vuetify.breakpoint.xs ? true : false"
          @click="showModalInvitar = true">
          Registrar proveedor
        </v-btn>
        <div v-else>
          Solo se puede registrar proveedores durante la ejecución del proceso de compra.
        </div>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items">
          <!-- Template para valoraciones -->
          <template v-slot:[`item.ponderacion`]="{ item }">
            <v-rating half-increments :value="Number(item.ponderacion)" readonly color="secondary" dense />
          </template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon v-if="haveRole('ROLE_UACI')">
              <v-icon color="secondary" @click="verParticipante(item)">
                mdi-eye
              </v-icon>
            </v-btn>
            <v-btn v-if="showProvCmp" icon @click="
              $router.push({
                name: 'proveedor',
                params: { id_proveedor: item.id_usuario },
              })
              ">
              <v-icon color="secondary"> mdi-account-eye </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal para ver la información de los participantes -->
    <app-dialog :max-width="1200" v-model="showModalVer" title="Oferta presentada">
      <template #content>
        <InformacionProvedor :providerData="proveedor" />
      </template>
      <template #actions>
        <div class="mt-3">
          <v-btn color="secondary" @click="showModalVer = false" class="mr-4" outlined>
            Cerrar
          </v-btn>
        </div>
      </template>
    </app-dialog>

    <app-dialog :max-width="1200" v-model="showModalInvitar" title="Invitar participante" @close="closeModal">
      <template #content>
        <div>
          <p>Digite el nombre comercial de la empresa a la que se desea invitar</p>
        </div>
        <!-- Autocomplete para buscar proveedores -->
        <v-row>
          <v-col cols="12">
            <v-autocomplete clearable :items="lista_proveedores_dos" outlined item-text="nombre_completo" return-object
              :search-input.sync="nombre_comercial_model" label="Nombre comercial" @keyup="getListadoProveedores"
              v-model="proveedor_model" />
          </v-col>
        </v-row>

        <v-row v-if="proveedor_model != null">
          <v-col cols="12">
            <InformacionProvedor :providerData="proveedor_model" />
          </v-col>
        </v-row>
      </template>

      <template #actions>
        <div class="mt-3">
          <v-btn color="secondary" @click="closeModal" class="mr-4" outlined>
            Cerrar
          </v-btn>
          <v-btn v-if="proveedor_model != null" color="secondary" @click="enviarInvitacion" outlined
            :loading="loadingInvitacion">
            Invitar
          </v-btn>
        </div>
      </template>
    </app-dialog>
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "ParticipantesComponent",
  components: {
    DataTable,
    InformacionProvedor,
  },
  props: {
    soloLectura: { type: Boolean },
  },
  data: () => ({
    showModalVer: false,
    showModalInvitar: false,
    loadingInvitacion: false,
    proveedor: {},
    headers: [
      {
        text: "Participante",
        align: "start",
        sortable: true,
        value: "nombre_comercial",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "acciones",
      },
    ],
    items: [],
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    ...mapState("procesoCompra", [
      "lista_proveedores",
      "nombre_comercial",
      "proveedor_invitar",
      "info_general",
    ]),
    lista_proveedores_dos() {
      return this.lista_proveedores.map(item => ({
        ...item,
        nombre_completo: `${item.nombre_comercial} - ${item.nombre}`,
      }))
    },
    showProvCmp() {
      return (
        this.haveRoles(["ROLE_UACI", "ROLE_UACI_TECNICO"]) &&
        this.procesoData?.id_seguimiento_proceso != 5 &&
        this.haveRole('ROLE_RUPES_PROVEEDORES_LIST')
      );
    },
    proveedor_model: {
      get() {
        return this.proveedor_invitar;
      },
      set(value) {
        this.setProveedor(value);
      },
    },
    nombre_comercial_model: {
      get() {
        return this.nombre_comercial;
      },
      set(value) {
        this.setNombreComercial(value);
      },
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getListadoProveedores",
      "setNombreComercial",
      "setProveedor",
      "invitarProveedor",
    ]),
    async enviarInvitacion() {
      this.loadingInvitacion = true;
      const params = {
        correo: this.proveedor_invitar.email,
      };
      try {
        const response = await this.services.Paac.postInvitarProveedor(
          this.$route.params.idProceso,
          params
        );
        if (response.status == 200) {
          this.proveedor_model = null;
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "success",
          });
          this.showModalInvitar = false;
          this.obtenerParticipantes();
        }
      } catch (error) {
      } finally {
        this.loadingInvitacion = false;
      }
    },
    async verParticipante(item) {
      const response = await this.services.Paac.getMostrarProveedor(item.id);

      if (response.status == 200) {
        this.proveedor = response.data;
        this.showModalVer = true;
      } else {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "error",
        });
      }
    },
    async obtenerParticipantes() {
      let response = null;

      if (
        this.procesoData.id_seguimiento_proceso == 1 &&
        (this.procesoData.id_forma_contratacion == 2 ||
          this.procesoData.id_forma_contratacion == 4)
      ) {
        response = await this.services.Paac.getProveedoresInvitados(
          this.procesoData.id
        );
      } else {
        response = await this.services.Paac.getProveedoresParticipantes(
          this.procesoData.id
        );
      }

      if (response != null && response.status == 200) {
        this.items = response.data;
      }
    },
    closeModal() {
      this.showModalInvitar = false;
      this.resetModal();
    },
    resetModal() {
      this.nombre_comercial_model = '';
      this.proveedor_model = null;
      this.lista_proveedores = [];
    },
  },
  created() {
    this.obtenerParticipantes();
  },
  mounted() {
  }
};
</script>

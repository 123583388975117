<template>
  <v-expansion-panels focusable>
    <v-expansion-panel v-for="apartado in arbol" :key="apartado.id">
      <v-expansion-panel-header> {{ apartado.nombre }} </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ArbolDocumentosProceso v-if="apartado.sub_tipos" :arbol="apartado.sub_tipos">
          <template v-slot:documentos="{ documentos, tipo }">
            <slot name="documentos" :documentos="documentos" :tipo="tipo" />
          </template>
        </ArbolDocumentosProceso>
        <slot v-else name="documentos" :documentos="apartado.documentos" :tipo="apartado.nombre" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>

export default {
    name: 'ArbolDocumentosProceso',
    props: {
        arbol: { type: Array },
    },
}
</script>
<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="800px" persistent>
            <v-card style="position: relative;">
                <v-card-title class="px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-4 py-4">
                    <v-form v-model="formularioValidado" ref="form" @submit.prevent="validarEnvioFormulario">
                        <v-file-input
                            v-model="form.adjunto"
                            outlined
                            light
                            label="Documento"
                            placeholder="Documento"
                            accept="application/pdf"
                            :rules="reglasValidacionAdjunto"
                        />
        
                        <v-textarea
                            v-model="form.descripcion"
                            outlined
                            label="Descripción"
                            placeholder="Descripción de la subsanación"
                            class="mt-4"
                            rows="4"
                            no-resize
                            :rules="reglasValidacionDescripcion"
                        />
    
                        <div 
                            class="d-flex justify-center align-center mt-4" 
                            style="position: sticky; bottom: 16px; background: white; gap: 16px;"
                        >
                            <v-btn 
                                class="flex-grow-1 flex-shrink-1" 
                                color="secondary" 
                                large
                                @click.stop="cerrarModal"
                            >
                                Cerrar
                            </v-btn>
                            <v-btn 
                                class="flex-grow-1 flex-shrink-1" 
                                color="primary" 
                                type="submit"
                                large
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent
            :isOpen="confirmacionAbierta" 
            description="¿Desea continuar con la creación de la subsanación?" 
            @confirm="crearSubsanacion" 
            @cancel="confirmacionAbierta = false"  
            :isLoading="creacionSubsanacion.isLoading"
        />
    </Fragment>
</template>
<script>
import { personalizarMensajeRegla, reglaExtensionPDF, reglaNombreArchivoValido, reglaRequerido } from '@/utils/form-rules';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { Fragment } from 'vue-fragment';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { convertToFormData } from '@/utils/data';

export default {
    name: 'ModalSubsanarPrevencion',
    emits: ['on-save'],
    components: { ConfirmationModalComponent },
    props: {
        isOpen: { type: Boolean },
        idPrevencion: { type: [Number, String] }
    },
    data: () => ({
        formularioValidado: false,
        form: {
            adjunto: null,
            descripcion: null,
        },
        cargaUtilFormulario: {
            adjunto: null,
            descripcion: null,
        },
        confirmacionAbierta: false,
        creacionSubsanacion: createLoadable(null),
    }),
    computed: {
        reglasValidacionAdjunto() {
            return [personalizarMensajeRegla(reglaRequerido, 'Archivo requerido'), reglaExtensionPDF, reglaNombreArchivoValido];
        },
        reglasValidacionDescripcion() {
            return [personalizarMensajeRegla(reglaRequerido, 'Campo requerido')];
        },
    },
    methods: {
        emitirCierreModal() {
            this.$emit('update:isOpen', false);
        },
        reiniciarFormulario() {
            this.$refs.form.reset();
            this.form = {
                adjunto: null,
                descripcion: null,
            }
        },
        cerrarModal() {
            this.reiniciarFormulario();
            this.emitirCierreModal();
        },
        validarEnvioFormulario() {
            this.$refs.form.validate();

            if (!this.formularioValidado) return;

            this.cargaUtilFormulario = { ...this.form };
            this.cerrarModal();
            this.confirmacionAbierta = true;
        },
        async crearSubsanacion() {
            const formData = convertToFormData(this.cargaUtilFormulario);

            toggleLoadable(this.creacionSubsanacion);
            const { data } = await this.services.PrevencionRecursoRevision.subsanarPrevencion(this.idPrevencion, formData);
            this.confirmacionAbierta = false;
            setLoadableResponse(this.creacionSubsanacion, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.$emit('on-save');
        },
    },
}
</script>
<template>
  <section class="ma-8">
    <template v-if="!cargandoEtapasActuales">
      <template v-for="(etapa, index) in etapasActuales">
        <AdmisionRecursoComponent
          v-if="etapa.id_etapa_proceso_impugnacion == 1"
          :solo-lectura="soloLectura"
          :etapa="etapa"
          :get-proceso="getProceso"
          :cargar-etapa-actual="getEtapaActual"
          :class="index === 0 ? '' : 'mt-16'"
        />
  
        <ResolucionRecursosComponent
          v-if="etapa.id_etapa_proceso_impugnacion == 2"
          :solo-lectura="soloLectura"
          :etapa="etapa"
          :get-proceso="getProceso"
          :cargar-etapa-actual="getEtapaActual"
          :class="index === 0 ? '' : 'mt-16'"
        />
  
        <!-- <PresentacionRecursoApelacion
          v-if="etapa.id_etapa_proceso_impugnacion == 3"
          :etapa="etapa"
        />
        
        <AdmisionRecursoApelacionComponent
          v-if="etapa.id_etapa_proceso_impugnacion == 4"
          :solo-lectura="soloLectura"
          :etapa="etapa"
        />
  
        <ResolucionRecursoApelacionComponent
          v-if="etapa.id_etapa_proceso_impugnacion == 5"
          :solo-lectura="soloLectura"
          :etapa="etapa"
        /> -->
  
        <PrevencionesRecursoRevision 
          v-if="etapa.id_etapa_proceso_impugnacion == 6" 
          :etapa="etapa"  
          :class="index === 0 ? '' : 'mt-16'"
        />
      </template>
    </template>
    <div v-if="etapasActuales.length === 0 && !cargandoEtapasActuales" class="d-flex flex-column justify-center mt-4" style="gap: 16px;">
      <v-icon style="font-size: 60px !important;" class="secondary--text">mdi-clock</v-icon>
      <h5 class="text-h5 text-center font-weight-bold secondary--text">
        No se ha encontrado etapa activa
      </h5>
    </div>

    <template v-if="cargandoEtapasActuales">
      <v-skeleton-loader type="button" />
      <v-skeleton-loader type="paragraph" class="mt-2" />
    </template>
  </section>
</template>

<script>
import ResolucionRecursoApelacionComponent from "./componentesUCP/ResolucionRecursoApelacionComponent.vue";
import AdmisionRecursoApelacionComponent from "./componentesUCP/AdmisionRecursoApelacionComponent.vue";
import PresentacionRecursoApelacion from "./componentesUCP/PresentacionRecursoApelacionComponent.vue";
import PrevencionesRecursoRevision from "./prevencionRecursoRevision/PrevencionesRecursoRevision.vue";
import ResolucionRecursosComponent from "./componentesUCP/ResolucionRecursosComponent.vue";
import RecepcionRecursosComponent from "./componentesUCP/RecepcionRecursosComponent.vue";
import AdmisionRecursoComponent from "./componentesUCP/AdmisionRecursoComponent.vue";
import { Observable, Listener } from '@/utils/observable.js';
import { mapActions } from "vuex";

export default {
  name: "UcpEtapaActualComponent",
  components: {
    RecepcionRecursosComponent,
    AdmisionRecursoComponent,
    ResolucionRecursosComponent,
    PresentacionRecursoApelacion,
    AdmisionRecursoApelacionComponent,
    ResolucionRecursoApelacionComponent,
    PrevencionesRecursoRevision,
  },
  props: {
    getProceso: { type: Function }
  },
  data: () => ({
    etapasActuales: [],
    unsubscribe: null,
    cargandoEtapasActuales: false,
  }),
  computed: {
    soloLectura() {
      return this.haveRole('ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION');
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["getEtapaActualProcesoImpugnacion"]),
    async getEtapaActual() {
      try {
        this.cargandoEtapasActuales = true;
        const response = await this.getEtapaActualProcesoImpugnacion({ id_proceso_compra: this.$route.params.idProceso });
        this.etapasActuales = response;   
      } finally {
        this.cargandoEtapasActuales = false;
      }
    },
  },
  created() {
    this.getEtapaActual();
    const listener = new Listener('UcpEtapaActualComponent').setHandler(() => this.getEtapaActual());
    this.unsubscribe = Observable.getInstance().subscribe('recargar-etapa-actual-ucp', listener);
  },
  beforeDestroy() {
    this.unsubscribe?.();
  },
};
</script>

<template>
    <Fragment>
        <h6 class="text-h6 font-weight-bold text-uppercase mb-1">Actos de trámite</h6>
        <v-divider />

        <DataTableComponent 
            :items="actasRecursosApelacion.data"
            :headers="actasRecursosApelacionHeaders"
            :show_loading="actasRecursosApelacion.isLoading"
            :tiene_paginacion="false"
            class="mt-2"
        >
            <template v-slot:item.created_at="{ item }">{{ formatDate(item.acta.created_at) }}</template>
            <template v-slot:item.acta="{ item }">
                <div class="d-flex align-center justify-center" style="gap: 16px;">
                    <v-btn 
                        icon 
                        :disabled="visualizacionActaRA.isLoading"
                        @click.stop="visualizarActaRecursoApelacion(item)"
                    >
                        <v-icon color="primary">mdi-eye</v-icon>
                    </v-btn>
                </div>
            </template>
        </DataTableComponent>

        <p class="mb-0 mt-8 text-uppercase font-weight-bold text-body-2">Documentos adicionales</p>
        <v-divider />
        <v-btn 
            v-if="puedeManipularDocumentos"  
            @click.stop="modalCrearActoTramite = true" 
            color="primary" 
            class="mt-4"
        >
            Subir documento
        </v-btn>

        <DataTableComponent 
            :items="actosTramite.data"
            :headers="actosTramiteHeaders"
            :show_loading="actosTramite.isLoading"
            :tiene_paginacion="false"
            class="mt-4"
        >
            <template v-slot:item.fecha_carga="{item}">{{ formatDate(item.created_at) }}</template>
            <template v-slot:item.acciones="{item}">
                <div class="d-flex align-center justify-center" style="gap: 16px;">
                    <v-icon @click.stop="visualizarDocumento(item)">
                        mdi-eye
                    </v-icon>
                    <v-icon v-if="puedeManipularDocumentos" @click.stop="confirmarEliminacionPronunciamiento(item)">
                        mdi-trash-can
                    </v-icon>
                </div>
            </template>
        </DataTableComponent>


        <v-dialog v-if="puedeManipularDocumentos" v-model="modalCrearActoTramite" max-width="700" persistent>
            <v-card class="px-4 py-4">
                <div>
                    <v-icon @click.stop="cerrarCreacion">mdi-close</v-icon>
                </div>
                <v-form ref="form" class="mt-4" @submit.prevent="validarCreacionActoTramite">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.nombre" 
                                label="Nombre de documento" 
                                outlined 
                                @change="$v.form.nombre.$touch()"
                                @blur="$v.form.nombre.$touch()"
                                :error-messages="nombreErrors"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-file-input
                                v-model="form.documento"
                                label="Adjunto"
                                accept="application/pdf"
                                outlined 
                                @change="$v.form.documento.$touch()"
                                :error-messages="documentoErrors"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                                x-large 
                                color="primary" 
                                style="width: 100%;" 
                                type="submit"
                            >
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>

        <ConfirmationModalComponent
            :is-open="confirmacionCreacion"
            :is-loading="guardadadoActoTramite.isLoading"
            description="¿Desea guardar el documento?"
            @confirm="crearActoTramite"
            @cancel="confirmacionCreacion = false"
        />

        <ConfirmationModalComponent
            :is-open="confirmacionEliminado"
            :is-loading="eliminacionActoTramite.isLoading"
            description="¿Desea eliminar el documento?"
            @confirm="eliminarActoTramite"
            @cancel="confirmacionEliminado = false"
        />

        <PdfModal
            :isOpen="modalVisualizacionDocumento" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="cerrarModalVisualizacionDocumento"
            :filename="filename"
        />

        <PdfModal
            :isOpen.sync="modalVisualizarActaRA" 
            :source-loadable="visualizacionActaRA" 
            filename="acta-recurso"
        />
    </Fragment>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import { Validator } from '@/utils/form-validation.js';
import { required } from 'vuelidate/lib/validators'
import { convertToFormData } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';

export default {
    name: 'ActosTramite',
    components: { Fragment, DataTableComponent, ConfirmationModalComponent, PdfModal },
    validations() {
        return ({
            form: {
                nombre: { required },
                documento: { required },
            },
        });
    },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data() {
        return ({
            actoTramiteActivo: false,
            // Form
            modalCrearActoTramite: false,
            form: {
                nombre: null,
                documento: null,
            },
            confirmacionCreacion: false,
            guardadadoActoTramite: createLoadable(null),
            actoTramiteCrear: null,
            // Actos
            actosTramite: createLoadable([]),
            actosTramiteHeaders: [],
            // Eliminado
            confirmacionEliminado: false,
            eliminacionActoTramite: createLoadable(null),
            // Visualizacion
            modalVisualizacionDocumento: false,
            visualizacionDocumento: createLoadable(null),
            // Actas de recursos de apelacion
            actasRecursosApelacion: createLoadable([]),
            // Visualizacion de acta de RA
            visualizacionActaRA: createLoadable(null),
            modalVisualizarActaRA: false,
        });
    },
    computed: {
        esProveedor() {
            return this.haveRoles(['ROLE_PROVEEDOR']);
        },
        esRoleTacop() {
            return this.haveRoles(['ROLE_TACOP']);
        },
        puedeManipularDocumentos() {
            return !this.esProveedor;
        },
        filename() {
            return this.actoTramiteActivo?.nombre_documento ?? 'pronunciamiento';
        },
        // Errores
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        documentoErrors() {
            return new Validator(this.$v.form.documento).detect().getResult();
        },
        actasRecursosApelacionHeaders() {
            return [
                { text: 'Recurso', value: 'correlativo', align: 'center', sortable: false },
                { text: 'Fecha de carga', value: 'created_at', align: 'center', sortable: false },
                { text: 'Ver acta', value: 'acta', align: 'center', sortable: false },
            ]
        },
    },
    methods: {
        // UI
        formatDate,
        limpiarFormulario() {
            this.$refs.form.reset();
            this.$v.form.$reset();
        },
        cerrarCreacion() {
            this.modalCrearActoTramite = false;
            this.limpiarFormulario();
        },
        confirmarEliminacionPronunciamiento(actosTramite) {
            this.actoTramiteActivo = actosTramite;
            this.confirmacionEliminado = true;
        },
        validarCreacionActoTramite() {
            this.$v.form.$touch();


            if (this.$v.form.$invalid) return;

            this.actoTramiteCrear = { ...this.form };
            this.modalCrearActoTramite = false;
            this.confirmacionCreacion = true;
            this.limpiarFormulario();
        },
        cerrarModalVisualizacionDocumento(visible) {
            this.modalVisualizacionDocumento = visible;
        },
        // Data
        async cargarActosTramite() {
            toggleLoadable(this.actosTramite);
            const { data } = await this.services.RecursoApelacion.cargarActosTramite(this.idProcesoCompra);
            setLoadableResponse(this.actosTramite, data);  
        },
        async crearActoTramite() {
            const formData = convertToFormData(this.actoTramiteCrear);
            toggleLoadable(this.guardadadoActoTramite);
            const { data } = await this.services.RecursoApelacion.guardarActoTramite(this.idProcesoCompra, formData);
            this.confirmacionCreacion = false;
            setLoadableResponse(this.guardadadoActoTramite, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            this.cargarActosTramite();
        },
        async eliminarActoTramite() {
            toggleLoadable(this.eliminacionActoTramite);
            const { data } = await this.services.RecursoApelacion.eliminarActoTramite(this.idProcesoCompra, this.actoTramiteActivo.id);
            this.confirmacionEliminado = false;
            setLoadableResponse(this.eliminacionActoTramite, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarActosTramite();
        },
        async visualizarDocumento(actoTramite) {
            this.actoTramiteActivo = actoTramite;
            this.modalVisualizacionDocumento = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.RecursoApelacion.visualizarDocumentoEtapaResolucion(actoTramite.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        // Actas de recursos de apelacion
        async cargarActasRecursosApelacion() {
            toggleLoadable(this.actasRecursosApelacion);
            const { data } = await this.services.RecursoApelacion.cargarActasRecursosApelacion(this.idProcesoCompra);
            setLoadableResponse(this.actasRecursosApelacion, data);
        },
        async visualizarActaRecursoApelacion(recursoApelacion) {
            this.modalVisualizarActaRA = true;
            toggleLoadable(this.visualizacionActaRA);
            const { data } = await this.services.RecursoApelacion.visualizarActaRecursoApelacion(this.idProcesoCompra, recursoApelacion.id);
            setLoadableResponse(this.visualizacionActaRA, data, { isFile: true });
        },
    },
    created() {
        this.cargarActosTramite();
        this.cargarActasRecursosApelacion();

        if (this.esProveedor) {
            this.actosTramiteHeaders = [
                { text: 'Documento', value: 'nombre_documento', align: 'center', sortable: false },
                { text: 'Fecha de carga', value: 'fecha_carga', align: 'center', sortable: false },
            ];
        }
        else {
            this.actosTramiteHeaders = [
                { text: 'Documento', value: 'nombre_documento', align: 'center', sortable: false },
                { text: 'Fecha de carga', value: 'fecha_carga', align: 'center', sortable: false },
                { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
            ];
        }
    },
}
</script>
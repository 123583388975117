<template>
  <v-expansion-panels class="my-3">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <p class="mb-0 font-weight-bold text-h6">
          <v-icon style="transform: translateY(-2px)" size="25">mdi-account</v-icon>
          {{ proveedorProp.proveedor }}
        </p>
      </v-expansion-panel-header>
      <v-divider />
      <v-expansion-panel-content>
        <div class="d-flex flex-wrap mt-4" style="gap: 24px;">
          <v-card v-for="recurso in recursos" :key="recurso.id" class="flex-grow-1 flex-shrink-1" style="min-width: 250px;">
            <v-card-title>
              <h5 class="text-subtitle-1 text-uppercase font-weight-bold">{{ recurso.tipo.nombre }}</h5>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <dl>
                <dt class="font-weight-bold">Cargado por</dt>
                <dd>{{ recurso.usuario.nombre_usuario }}</dd>

                <dt class="font-weight-bold mt-4">Fecha de subida</dt>
                <dd>{{ FormatDatePrt(recurso.created_at, true) }}</dd>
              </dl>

              <v-btn 
                @click="descargarArchivoProceso({ ruta_documento: recurso.ruta })" 
                color="secondary" 
                class="mt-4"
                outlined
              >
                <v-icon color="secondary" class="mr-1">mdi-download</v-icon>
                Descargar
              </v-btn>

              <h6 class="text-subtitle-1 text-uppercase font-weight-bold mt-8">
                <v-icon style="transform: translateY(-2px)" size="20">mdi-text-box</v-icon>
                Actas
              </h6>
              <v-divider class="mt-2" />
              <data-table-component
                class="mt-1"
                :headers="headersActas"
                :items="recurso.actas"
                inhabilitar_paginacion
                :tiene_paginacion="false"
              >
              <template v-slot:item.created_at="{ item }">
                {{ FormatDatePrt(item.created_at, true) }}
              </template>
                <template v-slot:item.descarga="{ item }">
                  <v-btn icon @click="descargarArchivoProceso({ ruta_documento: item.ruta })">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
              </data-table-component>
            </v-card-text>
          </v-card>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "CardProveedorIMPComponent",

  components: {
    DataTableComponent,
  },

  props: {
    proveedorProp: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    headersActas: [
      { text: "Estado", value: "estado.nombre", sortable: false, align: 'center' },
      { text: "Cargado por", value: "usuario.nombre_usuario", sortable: false, align: 'center' },
      { text: "Fecha de subida", value: "created_at", sortable: false, align: 'center' },
      { text: "Descarga", value: "descarga", sortable: false, align: 'center' },
    ],
  }),
  computed: {
    recursos() {
      return this.proveedorProp.recursos.map((recurso) => {
        return ({
          estado: recurso.EstadoImpugnacion,
          id: recurso.id,
          usuario: recurso.Usuario,
          ruta: recurso.doc_impugnacion,
          tipo: recurso.TipoImpugnacion,
          created_at: recurso.created_at,
          actas: recurso.actas.map((acta) => ({
            id: acta.id,
            ruta: acta.doc_impugnacion,
            created_at: acta.created_at,
            usuario: acta.Usuario,
            estado: acta.EstadoImpugnacion,
          })),
        })
      });
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
  },
};
</script>
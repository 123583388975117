<template>
    <Fragment>
        <span v-if="todasLasImpugnacionesHanSidoAtendidas && todasLasImpugnacionesHanRechazadas">
            <ContinuarProceso :id-proceso="idProcesoCompra" :solo-lectura="soloLectura" />
        </span>
        <div class="pb-4">
            <template v-if="todasLasImpugnacionesHanSidoAtendidas && todasLasImpugnacionesHanRechazadas">
                <h6 class="text-h6 text-uppercase font-weight-bold mb-2 mt-8">Recursos de apelación presentados</h6>
                <v-divider />
            </template>
            <DataTable 
                :headers="headers" 
                :items="apelaciones.data" 
                :tiene_paginacion="false"
                :show_loading="apelaciones.isLoading"
                dense
                no-gutters
                class="mt-4"
            >
                <template v-slot:[`item.estado`]="{ item }">
                    <v-chip
                    :color="getColor(item.EstadoImpugnacion?.id)"
                    style="display: block"
                    label
                >
                    {{ item?.EstadoImpugnacion?.nombre }}
                    </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon color="secondary" @click="atenderAprobacionDocumento(item)">
                    mdi-eye
                    </v-icon>
                </template>
            </DataTable>
            <ModalAprobacionRecursoApelacion 
                :is-open.sync="modalAprobacionAbierto" 
                :loading="cargandoDocumentoApelacion" 
                :source="documentoSrc"
                :solo-lectura="soloLectura"
                :id-proceso="idProcesoCompra"
                :apelacion="apelacionActiva"
                @on-cambio-estado="cargarApelaciones(true)"
            />
        </div>
    </Fragment>
</template>
<script>
import ContinuarProceso from '../../RecursosRevision/components/componentesUCP/ContinuarProceso.vue';
import ModalAprobacionRecursoApelacion from '../components/ModalAprobacionRecursoApelacion.vue';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import DataTable from "@/components/DataTableComponent.vue";
import { mapActions } from 'vuex';
import { Fragment } from 'vue-fragment';
import { Observable } from '@/utils/observable';

export default {
    name: 'AdmisionRecursoApelacion',
    components: { DataTable, ModalAprobacionRecursoApelacion, ContinuarProceso },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        apelaciones: createLoadable([]),
        apelacionActiva: null,
        headers: [
            { text: "Código", align: "center", value: "correlativo", sortable: false, width: "30%" },
            { text: "Proveedor", align: "center", value: "Usuario.Proveedor.nombre_comercial", sortable: false, width: "20%" },
            { text: "Fecha de creación", align: "center", value: "created_at", sortable: false, width: "20%" },
            { text: "Estado", align: "center", value: "estado", sortable: false, width: "20%" },
            { text: "Acciones", align: "center", value: "actions", sortable: false, width: "20%", sortable: false },
        ],
        modalAprobacionAbierto: false,
        // Visualizacion documento apelacion
        cargandoDocumentoApelacion: false,
        documentoSrc: null,
    }),
    computed: {
        soloLectura() {
            return this.haveRoles(['ROLE_TECDC_SOLO_LECTURA_RECURSO_REVISION']);
        },
        todasLasImpugnacionesHanSidoAtendidas() {
            if (this.apelaciones.data.length === 0) return false;
            
            return this.apelaciones.data.every((impugnacion) => impugnacion.id_estado_impugnacion !== 1);
        },
        todasLasImpugnacionesHanRechazadas() {
            return (
                this.todasLasImpugnacionesHanSidoAtendidas && 
                this.apelaciones.data.every((impugnacion) => [7].includes(impugnacion.id_estado_impugnacion))
            );
        },
    },
    methods: {
        ...mapActions("procesoCompraDoc", ["descargarBufferDocumento", "descargarArchivoProceso"]),
        ...mapActions("recursoApelacion", ["cargarEtapas", "cargarEtapaActual"]),
        // UI
        getColor(id_estado) {
            switch (id_estado) {
                case 1:
                return "#8EBCE1";
                case 6:
                return "green";
                case 7:
                return "red";
                default:
                return "grey";
            }
        },
        atenderAprobacionDocumento(apelacion) {
            this.apelacionActiva = apelacion;
            this.modalAprobacionAbierto = true;
            this.obtenerDocumentoApelacion(apelacion);
        },
        // Data
        async cargarApelaciones(validarApelaciones) {
            toggleLoadable(this.apelaciones);
            const { data } = await this.services.RecursoApelacion.cargarApelaciones(this.idProcesoCompra);
            setLoadableResponse(this.apelaciones, { data: Array.isArray(data) ? data : [] });

            if (!(data.length > 0)) return;

            const todasLasImpugnacionesHanSidoAtendidas = data.every((apelacion) => apelacion.id_estado_impugnacion !== 1);

            if (validarApelaciones && todasLasImpugnacionesHanSidoAtendidas) {
                Observable.emit('revalidar-pip-activo-recurso-apelacion');
            }
        },
        obtenerDocumentoApelacion(apelacion) {
            this.documentoSrc = null;
            this.cargandoDocumentoApelacion = true;

            this.services.PacProcesos.getDocumentoProcesoBuffer({ ruta: apelacion.doc_impugnacion }, false)
                .then((response) => {
                    if (!response.data) return;

                    const base64 = Buffer.from(response.data).toString("base64");
                    this.documentoSrc = `data:application/pdf;base64,${base64}`;
                }).finally(() => {
                    this.cargandoDocumentoApelacion = false;
                });
        },
    },
    created() {
        this.cargarApelaciones();
    },
};
</script>

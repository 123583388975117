<template>
    <div>
        <h6 class="text-h6 text-uppercase font-weight-bold mt-4">Siguiente etapa activa</h6>
        <v-divider class="mt-1" />
        <DataTableComponent 
            :tiene_paginacion="false"
            :items="etapa"
            :show_loading="siguienteEtapa.isLoading"
            :headers="headers"
            class="mt-4"
            dense
            no-gutters
        >
            <template v-slot:item.nombre="{ item }">
                {{ item.etapa_rec_ape.nombre }}
            </template>
            <template v-slot:item.fecha_inicio="{ item }">
                {{ formatDate(item.fecha_inicio) }}
            </template>
        </DataTableComponent>
    </div>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import { formatDate } from '@/utils/datetime';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';

export default {
    name: 'IndicadorSiguienteEtapa',
    components: { DataTableComponent },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        siguienteEtapa: createLoadable(null),
    }),
    computed: {
        headers() {
            return [
                { text: 'Etapa', value: 'nombre', align: 'center', sortable: false },
                { text: 'Fecha Inicio', value: 'fecha_inicio', align: 'center', sortable: false },
            ]
        },
        etapa() {
            return this.siguienteEtapa.data ? [this.siguienteEtapa.data] : [];
        },
    },
    methods: {
        formatDate,
        async cargarSiguienteEtapa() {
            toggleLoadable(this.siguienteEtapa);
            const { data } = await this.services.RecursoApelacion.cargarSiguienteEtapaRecursoApelacion(this.idProcesoCompra);
            setLoadableResponse(this.siguienteEtapa, data);
        },
    },
    created() {
        this.cargarSiguienteEtapa();
    },
}
</script>
<template>
    <Fragment>
        <v-row v-if="!soloLectura" class="mb-4">
            <v-col cols="12" md="4">
                <v-text-field 
                    v-model="nombreActaComision" 
                    :disabled="creacionRecomendacion.isLoading"
                    label="Nombre archivo *"
                    hide-details
                    outlined 
                />
            </v-col>
            <v-col cols="12" md="4">
                <v-file-input 
                    v-model="actaComisionFile" 
                    :disabled="creacionRecomendacion.isLoading"
                    prepend-icon="" 
                    accept="application/pdf"
                    prepend-inner-icon="mdi-paperclip" 
                    label="Recomendación de la Comisión de Alto Nivel"
                    truncate-length="15"
                    hide-details
                    outlined 
                />
            </v-col>
            <v-col cols="12" md="4">
                <v-btn
                    :disabled="!(nombreActaComision && actaComisionFile)"  
                    color="primary"
                    x-large
                    style="width: 100%"
                    @click.stop="confirmarCreacionRecomendacion"
                    :loading="creacionRecomendacion.isLoading"
                >
                    Agregar
                </v-btn>
            </v-col>
        </v-row>
        <DataTable 
            :show_loading="recomendaciones.isLoading"
            :items="recomendaciones.data"
            :headers="headers"
            :tiene_paginacion="false"
            dense
            no-gutters
        >
            <template v-slot:item.fecha_carga="{ item }">{{ formatDate(item.created_at) }}</template>
            <template v-slot:item.acciones="{ item }">
                <div class="d-flex align-center justify-center" style="gap: 16px;">
                    <v-icon @click.stop="visualizarDocumento(item)">mdi-eye</v-icon>
                    <v-icon v-if="!soloLectura" @click.stop="confirmarEliminacion(item)">mdi-trash-can</v-icon>
                </div>
            </template>
        </DataTable>

        <ConfirmationModalComponent 
            :isOpen="confirmacionCreacion" 
            description="¿Desea cargar el documento?"
            @confirm="crearRecomendacion" 
            @cancel="confirmacionCreacion = false"
            :isLoading="creacionRecomendacion.isLoading"
        />

        <!-- Visualizacion de documentos -->
        <PdfModal
            :isOpen="modalVisualizacionDocumento" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="cerrarModalVisualizacionDocumento"
            :filename="filename"
        />

        <ConfirmationModalComponent
            :is-open="confirmacionEliminacion"
            :is-loading="eliminacionRecomendacion.isLoading"
            description="¿Desea eliminar el documento?"
            @confirm="eliminarRecomendacion"
            @cancel="confirmacionEliminacion = false"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from "@/utils/loadable";
import { ConfirmationModalComponent } from "@/components/utils";
import DataTable from "@/components/DataTableComponent.vue";
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';
import { mapState, mapActions } from "vuex";
import { Fragment } from 'vue-fragment';
import { convertToFormData } from "@/utils/data";

export default {
    name: 'RecomendacionesComisionAltoNivel',
    components: { Fragment, DataTable, ConfirmationModalComponent, PdfModal },
    props: {
        idProcesoCompra: { type: [Number, String] },
        soloLectura: { type: Boolean },
    },
    data: () => ({
        recomendaciones: createLoadable([]),
        recomendacionActiva: null,
        // Tabla
        headers: [
            { text: "Nombre", value: "nombre_documento", align: "center", sortable: false, width: "30%" },
            { text: "Fecha de carga", value: "fecha_carga", align: "center", sortable: false, width: "30%" },
            { text: "Acciones", value: "acciones", align: "center", sortable: false, width: "30%" },
        ],
        // Formulario
        actaComisionFile: null,
        nombreActaComision: null,
        // Creacion
        confirmacionCreacion: false,
        creacionRecomendacion: createLoadable(null),
        // Visualizacion de documentos
        modalVisualizacionDocumento: false,
        visualizacionDocumento: createLoadable(null),
        // Eliminacion
        eliminacionRecomendacion: createLoadable(null),
        confirmacionEliminacion: false,
    }),
    computed: {
        ...mapState("procesoCompraDoc", ["procesoData"]),
        filename() {
            return this.recomendacionActiva?.nombre_documento ?? 'resolucion';
        },
    },
    methods: {
        ...mapActions("procesoCompraDoc", ["getDocumentosProcesoPorTipo"]),
        formatDate,
        async cargarRecomendaciones() {
            toggleLoadable(this.recomendaciones);
            const response = await this.getDocumentosProcesoPorTipo({
                id_proceso_compra: this.idProcesoCompra,
                id_tipo_documento: 15002,
                id_etapa_proceso: this.procesoData.id_etapa_pausa,
            });

            setLoadableResponse(this.recomendaciones, { data: response ?? [] });
        },
        // Creacion de recomendacion
        confirmarCreacionRecomendacion() {
            this.confirmacionCreacion = true;
        },
        async crearRecomendacion() {
            try {
                toggleLoadable(this.creacionRecomendacion);
    
                let body = {
                    documento: this.actaComisionFile,
                    nombre: this.nombreActaComision,
                    id_proceso: this.idProcesoCompra,
                    id_tipo_documento: 15002,
                    id_etapa_proceso: this.procesoData.id_etapa_pausa,
                    folder: 'generales',
                };
    
                const formData = convertToFormData(body);
    
                const response = await this.services.PacProcesos.agregarRecomendacionComisionAltoNivel(this.idProcesoCompra, formData);
        
                if (response.status === 200) {
                    setLoadableResponse(
                        this.creacionRecomendacion, 
                        { data: null, message: 'Documento cargado correctamente' }, 
                        { showAlertOnSuccess: true }
                    );
                    this.actaComisionFile = null;
                    this.nombreActaComision = null;
                    this.cargarRecomendaciones();
                }
            } finally {
                this.confirmacionCreacion = false;
                this.creacionRecomendacion.isLoading = false;
            }
        },
        // Visualizacion documentos
        async visualizarDocumento(recomendacion) {
            this.recomendacionActiva = recomendacion;
            this.modalVisualizacionDocumento = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.RecursoApelacion.visualizarDocumentoEtapaResolucion(recomendacion.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        cerrarModalVisualizacionDocumento(visible) {
            this.modalVisualizacionDocumento = visible;
        },
        // Eliminacion de documentos
        confirmarEliminacion(recomendacion) {
            this.recomendacionActiva = recomendacion;
            this.confirmacionEliminacion = true;
        },
        async eliminarRecomendacion() {
            toggleLoadable(this.eliminacionRecomendacion);
            const { data } = await this.services.PacProcesos.eliminarRecomendacionComisionAltoNivel(this.idProcesoCompra, this.recomendacionActiva.id);
            this.confirmacionEliminacion = false;
            setLoadableResponse(this.eliminacionRecomendacion, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarRecomendaciones();
        },
    },
    created() {
        this.cargarRecomendaciones();
    },
}
</script>
<template>
    <Fragment>
        <h6 class="text-h6 font-weight-bold text-uppercase mb-1">Recursos de apelación</h6>
        <v-divider />
        <DataTableComponent 
            :items="apelacionesAdmitidas.data"
            :show_loading="apelacionesAdmitidas.isLoading"
            :headers="apelacionesAdmitidasHeaders"
            :tiene_paginacion="false"
            class="mt-4"
            dense
            no-gutters
        >
            <template v-slot:item.fecha_presentacion="{ item }">{{ formatDate(item.created_at) }}</template>
            <template v-slot:item.fecha_admision="{ item }">{{ formatDate(item.SeguimientoImpugnacions?.[0]?.created_at) }}</template>
            <template v-slot:item.acciones="{ item }">
                <v-icon 
                    :loading="cargandoDocumentoApelacion" 
                    @click.stop="visualizarDocumentoApelacion(item)" 
                    icon
                >
                    mdi-eye
                </v-icon>
            </template>
        </DataTableComponent>
        
        <v-dialog v-model="modalVisualizacionAbierto" max-width="800px" persistent>
            <v-card class="pb-2">
                <div v-if="cargandoDocumentoApelacion" class="py-4 text-center">
                    <v-progress-circular indeterminate :size="60" />
                </div>
                <embed
                    v-if="documentoSrc"
                    width="100%"
                    height="700"
                    :src="documentoSrc + '#page=1%zoom=100'"
                />
                <v-card-actions v-if="!cargandoDocumentoApelacion">
                    <v-btn @click.stop="modalVisualizacionAbierto = false" color="secondary" outlined>
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </Fragment>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';

export default {
    name: 'RecursosApelacionAdmitidos',
    components: { Fragment, DataTableComponent },
    props: {
        idProcesoCompra: { type: [Number, String] }
    },
    data: () => ({
        // Apelaciones admitidas
        apelacionesAdmitidas: createLoadable([]),
        apelacionesAdmitidasHeaders: [
            { text: 'Código', value: 'correlativo', align: 'center', sortable: false },
            { text: 'Proveedor', value: 'Usuario.Proveedor.nombre_comercial', align: 'center', sortable: false },
            { text: 'Fecha de presentación', value: 'fecha_presentacion', align: 'center', sortable: false },
            { text: 'Fecha de admisión', value: 'fecha_admision', align: 'center', sortable: false },
            { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
        ],
        // Visualizacion documento apelacion
        modalVisualizacionAbierto: false,
        cargandoDocumentoApelacion: false,
        documentoSrc: null,
    }),
    methods: {
        // UI,
        formatDate,
        // Data
        async cargarApelaciones() {
            toggleLoadable(this.apelacionesAdmitidas);
            const { data } = await this.services.RecursoApelacion.cargarApelacionesAdmitidas(this.idProcesoCompra);
            setLoadableResponse(this.apelacionesAdmitidas, { data: data ?? [] });            
        },
        visualizarDocumentoApelacion(apelacion) {
            this.modalVisualizacionAbierto = true;
            this.documentoSrc = null;
            this.cargandoDocumentoApelacion = true;

            this.services.PacProcesos.getDocumentoProcesoBuffer({ ruta: apelacion.doc_impugnacion }, false)
                .then((response) => {
                    if (!response.data) return;

                    const base64 = Buffer.from(response.data).toString("base64");
                    this.documentoSrc = `data:application/pdf;base64,${base64}`;
                }).finally(() => {
                    this.cargandoDocumentoApelacion = false;
                });
        },
    },
    created() {
        this.cargarApelaciones();
    },
}
</script>
<template>
    <Fragment>
        <v-row v-if="!soloLectura" class="mb-4">
            <v-col cols="12" md="4">
                <v-text-field 
                    v-model="nombreNombramiento" 
                    label="Nombre archivo" 
                    outlined 
                    hide-details
                />
            </v-col>

            <v-col cols="12" md="4">
                <v-file-input 
                    v-model="documentoNombramiento" 
                    prepend-icon="" 
                    prepend-inner-icon="mdi-paperclip"
                    accept="application/pdf" 
                    label="Archivo adjunto" 
                    truncate-length="15" 
                    outlined 
                    hide-details
                />
            </v-col>

            <v-col cols="12" md="4">
              <v-btn 
                    :disabled="!(nombreNombramiento && documentoNombramiento)" 
                    x-large
                    color="primary"
                    class="flex-shrink-1 flex-grow-1"
                    style="width: 100%;"
                    @click="confirmacionCreacion = true"
                >
                    Agregar
                </v-btn>
            </v-col>
        </v-row>
        <DataTable 
            :show_loading="documentosNombramientos.isLoading"
            :items="documentosNombramientos.data"
            :headers="headers"
            :tiene_paginacion="false"
            dense
            no-gutters
            class="mt-4"
        >
            <template v-slot:item.fecha_carga="{ item }">{{ formatDate(item.created_at) }}</template>
            <template v-slot:item.acciones="{ item }">
                <div class="d-flex align-center justify-center" style="gap: 16px;">
                    <v-icon @click.stop="visualizarDocumento(item)">mdi-eye</v-icon>
                    <v-icon v-if="!soloLectura" @click.stop="confirmarEliminacion(item)">mdi-trash-can</v-icon>
                </div>
            </template>    
        </DataTable>

        <ConfirmationModalComponent
            :isOpen="confirmacionCreacion" 
            description="¿Desea cargar el documento de nombramiento?"
            @confirm="guardarNombramiento" 
            @cancel="confirmacionCreacion = false"
            :isLoading="cargaNombramiento.isLoading"
        />

        <ConfirmationModalComponent
            :is-open="confirmacionEliminado"
            :is-loading="eliminadoNombramiento.isLoading"
            description="¿Desea eliminar el documento?"
            @confirm="eliminarNombramiento"
            @cancel="confirmacionEliminado = false"
        />

        <!-- Visualizacion de documentos -->
        <PdfModal
            :isOpen="modalVisualizacionDocumento" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="cerrarModalVisualizacionDocumento"
            :filename="filename"
        />
    </Fragment>
</template>
<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import DataTable from "@/components/DataTableComponent.vue";
import { Fragment } from 'vue-fragment';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import { mapActions, mapState } from "vuex";
import { convertToFormData } from "@/utils/data";
import PdfModal from "@/components/PdfModal.vue";
import { formatDate } from "@/utils/datetime";

export default {
    name: 'DocumentoNombramiento',
    components: {
        DataTable,
        Fragment,
        ConfirmationModalComponent,
        PdfModal,
    },
    props: {
        idProcesoCompra: { type: [Number, String] },
        soloLectura: { type: Boolean },
    },
    data: () => ({
        // Formulario
        nombreNombramiento: null,
        documentoNombramiento: null,
        // UI
        confirmacionCreacion: false,
        confirmacionEliminado: false,
        documentoActivo: null,
        modalVisualizacionDocumento: false,
        // Data
        documentosNombramientos: createLoadable([]),
        cargaNombramiento: createLoadable(null),
        visualizacionDocumento: createLoadable(null),
        eliminadoNombramiento: createLoadable(null),
    }),
    computed: {
        ...mapState("procesoCompraDoc", ["procesoData"]),
        filename() {
            return this.documentoActivo?.nombre_documento ?? 'nombramiento';
        },
        headers() {
            return [
                { text: "Nombre", value: "nombre_documento", align: "center", sortable: false, width: "30%" },
                { text: "Fecha de carga", value: "fecha_carga", align: "center", sortable: false, width: "30%" },
                { text: "Acciones", value: "acciones", align: "center", sortable: false, width: "30%" },
            ]
        },
    },
    methods: {
        formatDate,
        ...mapActions("procesoCompraDoc", ["getDocumentosProcesoPorTipo"]),
        async cargarDocumentosNombramientos() {
            toggleLoadable(this.documentosNombramientos);

            const response = await this.getDocumentosProcesoPorTipo({
                id_proceso_compra: this.idProcesoCompra,
                id_tipo_documento: 15008,
                id_etapa_proceso: this.procesoData.id_etapa_pausa,
            });

            setLoadableResponse(this.documentosNombramientos, { data: response ?? [] });
        },
        async guardarNombramiento() {
            try {
                toggleLoadable(this.cargaNombramiento);
                    
                let body = {
                    documento: this.documentoNombramiento,
                    nombre: this.nombreNombramiento,
                    id_proceso: this.idProcesoCompra,
                    id_tipo_documento: 15008,
                    id_etapa_proceso: this.procesoData.id_etapa_pausa,
                    folder: 'generales',
                };

                const formData = convertToFormData(body);
                const response = await this.services.PacProcesos.agregarDocumentoNombramiento(this.idProcesoCompra, formData);
                

                if (response.status === 200) {
                    setLoadableResponse(
                        this.cargaNombramiento, 
                        { data: null, message: 'Documento cargado correctamente' }, 
                        { showAlertOnSuccess: true }
                    );

                    this.documentoNombramiento = null;
                    this.nombreNombramiento = null;
                    this.cargarDocumentosNombramientos();
                }
            } finally {
                this.confirmacionCreacion = false;
                this.cargaNombramiento.isLoading = false;
            }
        },
        // Eliminacion de documentos
        confirmarEliminacion(documento) {
            this.documentoActivo = { ...documento };
            this.confirmacionEliminado = true;
        },
        async eliminarNombramiento() {
            toggleLoadable(this.eliminadoNombramiento);
            const { data } = await this.services.PacProcesos.eliminarDocumentoNombramiento(this.idProcesoCompra, this.documentoActivo.id);
            this.confirmacionEliminado = false;
            setLoadableResponse(this.eliminadoNombramiento, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarDocumentosNombramientos();
        },
        // Ver documentos
        async visualizarDocumento(documento) {
            this.documentoActivo = { ...documento };
            this.modalVisualizacionDocumento = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.RecursoApelacion.visualizarDocumentoEtapaResolucion(documento.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        cerrarModalVisualizacionDocumento(visible) {
            this.modalVisualizacionDocumento = visible;
        },
    },
    created() {
        this.cargarDocumentosNombramientos();
    },
}
</script>
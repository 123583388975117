<template>
    <Fragment>
        <v-dialog v-if="!confirmacionAbierta" v-model="isOpen" max-width="800px" persistent>
            <v-card>
                <v-card-title class="px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-4 py-4">
                    <v-form ref="form" @submit.prevent="confirmarCargaPronunciamiento">
                        <v-select 
                            v-if="!esProveedor"
                            v-model="form.id_proveedor"
                            :items="proveedoresOferentes.data"
                            :loading="proveedoresOferentes.isLoading"
                            placeholder="Proveedor"
                            label="Proveedor"
                            :error-messages="id_proveedorErrors"
                            @change="$v.form.id_proveedor.$touch()"
                            @blur="$v.form.id_proveedor.$touch()"
                            item-text="nombre_comercial"
                            item-value="id"
                            outlined
                        />
    
                        <v-file-input
                            v-model="form.documento"
                            label="Documento"
                            placeholder="Documento"
                            outlined
                            light
                            :error-messages="documentoErrors"
                            @blur="$v.form.documento.$touch()"
                            accept="application/pdf"
                        />
                        
                        <div class="d-flex justify-center align-center flex-wrap" style="gap: 16px;">
                            <v-btn @click.stop="cerrarModal" color="secondary" class="flex-grow-1" large>Cerrar</v-btn>
                            <v-btn type="submit" color="primary" class="flex-grow-1" large>Guardar</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent 
            :is-open="confirmacionAbierta"
            description="¿Desea cargar el documento?"
            :is-loading="guardadoPronunciamiento.isLoading"
            @cancel="confirmacionAbierta = false"
            @confirm="cargarPronunciamiento"
        />
    </Fragment>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { required } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';
import { convertToFormData } from '@/utils/data';
import { Fragment } from 'vue-fragment';
import { mapState } from "vuex";

export default {
    name: 'ModalCargarPronunciamientoAdjudicado',
    components: { Fragment, ConfirmationModalComponent },
    emits: ['on-save'],
    validations() {
        return ({
            form: {
                ...!this.esProveedor && ({ id_proveedor: { required } }),
                documento: { required },
            },
        });
    },
    props: {
        isOpen: { type: Boolean },
        idProceso: { type: [Number, String] },
    },
    data() {
        return ({
            form: {
                ...!this.esProveedor && { id_proveedor: null },
                documento: null,
            },
            confirmacionAbierta: false,
            proveedoresOferentes: createLoadable([]),
            guardado: false,
            guardadoPronunciamiento: createLoadable(null),
        });
    },
    computed: {
        ...mapState("procesoCompraDoc", ["procesoData"]),
        esProveedor() {
            return this.haveRoles(['ROLE_PROVEEDOR']);
        },
        // Form
        id_proveedorErrors() {
            if (this.esProveedor) return [];

            return new Validator(this.$v.form.id_proveedor).detect().getResult();
        },
        documentoErrors() {
            return new Validator(this.$v.form.documento).detect().getResult();
        },
    },
    methods: {
        limpiarCampos() {
            this.$v.form.$reset();
            const keysForm = Object.keys(this.form);
            const estadoLimpio = {};
            keysForm.forEach((key) => estadoLimpio[key] = null);
            this.form = estadoLimpio;
        },
        cerrarModal() {
            this.limpiarCampos();
            this.confirmacionAbierta = false;
            this.$emit('update:is-open', false);
        },
        async cargarProveedoresOferentes() {
            toggleLoadable(this.proveedoresOferentes);
            const { data } = await this.services.RecursoRevision.obtenerProveedoresOferentes(this.idProceso);
            setLoadableResponse(this.proveedoresOferentes, data);
        },
        confirmarCargaPronunciamiento() {
            this.$v.form.$touch();

            if (this.$v.form.$invalid) return;

            this.confirmacionAbierta = true;
        },
        async cargarPronunciamiento() {
            const payload = { ...this.form };
            const formData = convertToFormData(payload);
            
            toggleLoadable(this.guardadoPronunciamiento);
            const { data } = await this.services.PacProcesos.guardarPronunciamientoImpugnacion(this.idProceso, formData);
            this.confirmacionAbierta = false;
            setLoadableResponse(this.guardadoPronunciamiento, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (!isResponseSuccesful(data)) return;

            this.cerrarModal();
            this.$emit('on-save');
        },
    },
    created() {
        if (!this.esProveedor) this.cargarProveedoresOferentes();
    }
}
</script>
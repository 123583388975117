<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="600" persistent>
            <v-card>
              <v-card-title class="align-center px-4 py-4" style="gap: 8px;">
                <v-icon @click.stop="cerrarModal">mdi-close</v-icon>
                <p class="secondary--text text-h6 text-uppercase font-weight-medium mb-0">Agregar participante</p>
              </v-card-title>
              <v-divider />
              <v-card-text class="px-4 pt-4 pb-0">
                <v-select 
                    v-model="formulario.tipo_documento"
                    :items="tiposDePersona" 
                    label="Tipo de documento*" 
                    outlined 
                    item-text="nombre" 
                    item-value="id"
                    hide-details
                    return-object 
                />
    
                <v-text-field 
                    v-model="formulario.numero_documento" 
                    outlined 
                    v-mask="formulario.tipo_documento?.formato"
                    class="mt-4 input-with-append"
                    label="Número de documento *" 
                    :placeholder="formulario.tipo_documento?.formato"
                    hide-details
                >
                    <template v-if="formulario.tipo_documento && formulario.numero_documento" v-slot:append-outer>
                    <v-btn 
                        dark 
                        color="secondary"
                        @click="consultarUsuario"
                    >
                        Buscar
                    </v-btn>
                    </template>
                </v-text-field> 
      
                <template v-if="busquedaActiva">
                  <div class="mt-8 d-flex align-center" style="gap: 4px;">
                    <v-icon style="transform: translateY(-3px)">mdi-account-search</v-icon>
                    <h6 class="text-subtitle-2 text-uppercase font-weight-bold">Resultado de la búsqueda</h6>
                  </div>
                  <v-divider class="mt-1" />
      
                  <EmptyComponent :empty="!usuario" text="Usuario no encontrado">
                    <dl v-if="usuario">
                        <dt class="font-weight-bold text-body-2 mt-3">Nombre</dt>
                        <dd class="text-body-2">{{ usuario.empleado }}</dd>
    
                        <dt class="font-weight-bold text-body-2 mt-3">Número de documento</dt>
                        <dd class="text-body-2">{{ usuario.documento }}</dd>
                    </dl>
                    <template v-slot:icon>
                      <v-icon size="40" color="primary">mdi-account-question</v-icon>
                    </template>
                  </EmptyComponent>
                </template>
              </v-card-text>
              <v-card-actions class="flex-wrap px-4 py-4">
                <v-btn 
                  color="secondary" 
                  class="flex-grow-1 flex-shrink-1"
                  outlined 
                  large
                  @click="cerrarModal"
                >
                  Cancelar
                </v-btn>
                <v-btn 
                  class="flex-grow-1 flex-shrink-1"
                  :disabled="!busquedaActiva || !usuario" 
                  color="primary" 
                  large
                  @click="confirmarCreacionParticipacion"
                >
                  Seleccionar
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent
            :isOpen="confirmacionAbierta" 
            description="¿Desea agregar un nuevo participante a la comisión de alto nivel?" 
            @confirm="crearParticipanteComisionAltoNivel" 
            @cancel="confirmacionAbierta = false"  
            :isLoading="creandoParticipacion"
        />
    </Fragment>
</template>
<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import EmptyComponent from "@/components/utils/EmptyComponent.vue";
import { convertToFormData } from "@/utils/data";
import { Fragment } from "vue-fragment";

const estadoInicialFormulario = {
    tipo_documento: null,
    numero_documento: null,
}

export default {
    name: 'ModalAgregarComisionAltoNivel',
    emits: ['on-save'],
    components: { EmptyComponent, Fragment, ConfirmationModalComponent },
    props: {
        isOpen: { type: Boolean },
        idProceso: { type: [Number, String] },
        idEtapaProceso: { type: [Number, String] },
    },
    data: () => ({
        // FORM
        formulario: { ...estadoInicialFormulario },
        // DATA
        usuario: null,
        busquedaActiva: false,
        tiposDePersona: [],
        // UI
        confirmacionAbierta: false,
        creandoParticipacion: false,
        payload: null,
    }),
    methods: {
        // UI
        limpiarEstado() {
            this.formulario = { ...estadoInicialFormulario };
            this.busquedaActiva = false;
        },
        cerrarModal() {
            this.$emit('update:is-open', false);
            this.limpiarEstado();
        },
        confirmarCreacionParticipacion() {
            this.payload = { usuario: this.usuario  };
            this.cerrarModal();
            this.confirmacionAbierta = true;
        },
        // DATA
        async crearParticipanteComisionAltoNivel() {    
            try {
                this.creandoParticipacion = true;
                const { usuario } = this.payload;

                const data = {
                    id_usuario: usuario.id_usuario, 
                    id_etapa_proceso: this.idEtapaProceso,
                };

                const formData = convertToFormData(data);


                const { status } = await this.services.RecursoRevision.postParticipacionComision(this.idProceso, formData);
    
                if (status == 200) {
                    this.pushAppMessage({ message: "Participante agregado con éxito", type: "success" });
    
                    this.$emit('on-save');
                }
            } finally {
                this.confirmacionAbierta = false;
                this.creandoParticipacion = false;
            }
        },
        async consultarUsuario() {
            try {
                this.usuario = null;
                
                const response = await this.services.Usuarios.getUsuarios({
                    numero_documento: this.formulario.numero_documento,
                    id_tipo_documento: this.formulario.tipo_documento.id,
                    crear_usuario: true,
                });
    
                if (response.status === 200 && response.data?.length > 0 && response.data[0]?.id_usuario) {
                    this.usuario = response.data[0];
                }
            } finally {
                this.busquedaActiva = true;
            }
        },
        async obtenerTiposPersonas() {
            const response = await this.services.IdentificadorFiscal.getIdentificadorFiscal()

            this.tiposDePersona = response.data.filter((tipo) => [1, 20].includes(tipo.id_tipo_documento));
        },
    },
    watch: {
        'formulario.tipo_documento'() {
            this.formulario.numero_documento = null;
        }
    },
    created() {
        if (!this.haveRoles(['ROLE_TACOP'])) this.obtenerTiposPersonas();
    },
}
</script>
<style scoped>
:deep(.v-input.input-with-append) {
  align-items: center;
}

:deep(.v-input.input-with-append .v-input__append-outer) {
  margin: 0 8px !important;
}
</style>

<template>
    <section>
        <SpinnerComponent v-if="verificacionPIPActivo.isLoading" spinning text="Cargando información..." />
        <template v-else>
            <template v-if="esAuditor">
                <v-tabs v-model="id" background-color="bgMinsal" show-arrows>
                    <v-tab
                        v-for="tab in tabs"
                        :key="tab.id"
                        class="secondary--text"
                        style="text-transform: none !important"
                        v-if="!tab.hide"
                    >
                        <v-icon>{{ tab.icon }}</v-icon>
                        <span class="pl-1">{{ tab.tab }}</span>
                    </v-tab>
                </v-tabs>
    
                <v-tabs-items v-model="id" class="mt-8">
                    <v-tab-item
                        v-for="tab in tabs"
                        :key="tab.id"
                        v-if="!tab.hide"
                    >
                        <component
                            :is="tab.content"
                            :id-proceso-compra="procesoData?.id"
                        ></component>
                    </v-tab-item>
                </v-tabs-items>
            </template>
            <EtapaActualRecursoApelacion 
                v-else
                :id-proceso-compra="procesoData?.id"
            />
        </template>
    </section>
</template>
<script>
import EtapaActualRecursoApelacion from "./EtapaActualRecursoApelacion.vue";
import EtapaActualComponent from "../components/EtapaActualComponent.vue";
import PIPRecursoApelacion from "./PIPRecursoApelacion.vue";
import PIPComponent from "../components/PIPComponent.vue";
import { SpinnerComponent } from '@/components/utils';
import { mapState } from "vuex";
import MainAuditoriaComponent from "../AuditoriaProcesos/MainAuditoriaComponent.vue";

export default {
    name: 'RecursoApelacion',
    components: { 
        PIPComponent, 
        PIPRecursoApelacion, 
        EtapaActualComponent, 
        EtapaActualRecursoApelacion, 
        SpinnerComponent,
        MainAuditoriaComponent,
    },
    props: {
        getProceso: { type: Function },
    },
    data: () => ({
        id: null,
    }),
    computed: {
        ...mapState("procesoCompraDoc", ["procesoData"]),
        ...mapState("recursoApelacion", ["verificacionPIPActivo"]),
        tabs() {
            return [
                {
                    id: 1,
                    tab: "Etapa Actual",
                    icon: "mdi-clock-outline",
                    content: EtapaActualRecursoApelacion,
                },
                {
                    id: 3,
                    tab: "Auditoría del proceso",
                    icon: "mdi-package-variant",
                    content: MainAuditoriaComponent,
                    hide: !this.haveRoles(["ROLE_SUPER_ADMIN", "ROLE_AUDITORIA_PROCESO"]),
                }
            ];
        },
        esProveedor() {
            return this.haveRoles(['ROLE_PROVEEDOR']);
        },
        esTacop() {
            return this.haveRoles(['ROLE_TACOP']);
        },
        esAuditor() {
            return this.haveRoles(["ROLE_SUPER_ADMIN", "ROLE_AUDITORIA_PROCESO"]);
        },
    },
}
</script>
<template>
    <Fragment>
        <section class="ma-8"  style="padding-bottom: 400px;">
            <DataTableComponent 
                :headers="headersFiltered"
                :items="etapasPIP"
                :show_loading="etapas.isLoading"
                :tiene_paginacion="false"
            >
                <template v-slot:item.periodo="{ item }">
                    {{ formatDate(item.fecha_inicio, 'DD/MM/YYYY HH:mm:ss') }}
                    -
                    {{ formatDate(item.fecha_fin, 'DD/MM/YYYY HH:mm:ss') }}
                    <b>({{ item.duracion_en_dias }} dia{{ item.duracion_en_dias > 1 ? 's' : ''}})</b>
                </template>
                <template v-slot:item.cambio_fecha="{ item }">
                    <div 
                    v-if="verificarFechaFinEsMenorQueHoy(item)" 
                    class="text-subtitle-1 font-weight-bold text-uppercase py-4 d-flex justify-center align-center"
                    style="gap: 8px;"
                    >
                    <v-icon style="font-size: 26px !important;">mdi-calendar-lock</v-icon>
                    <span>Etapa finalizada</span>
                    </div>
                    <div v-else class="d-flex align-center justify-center flex-wrap py-4 cambio-fecha-wrapper" style="gap: 16px;">
                        <vc-date-picker
                            v-model="item._fecha_inicio_state"
                            mode="dateTime"
                            locale="es"
                            :popover="config_calendar"
                            :min-date="hoy?.toDate()"
                            :disabled-dates="fechasDeshabilitadas"
                            :valid-hours="obtenerHorasValidasInicio(item)"
                            @input="(fecha) => manejarCambioFechaInicio(fecha, item)"
                        >
                            <template v-slot="{ inputEvents, hidePopover, showPopover }">
                            <span v-on="inputEvents" @click.stop="showPopover()">
                                <v-text-field
                                class="border roundend focus:outline-none focus:border-blue-300"
                                :value="formatearFecha(item._fecha_inicio_state)"
                                @blur="hidePopover()"
                                readonly
                                hide-details
                                outlined
                                />
                            </span>
                            </template>
                        </vc-date-picker>
            
                        <vc-date-picker
                            v-model="item._fecha_fin_state"
                            mode="dateTime"
                            locale="es"
                            :popover="config_calendar"
                            :min-date="obtenerFechaMinimaCierreEtapa(item)"
                            :max-date="obtenerFechaMaximaEtapa(item)"
                            :disabled-dates="fechasDeshabilitadas"
                            :valid-hours="obtenerHorasValidasCierre(item)"
                        >
                            <template v-slot="{ inputEvents, hidePopover, showPopover }">
                            <span v-on="inputEvents" @click.stop="showPopover()">
                                <v-text-field
                                class="border roundend focus:outline-none focus:border-blue-300"
                                :value="formatearFecha(item._fecha_fin_state)"
                                :disabled="!item._fecha_inicio_state"
                                @blur="hidePopover()"
                                outlined
                                readonly
                                hide-details
                                />
                            </span>
                            </template>
                        </vc-date-picker>
            
                        <v-btn 
                            @click.stop="confirmarGuardadoFechas(item)" 
                            :disabled="!(item._fecha_inicio_state && item._fecha_fin_state)"
                            color="primary"
                            x-large
                            >
                            Actualizar
                            <v-icon color="white" class="ml-2" style="font-size: 22px !important;">mdi-send</v-icon>
                        </v-btn>
                    </div>
                </template>
            </DataTableComponent>
            <ConfirmationModalComponent
                :is-open="confirmacionCambioEtapa"
                description="¿Desea cambiar las fechas para la etapa seleccionada?"
                :is-loading="actualizacionEtapa.isLoading"
                @confirm="cambiarFechasEtapa"
                @cancel="confirmacionCambioEtapa = false"
            />
        </section>
    </Fragment>
</template>
<script>
import { obtenerNumeroDias, obtenerFechasPermitidas, generarHoras } from "@/utils/dias-habiles";
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from "@/components/utils";
import { formatDate } from '@/utils/datetime';
import { mapActions, mapState } from "vuex";
import { Fragment } from 'vue-fragment';
import moment from "moment";
import { ValidacionAccesos } from "@/utils/role-utils";

export default {
    name: 'PIPRecursoApelacion',
    components: { Fragment, DataTableComponent, ConfirmationModalComponent },
    props: {
        idProcesoCompra: { type: [Number, String], required: true },
    },
    data: () => ({
        etapasPIP: [],
        // UI
        headers: [
            { text: "Etapa", value: "etapa_rec_ape.nombre", align: "center", sortable: false },
            { text: 'Período', value: 'periodo', align: 'center', sortable: false },
            { text: "Máximo días hábiles", value: "etapa_rec_ape.cantidad_dias", align: "center", sortable: false },
            { text: "Cambiar fecha", value: "cambio_fecha", align: "center", sortable: false },
        ],
        config_calendar: {
            placement: "bottom",
            visibility: 'focus',
        },
        // Cambio etapa
        etapaActiva: null,
        confirmacionCambioEtapa: false,
    }),
    computed: {
        ...mapState(["asuetos", "feriados", "serverTime"]),
        ...mapState('recursoApelacion', ["etapas", "actualizacionEtapa"]),
        esProveedor() {
            return ValidacionAccesos.ES_PROVEEDOR;
        },
        esUCP() {
            return ValidacionAccesos.ES_UCP;
        },
        esAlMenosUCP() {
            return ValidacionAccesos.ES_AL_MENOS_UCP;
        },
        etapasCargadas() {
           return this.etapas.data;
        },
        headersFiltered() {
            return this.esAlMenosUCP
                ? this.headers
                : this.headers.filter((target) => target.value !== "cambio_fecha");
        },
        fechasDeshabilitadas() {
            const diasFeriados =  this.feriados.map((feriado) => feriado.toDate());

            const diasAsueto = [];
            const anios = 2;
            let anioActual = new Date().getFullYear();

            for (let i = 0; i < anios; i++) {
                this.asuetos.forEach((asueto) => {
                    diasAsueto.push(new Date(anioActual, asueto.mes - 1, asueto.dia));
                });
                anioActual++;
            }

            return [{ weekdays: [1, 7] }].concat(diasFeriados).concat(diasAsueto);
        },
        hoy() {
            return this.serverTime.clone();
        },
    },
    methods: {
        ...mapActions(["cargarDiasNoLaborables"]),
        ...mapActions('recursoApelacion', ["cargarEtapas", "actualizarEtapa", "cargarEtapaActual"]),
        formatDate,
        // Validaciones
        formatearFecha(fecha) {
            return fecha
                ? moment(fecha).format("DD/MM/YYYY hh:mm A")
                : "Seleccione una fecha";
        },
        manejarCambioFechaInicio(fecha, item) {
            if (!fecha) return;

            if (moment(fecha).isAfter(moment(item._fecha_fin_state))) {
                item._fecha_fin_state = null;
            }
        },
        obtenerFechaMaximaEtapa(etapa) {
            const fechaInicio = etapa._fecha_inicio_state;
            if (!fechaInicio) return null;

            const fechasPermitidas = obtenerFechasPermitidas(moment(fechaInicio).startOf('day'), etapa.etapa_rec_ape.cantidad_dias);

            return fechasPermitidas.length > 0 ? fechasPermitidas.pop().endOf('day').toDate() : null;
        },
        obtenerHorasValidasInicio(etapa) {
            if (!etapa._fecha_inicio_state) return [];

            const fechaInicio = moment(etapa._fecha_inicio_state).clone().startOf('day');
            
            if (this.hoy.clone().startOf('day').isSame(fechaInicio)) {
                return generarHoras(this.hoy.get('hour'));
            }

            return generarHoras(0);
        },
        obtenerHorasValidasCierre(etapa) {
            if (!etapa._fecha_fin_state) return [];

            const fechaInicio = moment(etapa._fecha_inicio_state);
            const fechaFin = moment(etapa._fecha_fin_state);
            
            if (fechaInicio.clone().startOf('day').isSame(fechaFin.clone().startOf('day'))) {
                return generarHoras(fechaInicio.get('hour') + 1);
            }

            return generarHoras(0);
        },
        obtenerFechaMinimaCierreEtapa(etapa) {
            if (!etapa._fecha_inicio_state) return null;

            const fechaInicio = moment(etapa._fecha_inicio_state);

            return fechaInicio.get('hour') < 23 ? etapa._fecha_inicio_state : fechaInicio.clone().add(1, 'day').startOf('day').toDate();
        },
        verificarFechaFinEsMenorQueHoy(etapa) {
            const fechaFin = moment(etapa.fecha_fin);
            return fechaFin.isBefore(this.hoy);
        },
        // Data
        confirmarGuardadoFechas(etapa) {
            // Validaciones
            const fechaInicioEtapa = moment(etapa._fecha_inicio_state);
            const fechaFinEtapa = moment(etapa._fecha_fin_state);

            const diasEnRango = obtenerNumeroDias(fechaInicioEtapa, fechaFinEtapa);
            const diasLimite = etapa.etapa_rec_ape.cantidad_dias;

            if (diasEnRango > diasLimite) {
                this.pushAppMessage({ type: 'warning', message: `El máximo de días hábiles para esta etapa es de ${diasLimite} días.` });
                return;
            }

            // Abrir modal
            this.etapaActiva = etapa;
            this.confirmacionCambioEtapa = true;
        },
        formatearFechaEnInicioDeMinuto(fecha) {
            return moment(fecha).startOf('minute').format("YYYY-MM-DD HH:mm:ss")
        },
        async cambiarFechasEtapa() {
            if (moment(this.etapaActiva._fecha_inicio_state).startOf('minute').isBefore(this.hoy.clone().startOf('minute'))) {
                const message = `El inicio de la etapa no debe ser menor a la fecha/hora actual ${this.hoy.format('DD/MM/YYYY HH:mm')}`;
                this.pushAppMessage({ type: "error", message });
                return;
            }

            const cambioExitoso = await this.actualizarEtapa({
                id_proceso_compra: this.idProcesoCompra,
                id_etapa_recurso_apelacion: this.etapaActiva.id,
                data: {
                    fecha_inicio: this.formatearFechaEnInicioDeMinuto(this.etapaActiva._fecha_inicio_state),
                    fecha_fin: this.formatearFechaEnInicioDeMinuto(this.etapaActiva._fecha_fin_state),
                },
            });

            this.confirmacionCambioEtapa = false;
            if (!cambioExitoso) return;

            this.cargarEtapas(this.idProcesoCompra);
            this.cargarEtapaActual(this.idProcesoCompra);
        },
    },
    watch: {
        etapasCargadas: {
            handler(value) {
                const etapasConEstadoLocal =  value.map((etapa) => ({
                    ...etapa,
                    _fecha_inicio_state: null,
                    _fecha_fin_state: null,
                }));

                this.etapasPIP = etapasConEstadoLocal;
            },
            deep: true,
        },
    },
    created() {
        this.cargarDiasNoLaborables();
        this.cargarEtapas(this.idProcesoCompra);
    },
}
</script>
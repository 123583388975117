<template>
  <section>
    <v-card>
      <v-row class="align-center mt-4">
        <v-col cols="12" sm="12" md="2" lg="3" class="text-center">
          <v-btn
            @click="descargarDocumento(procesoData?.expediente)"
            icon
            :disabled="!procesoData?.expediente"
          >
            <v-icon color="secondary" size="40"> mdi-download </v-icon>
          </v-btn>
          <p class="secondary--text" v-if="!procesoData?.expediente">
            Aún no se ha cargado un expediente del proceso
          </p>
          <p class="secondary--text" v-else>Expediente de proceso</p>
        </v-col>
        <v-col cols="12" sm="12" md="1" lg="1" class="text-center">
          <v-divider vertical></v-divider>
        </v-col>
        <v-col cols="12" sm="12" md="9" lg="8">
          <v-row class="align-center">
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row no-gutters align="center" class="mb-2">
                <v-col>
                  <span class="secondary--text font-weight-bold">Estado:</span>
                </v-col>
                <v-col>
                  <v-chip
                    :color="`${
                      procesoData?.estado === 'Activo' ? 'success' : 'secondary'
                    } white--text d-block text-center`"
                    >{{ procesoData?.estado }}</v-chip
                  >
                </v-col>
              </v-row>
              <v-row no-gutters align="center" class="mb-2">
                <v-col>
                  <span class="secondary--text font-weight-bold"
                    >Fecha de creación:</span
                  >
                </v-col>
                <v-col>
                  <span class="secondary--text">{{
                    moment(procesoData?.fecha_creacion).isValid()
                      ? moment(procesoData?.fecha_creacion).format(
                          "DD/MM/YYYY HH:mm a"
                        )
                      : "Fecha no disponible"
                  }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" class="mb-2">
                <v-col>
                  <span class="secondary--text font-weight-bold"
                    >Fecha de publicación:</span
                  >
                </v-col>
                <v-col>
                  <span class="secondary--text">{{
                    moment(procesoData?.fecha_publicacion).isValid()
                      ? moment(procesoData?.fecha_publicacion).format(
                          "DD/MM/YYYY HH:mm a"
                        )
                      : "Fecha no disponible"
                  }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" class="mb-2">
                <v-col>
                  <span class="secondary--text font-weight-bold"
                    >Fecha de contratación:</span
                  >
                </v-col>
                <v-col>
                  <span class="secondary--text">{{
                    moment(procesoData?.fecha_contratacion).isValid()
                      ? moment(procesoData?.fecha_contratacion).format(
                          "DD/MM/YYYY"
                        )
                      : "Fecha no disponible"
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row no-gutters align="center" class="mb-2">
                <v-col>
                  <span class="secondary--text font-weight-bold"
                    >Modalidad de compra:</span
                  >
                </v-col>
                <v-col>
                  <span class="secondary--text">{{
                    procesoData?.modalidad_compra
                  }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" class="mb-2">
                <v-col>
                  <span class="secondary--text font-weight-bold"
                    >Técnico asignado:</span
                  >
                </v-col>
                <v-col>
                  <span class="secondary--text">{{
                    procesoData?.tecnico_asignado?.nombre
                  }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" class="mb-2">
                <v-col>
                  <span class="secondary--text font-weight-bold"
                    >Mes/año inicio del proceso:</span
                  >
                </v-col>
                <v-col>
                  <span class="secondary--text">{{
                    moment(procesoData?.fecha_inicio_proceso).format(
                      "MM/YYYY"
                    ) || "Fecha no disponible"
                  }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" class="mb-2">
                <v-col>
                  <span class="secondary--text font-weight-bold"
                    >Mes/año posible contratación:</span
                  >
                </v-col>
                <v-col>
                  <span class="secondary--text">{{
                    moment(procesoData?.fecha_fin_proceso).format("MM/YYYY") ||
                    "Fecha no disponible"
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center" class="pb-4">
        <v-btn
          @click="descargarDocumento(procesoData?.desierto)"
          :disabled="!procesoData?.desierto"
          class="mx-2 my-2 my-md-0"
          color="secondary"
          outlined
        >
          Docs - desierto
          {{
            procesoData?.desierto
              ? `(${moment(procesoData?.updated_at).format(
                  "YYYY-MM-DD HH:mm:ss"
                )})`
              : ""
          }}
        </v-btn>
        <v-btn
          :disabled="procesoData?.suspendido.length == 0"
          class="mx-2 my-2 my-md-0"
          color="secondary"
          outlined
          @click="showHistorial('suspendido')"
        >
          Docs - suspendido ({{ procesoData?.suspendido.length }})
        </v-btn>
        <v-btn
          :disabled="procesoData?.reanudado.length == 0"
          class="mx-2 my-2 my-md-0"
          color="secondary"
          outlined
          @click="showHistorial('reanudado')"
        >
          Docs - reanudado ({{ procesoData?.reanudado.length }})
        </v-btn>
        <v-btn
          :disabled="procesoData?.cancelado.length == 0"
          class="mx-2 my-2 my-md-0"
          color="secondary"
          outlined
          @click="showHistorial('cancelado')"
        >
          Docs - cancelado ({{ procesoData?.cancelado.length }})
        </v-btn>
        <v-btn
          :disabled="procesoData?.sin_efecto.length == 0"
          class="mx-2 my-2 my-md-0"
          color="secondary"
          outlined
          @click="showHistorial('sin_efecto')"
        >
          Docs - sin efecto ({{ procesoData?.sin_efecto.length }})
        </v-btn>
      </v-row>

      <v-dialog max-width="800" v-model="showHistory">
        <v-card class="pa-4">
          <v-card-title>
            <p class="secondary--text">Historial de modificaciones</p>
          </v-card-title>
          <v-card-text>
            <data-table-component
              :headers="headers"
              :items="historialDocs"
              mobile_breakpoint="725"
              :tiene_paginacion="false"
            >
              <template v-slot:[`item.fecha_carga`]="{ item }">
                {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn
                  @click="
                    descargarDocumento(item.doc_justificacion, 'procesos')
                  "
                  icon
                >
                  <v-icon color="secondary"> mdi-download </v-icon>
                </v-btn>
              </template>
            </data-table-component>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn @click="showHistory = false" color="secondary" outlined
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </section>
</template>
<script>
import moment from "moment";
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "DatosGeneralesComponent",
  props: {
    procesoData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DataTableComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Usuario",
        align: "start",
        value: "Usuario.nombre",
        sortable: false,
      },
      {
        text: "Fecha de subida",
        value: "created_at",
        sortable: false,
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
        align: "center",
      },
    ],
    historialDocs: [],
    showHistory: false,
  }),
  methods: {
    async descargarDocumento(doc, disk) {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: doc,
          disk: disk,
        });
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "expediente", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },
    showHistorial(type) {
      this.historialDocs = [];
      const documentos = this.procesoData[type] ?? [];

      this.historialDocs = documentos;
      this.showHistory = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-divider {
  border-color: #404a7a !important;
  border-width: 1 thin 1 1 !important;
  height: 120px !important;
}

@media (max-width: 800px) {
  .v-divider {
    display: none !important;
  }
}
</style>
